import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CustomizationManager } from '@core/services/customization-manager.service';
import { BillingService } from '@modules/billing/services/billing.service';

@Component({
  selector: 'app-confirmation-step',
  templateUrl: './confirmation-step.component.html',
  styleUrls: ['./confirmation-step.component.scss']
})
export class ConfirmationStepComponent implements OnInit {

  constructor(
    private router: Router,
    private customizationManager: CustomizationManager,
    private billingService: BillingService
  ) { }

  ngOnInit() {
  }

  insertHeadline() {
    const headline = this.customizationManager.get('bind-confirmation');
    return this.customizationManager.interpolateText(this.billingService.billingData, headline);
  }

  navigateToDashboard() {
    this.router.navigate([`/dashboard`]);
  }
}