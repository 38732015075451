import InterestTabs from '@shared/models/interests.model';
import { InterestsService } from './../../../services/interests/interests.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss']
})

export class InterestsComponent {

  constructor(
    private InterestsService: InterestsService,
    private router: Router) {
  }

  ngOnInit() {
    this.InterestsService.dataSourceSubject.pipe(
      takeWhile(res => res.length === 0, true)
    ).subscribe((interests: InterestTabs[]) => {
      if (interests.length > 0) this.router.navigateByUrl(interests[0].fullRoute);
    });
  }
}
