import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from '@environments/environment';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { CustomizationManager } from './customization-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CustomActionService {
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private customizationManager: CustomizationManager
  ) { }

  public executeCustomAction(quoteId: string, actionPayload: object, endpoint: string) {
    return this.http.post<any>(`${this.config.apiUrl}${endpoint}`, actionPayload, { params: { quoteId } });
  }

  public executeWorkbenchAction(actionPayload, endpoint: string, params = {}) {
    return this.http.post<any>(`${this.config.apiUrl}${endpoint}`, actionPayload, { params });
  }

  public displaySuccessDialog(message: string, response?: object) {
    
    return this.dialog.open(BasicDialogComponent, {
      data: {
        message: this.customizationManager.interpolateText(response, message),
        onlyConfirm: true,
        title: 'Success',
        showDetails: false,
        dialogPrefix: 'custom-action-success-modal'
      },
      width: '480px'
    }).afterClosed();
  }
}
