import { NestedSubtabFormComponent } from './../../../../../shared/components/nested-subtab-form/nested-subtab-form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { of } from 'rxjs';

@Component({
  selector: 'app-risk-details',
  templateUrl: './risk-details.component.html',
  styleUrls: ['./risk-details.component.scss']
})
export class RiskDetailsComponent implements OnInit {
  form: FormGroup;
  lob: string
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor() { }

  ngOnInit() { }

  canDeactivate() {
    return of(true).toPromise();
  }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
  }
}
