import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import LocationTabs from '@shared/models/location.model';
import { BehaviorSubject } from 'rxjs';
import Queue from '@modules/policy/shared/helpers/queue';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  public dataSourceSubject: BehaviorSubject<LocationTabs[]>;
  public numLocationsSubject: BehaviorSubject<number>;

  constructor() {
    this.dataSourceSubject = new BehaviorSubject([]);
    this.numLocationsSubject = new BehaviorSubject(0);
  }

  updateDataSource(data) {
    this.dataSourceSubject.next(data);
  }

  updateNumLocations(value: number) {
    this.numLocationsSubject.next(value);
  }

  incrementNumLocations() {
    this.numLocationsSubject.next(this.numLocationsSubject.getValue() + 1);
  }

  decrementNumLocations() {
    this.numLocationsSubject.next(this.numLocationsSubject.getValue() - 1);
  }

  // Iterative solution for now. Tough to update dataSource with recursive solution
  appendFormToData(tree: LocationTabs[], id: number, form: FormGroup) {
    if (tree.length === 0) return;
    for (const location of tree) {
      const children = location["children"];
      for (const child of children) {
        if (child.id == id) {
          child["formGroup"] = form;
          this.updateDataSource(tree);
          return;
        }
        const grandchildren = child["children"];
        if (!grandchildren) continue;
        for (const grandchild of grandchildren) {
          if (grandchild.id == id) {
            grandchild["formGroup"] = form;
            this.updateDataSource(tree);
            return;
          }
        }
      }
    }
  }

  // Update this state on save
  updateParentState(tree: LocationTabs[], id: number, newState: boolean) {
    const q = new Queue();
    for (const location of tree) {
      q.enqueue(location);
    }
    while (q.size() > 0) {
      const node: LocationTabs = q.dequeue();
      if (node.id == id) node.state = { state: newState }
      if (!node.children) continue;
      for (const child of node.children) {
        if (child.id == id) {
          child.state = { state: newState }
        }
        q.enqueue(child);
      }
    }
    this.updateDataSource(tree);
  }

  recursiveNodeEliminator(tree, id): boolean {
    for (let index = tree.length - 1; index >= 0; index--) {
      const node = tree[index];
      if (node.id == id) {
        tree.splice(index, 1);
      }
      if (node.children) {
        const parentCanBeEliminated = this.recursiveNodeEliminator(node.children, id);
        if (parentCanBeEliminated) {
          if (node.id == id) {
            tree.splice(index, 1);
          }
        }
      } else {
        // Its a leaf node. No more branches.
        if (node.id == id) {
          tree.splice(index, 1);
        }
      }
    }
    return tree.length === 0;
  }

  getParent(config: LocationTabs[], i: number): number {
    const q = new Queue();
    let currentParent = 0;
    for (const location of config) {
      q.enqueue(location);
    }
    while (q.size() > 0) {
      const node: LocationTabs = q.dequeue();
      currentParent = node.id;
      if (!node.children) continue;
      for (const child of node.children) {
        if (child.id == i) {
          return currentParent;
        }
        q.enqueue(child);
      }
    }
    return currentParent;
  }

  getFirstAvailForm(dataSource) {
    const data = dataSource.data;
    for (const location of data) {
      const children = location["children"];
      const riskAddressForm = children.find(child => child.name == "Risk Address");
      if (riskAddressForm) {
        return { url: riskAddressForm["fullRoute"], id: location.id };
      }
    }
    return null;
  }

  getFormForId(subtabId, locationId, dataSource) {
    const location = dataSource.data.find(location => location.id == locationId);
    if (location != null) {
      for (let child of location.children) {
        if (child.id == subtabId) {
          return { url: child.fullRoute, id: child.id };
        } else if (child.children != null) {
          const subtab = child.children.find(subtab => subtab.id == subtabId);
          if (subtab != null) {
            return { url: subtab.fullRoute, id: child.id };
          }
        }
      }
      const subtab = location.children.find(subtab => subtab.name == "Risk Address");
      return { url: subtab.fullRoute, id: subtab.id };
    }
  }

  // Pass in form id
  isRiskValid(riskID, locationID): boolean {
    const data: LocationTabs[] = this.dataSourceSubject.getValue();
    const locationConfig: LocationTabs = data.find(loc => loc.id == locationID);
    const riskConfig = locationConfig.children.find(child => child.id == riskID);
    const validArr: boolean[] = [];
    this.validityEval(riskConfig, validArr);
    const reducer = (prev, cur) => prev && cur;
    return validArr.reduce(reducer, true);
  }

  isLocationValid(locationID): boolean {
    const data: LocationTabs[] = this.dataSourceSubject.getValue();
    const locationConfig: LocationTabs = data.find(loc => loc.id == locationID);
    const validArr: boolean[] = [];
    this.validityEval(locationConfig, validArr);
    const reducer = (prev, cur) => prev && cur;
    return validArr.reduce(reducer, true);
  }

  private validityEval(config, validArr) {
    if (Array.isArray(config)) {
      for (const c of config) { this.validityEval(c, validArr) }
    }
    const { children } = config;
    if (children) {
      this.validityEval(children, validArr);
    }
    const { state, formGroup } = config;
    if (formGroup && formGroup.status != "DISABLED") {
      validArr.push(formGroup.valid)
    } else if (state && !formGroup && !config["type"]) {
      validArr.push(state.state);
    }
    return;
  }

  setParentNodeName(updatePolicyRes): void {
    this.dataSourceSubject.pipe(
      take(1)
    ).subscribe((data: Array<any>) => {
      data.forEach(dataObj => {
        const nodeData = dataObj.children.find(node => {
          return node.id === updatePolicyRes.parent.id
        });
        if (nodeData != null) {
          nodeData.name = updatePolicyRes.parent.$name;
        }
      })
      this.updateDataSource(data);
    });
  }
}