import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ClientService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  getClientsByZip(ClientCompanyNameTxt: string, ClientAddressPostalTxt: string, quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/clientsByNameZipCode`, { params: { ClientCompanyNameTxt, ClientAddressPostalTxt, quoteId } });
  }

  getClientsByName(ClientFirstNameTxt: string, ClientLastNameTxt: string, ClientAddressPostalTxt: string, quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/clientsByNameZipCode`, { params: { ClientFirstNameTxt, ClientLastNameTxt, ClientAddressPostalTxt, quoteId } });
  }
}