import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'delete-node-dialog',
  templateUrl: 'delete-node-dialog.html',
})
export class DeleteNodeDialog implements OnInit {
  id;
  hasRisks: boolean;
  title: string;
  message: string;
  data: any[];

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.hasRisks = false;
    this.id = data.id;
    this.data = data.data;
  }

  ngOnInit() {
    this.setMessage();
  }

  setMessage() {
    for (let location of this.data) {
      if (!location.children) {
        this.title = "Location";
        if (location.id == this.id) {
          this.hasRisks = false;
          for (let item in location) {
            if (Array.isArray(location[item]) && location[item].length > 0) {
              this.message = "Please delete all risks under this location prior to deleting it.";
              this.hasRisks = true;
              break;
            }
          }
          if (!this.hasRisks) {
            this.message = 'Are you sure you wish to delete "' + location["$name"] + '"?';
            break;
          }
        }
      } else {
        this.title = "Risk";
        const subtab = location.children.find(subtab => subtab.id == this.id);
        if (subtab) {
          this.message = 'Are you sure you wish to delete "' + subtab["name"] + '"?'
        }
      }
    }
  }

}
