import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyCreationService {
  private brokers: Array<any>;
  public dashboardCurrentPage = new BehaviorSubject<number>(0);
  public dashboardLastPage = new BehaviorSubject<number>(0);
  public searchValue: string = '';
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  };

  constructor(private http: HttpClient) { }

  getPolicies(filters = {}, pageNum: number = 1) {
    const params = { pageNo: String(pageNum), searchText: this.searchValue }
    return this.http.post<any>(`${this.config.apiUrl}/dashboard/policy-list`, filters, { params })
      .pipe(catchError(err => {throw err}),
        map(res => this.handlePolicyRes(res))
      );
  }

  getPoliciesInForce(pageNum: number = 1, contractNum: string) {
    const params = { pageNum: String(pageNum), contractNum }
    return this.http.get<any>(`${this.config.apiUrl}/dashboard/policies-in-force`, { params })
      .pipe(catchError(err => {throw err}));
  }

  editPolicy(quoteId: string) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/init`, {}, { params : { quoteId } });
  }

  createPolicy(body) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/policy`, body);
  }

  getBrokers() {
    return this.brokers;
  }

  initBrokers() {
    if (this.brokers) return Promise.resolve();
    return this.http.get<any>(`${this.config.apiUrl}/dashboard/brokers`).toPromise().then(res => {
      this.brokers = res;
    });
  }

  clearBrokers() {
    this.brokers = null;
  }

  getLOBs() {
    return this.http.get<any>(`${this.config.apiUrl}/dashboard/lobs`);
  }

  deletePolicy(quoteId) {
    return this.http.post<any>(`${this.config.apiUrl}/workflow/delete`, {}, { params: { quoteId } });
  }

  private handlePolicyRes(res: any) {
    const { pageCount, currentPage: { pageNo = 1, data: policies = [] } = {} } = res;
    this.dashboardCurrentPage.next(pageCount == 0 ? 0 : pageNo);
    this.dashboardLastPage.next(pageCount);
    return { policies, pageCount };
  }
}
