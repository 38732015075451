import { Router } from '@angular/router';
import ClaimTabs from '@shared/models/claims.model';
import { ClaimsService } from './../../../services/claims/claims.service';
import { Component } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-claims-content',
  templateUrl: './claims-content.component.html',
  styleUrls: ['./claims-content.component.scss']
})

export class ClaimsContentComponent {

  constructor(
    private ClaimsService: ClaimsService,
    private router: Router
  ) { }

  ngOnInit() {
    const $subject = this.ClaimsService.dataSourceSubject;
    $subject.pipe(
      takeWhile(res => res.length === 0, true)
    ).subscribe((claims: ClaimTabs[]) => {
      if (claims.length > 0) this.router.navigateByUrl(claims[0].fullRoute);
    });
  }

}