import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatPaginatorIntl, PageEvent } from '@angular/material';
import { MetadataService } from '@core/services/metadata.service';
import { HubioTableComponent, HubioTableOptions } from '@hubio/hubio-common-components';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { TableConfig } from '@shared/models/feMetadata.model';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PoliciesInForcePaginator } from './policies-in-force.paginator';

@Component({
  selector: 'app-policies-in-force',
  templateUrl: './policies-in-force.component.html',
  styleUrls: ['./policies-in-force.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useClass: PoliciesInForcePaginator }
  ]
})
export class PoliciesInForceComponent implements OnInit {
  @ViewChild('table', { static: false }) public table: HubioTableComponent;

  tableOptions: HubioTableOptions;
  tableConfig: TableConfig;
  getPoliciesSub: Subscription;
  transactionsHeight: string;  
  pifConfig: any;
  brokersControl = new FormControl();
  brokers: any[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private policyService: PolicyCreationService,
    private metadataService: MetadataService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.initMetadata();
    this.initTableOptions();
    this.brokersControl.setValidators(this.valueValidator())
    this.brokers = this.policyService.getBrokers()
    this.brokersControl.setValue(this.brokers[0])
    this.getPolicies()
    
    this.filteredOptions = this.brokersControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.brokersControl.statusChanges.subscribe(status => {
      this.table.paginator.disabled = status === 'INVALID'
    })
  }

  ngAfterContentChecked(): void {
    if (this.transactionsHeight) return;
    const setHeight = (230 - 25)
      + (this.dashboardService.hasTabs() ? 50 : 0)
    this.transactionsHeight = `calc(100vh - ${setHeight}px)`;
    this.tableOptions.minHeight = this.transactionsHeight;
  }

  initTableOptions() {
    this.tableOptions = {
      columns: this.tableConfig.columns,
      tooltip: this.tableConfig.tooltip,
      minHeight: this.transactionsHeight,
      paginator: {
        paginate: true,
        pageSize: this.dashboardService.PAGE_SIZE,
        length: 1
      },
      cache: {
        cache: true,
        maxCacheSize: this.pifConfig.maxPagesCached
      }
    };
  }

  initMetadata() {
    this.pifConfig = this.metadataService.getPoliciesInForceMetaData();
    this.tableConfig = this.pifConfig.tableDefinition;
  }

  getPolicies(pageNum = 1) {
    const { contractNum } = this.brokersControl.value
    this.getPoliciesSub = this.policyService.getPoliciesInForce(pageNum, contractNum).subscribe(policies => {
      // pageNum - 1 to offset pageNum indexing at 1
      this.table.setData(policies, pageNum - 1);

      if (policies.length == this.dashboardService.PAGE_SIZE) {
        this.tableOptions.paginator.length += policies.length;
      }
    });
  }

  handlePaginationEvent(event: PageEvent) {
    if (event.pageIndex > 0) {
      this.getPolicies(event.pageIndex + 1);
    }
  }

  handleBrokerSelect(e: MatAutocompleteSelectedEvent) {
    const selectedValue = e.option.value;
    this.brokersControl.setValue(selectedValue)
    this.table.resetCache()
    this.table.paginator.firstPage()
    this.getPolicies()
  }

  getOptionText(option) {
    return option && option.value ? option.value : '';
  }

  private _filter(value: string): string[] {
    if (typeof value != 'string') return;
    const filterValue = value.toLowerCase();
    return this.brokers.filter(option => option.value.toLowerCase().includes(filterValue));
  }

  private valueValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const valid = typeof control.value != 'string'
      return valid ? null : { 'invalidValue': { value: control.value } };
    };
  }

  ngOnDestroy(): void {
    this.getPoliciesSub?.unsubscribe()
  }
}
