import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface dialogOptions {
  message?: string;
  details?: string;
  onlyConfirm?: boolean;
  title?: string;
  showDetails?: boolean;
  dialogPrefix?: string;

  htmlContent?: string;
  buttons?: DialogButton[];
}

interface DialogButton {
  label: string;
  action: string;
}

@Component({
  selector: 'app-basic-dialog',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent {
  moreDetails: boolean = false;
  data: dialogOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: dialogOptions,
    private dialogRef: MatDialogRef<BasicDialogComponent>
  ) {
    this.data = data;
  }

  toggleDetails() {
    this.moreDetails = this.moreDetails ? false : true;
  }

  buildButtonId(buttonName: string): string {
    return (this.data.dialogPrefix != null ? this.data.dialogPrefix + '-' : '') + buttonName;
  }
}
