import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { AbstractControl, FormArray, FormGroup, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-interest-allocation-table',
  templateUrl: './interest-allocation-table.component.html',
  styleUrls: ['./interest-allocation-table.component.scss']
})

// TODO: Once we settle on configuration, most of this needs to be cleaned up
export class InterestAllocationTableComponent implements OnInit {
  displayedColumns: string[];
  rows: FormArray;
  data: any;
  form: FormGroup;
  dataSource: BehaviorSubject<AbstractControl[]>;
  tabState;
  lookups;

  @Output() newFormEvent = new EventEmitter<FormGroup>();
  @Input() subtab: string;
  @Input() tab: string;
  @Input() nodeId: number;
  @Input() policyId: number;
  @Input() interestForm: FormGroup;
  @Input() formConfig: any;
  constructor(
    private PolicyDataService: PolicyDataService,
    private fb: FormBuilder
  ) { }

  ngOnInit() { }

  newForm(form: FormGroup) {
    this.newFormEvent.emit(form);
  }

  ngOnChanges(_: SimpleChanges) {
    if (this.nodeId && this.policyId && this.subtab && this.tab) {
      this.init();
    }
  }

  init() {
    this.initializeTable();
    const stateObs = this.PolicyDataService.stateSubject;
    // TODO: Probably redundant if data gets passed in as it likely should
    const dataObs = this.PolicyDataService.getData(this.policyId, this.nodeId);
    combineLatest([stateObs, dataObs])
      .pipe(first())
      .subscribe(res => {
        const [states, data] = res;
        const binding = this.formConfig ? this.formConfig.binding : 'InterestAllocations'
        this.tabState = states[this.tab];
        this.PolicyDataService.updateUnderWritingRules(data['opinions']);
        this.data = data.data[binding];
        if (this.data) {
          this.data.forEach((ia: any) => this.addRow(ia, true));
          this.lookups = this.formConfig ? data.lookups : data.lookups.NatureofInterest;
          this.updateView();
        }
        this.form.markAsPristine();
        this.newForm(this.form);
      });
  }

  addRow(ia: any, noUpdate?: boolean) {
    const row = this.fb.group(ia);
    this.rows.push(row);
    this.onCheck(row, { checked: row.get('InterestAllocationAllocateInterestInd').value })
    if (!noUpdate) this.updateView();
  }

  updateView() {
    this.dataSource.next(this.rows.controls);
  }

  onCheck(row: FormGroup, { checked }) {
    this.setEnabled(row.get('InterestAllocationNatureOfInterestCd'), checked);
    this.setEnabled(row.get('InterestAllocationInterestRankCd'), checked);
  }

  getLookups(lookupName: string) {
    return this.lookups[lookupName]
  }

  initializeTable() {
    if (this.form == null) {
      this.rows = this.fb.array([]);
      this.form = this.fb.group({ 'InterestAllocations': this.rows }, {
        validators: [this.mustHaveAllocationSelection()]
      });
      this.interestForm.setControl('InterestAllocation', this.form)
    } else {
      this.rows = this.form.get('InterestAllocations') as FormArray;
      while (this.rows.length !== 0) {
        this.rows.removeAt(0);
      }
    }
    this.dataSource = new BehaviorSubject<AbstractControl[]>([]);
    this.displayedColumns = this.formConfig 
      ? this.formConfig.parameters.map(({ label }) => label)
      : ['value', 'Location and Risk', 'Nature of Interest', 'Interest Ranking']
  }

  setEnabled(control: AbstractControl, checked: boolean) {
    if (checked) {
      control.enable();
    } else {
      control.disable();
      control.setValue(null);
    }
  }

  mustHaveAllocationSelection(): ValidatorFn {
    return (_: FormGroup): ValidationErrors | null => {
      const selectedRows = this.rows.controls.find((row) => {
        return row.get('InterestAllocationAllocateInterestInd').value;
      });
      if (selectedRows == null) {
        return {
          invalid: true
        }
      }
      return null;
    };
  }
}
