import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.scss']
})
export class ErrorNotFoundComponent {
  public homepageRoute: string;

  constructor() {
    this.homepageRoute = environment.HOMEPAGE_ROUTE;
  }
}
