import { DebugService } from '@core/services/debug.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DebugInterceptor implements HttpInterceptor {
  constructor(private DebugService: DebugService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map(event => {
      if (this.shouldBeIntercepted(event)) {
        this.DebugService.addLog(event["body"]["__debug"], request.url);
      }
      return event;
    }));
  }

  shouldBeIntercepted(event) {
    return event["body"] && event["body"]["__debug"];
  }
}