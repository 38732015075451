import { AbstractControl, FormGroup } from "@angular/forms";
import { startWith } from "rxjs/internal/operators/startWith";
import { validateMainRevenueNoRevenue, validateTotalRevenue } from '@modules/policy/shared/rules/farm-revenue.rules';

const handleDisablingConfig = (field: any, form: any, formGroup: FormGroup) => {
    const configs = field.disabling ? field.disabling.concat(form.disabling) : form.disabling; // the disabling config
    if (!configs) return;
    for (let config of configs) {
        if (config == undefined) continue;
        const requiredField = formGroup.get(config.form).get(config.field); // the FormControl for the field that is required
        const formControl = formGroup.get(form.name).get(field.name); // the FormControl for the field that will be disabled
        // console.log("handle-validations", field.name, requiredField, config)
        requiredField.valueChanges.pipe(startWith((formGroup.get(config.form) as FormGroup)[config.field])).subscribe(value => {
            let output = true;
            if (field.disabling == null && form.disabling == null) {
                return;
            }
            for (let config of field.disabling ? field.disabling.concat(form.disabling) : form.disabling) {
                if (config == undefined) continue;
                const field = formGroup.get(config.form).get(config.field); // the FormControl for the field that is required
                const { values } = config;
                const invertValues = (config.invertValues == undefined ? false : config.invertValues);
                let value = field.value
                if (value == undefined) value = null;
                if ((Array.isArray(values) && values.includes(value) != invertValues) ||
                    ((values != undefined && !Array.isArray(values)) && (values == value) != invertValues) ||
                    (values == undefined && value != invertValues)) {
                    output = false
                    break;
                }
            }
            if (output && formControl.disabled && !formGroup.disabled) {
                formControl.enable();
            } else if (!output) {
                formControl.setValue(null);
                formControl.disable();
            }
        });
    }
}

const handleDisabled = (field: any, form: any, formGroup: FormGroup) => {
    if (form["conditionalRequirements"]) formGroup.get(form.name).updateValueAndValidity();
    formGroup.get(form.name).get(field.name).disable();
}

const handleFarmRevenueValidations = (form: any, formGroup: FormGroup) => {
    const farmRevenue: AbstractControl = formGroup.get(form.name);
    const initialValues = (farmRevenue as FormGroup).getRawValue();
    farmRevenue.get("PolicyMainRevenueCd").valueChanges.pipe(startWith(initialValues["PolicyMainRevenueCd"])).subscribe(value => {
        validateMainRevenueNoRevenue(value, farmRevenue, !farmRevenue.get("PolicyMainRevenueCd").disabled);
    });
    farmRevenue.get("PolicyTotalRevenueAmt").valueChanges.pipe(startWith(initialValues["PolicyTotalRevenueAmt"])).subscribe(value => {
        validateTotalRevenue(value, farmRevenue);
    });
}

export const handleValidations = (formJson: any, formGroup: FormGroup) => {
    if (formGroup.disabled) return
    for (const form of formJson) {
        if (form.parameters != null) {
            for (const field of form.parameters) {
                if (field.disabled) {
                    handleDisabled(field, form, formGroup);
                }
                else handleDisablingConfig(field, form, formGroup);
            }
            if (form.name == "farmRevenueAndCoverage") handleFarmRevenueValidations(form, formGroup);
        }
    }
}
