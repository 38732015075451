import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';
import { of, Subscription } from 'rxjs';
import { environment } from '@environments/environment';
import { TreeService } from '@modules/policy/services/location/tree.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-schedule-item-information',
  templateUrl: './schedule-item-information.component.html',
  styleUrls: ['./schedule-item-information.component.scss']
})
export class ScheduleItemInformationComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  private valueChangeSubscription: Subscription;

  constructor(
    private TreeService: TreeService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService,
    private PolicyDataService: PolicyDataService
  ) { }

  saveBeforeDeactivate(): Promise<any> {
    // TODO: This solution works but is temporarily in place while we resolve the race
    // condition that happens in the regular this.formComponent.saveBeforeDeactivate workflow
    // where navigation and data are saved in parallel. 
    if (this.form && (this.form.dirty || this.form.touched)) {
      const policyId = this.PolicyDataService.getPolicyID();
      const cascadingIds = {
        nodeState: [Number(this.formComponent.formId), this.form.valid]
      };
      const { payload, navPoint } = this.FormSaveHelper.buildSavePayload(this.form, cascadingIds);
      if (!payload || !navPoint) {
        console.error("Something wrong with save payload");
        return of(null).toPromise();
      }
      return this.SaveService.updatePolicy(policyId, payload.data, navPoint).toPromise().then((dataRes) => {
        this.TreeService.setParentNodeName(dataRes)
        this.SaveService.updateStates(policyId, payload.navigation).toPromise().then((navRes) => {
          this.PolicyDataService.updateStates(navRes);
        });
      });
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) {
      handleValidations(this.formComponent.formJson, this.form);
      // TODO: Find a better way to isolate SMI code
      if (environment.TENANT === 'smi') {
        this.valueChangeSubscription = this.formComponent.form.valueChanges.subscribe((formRawValues) => {
          let total = 0;
          if (formRawValues.FloaterInformation.ScheduleItemPerUnitValueAmt != null
            && formRawValues.FloaterInformation.ScheduleItemQuantityAmt != null) {
            total = formRawValues.FloaterInformation.ScheduleItemPerUnitValueAmt * formRawValues.FloaterInformation.ScheduleItemQuantityAmt;
          }

          const totalFormControl = this.formComponent.form.get('FloaterInformation').get('ScheduleItemTotalAmt');
          if (totalFormControl.value != total) {
            totalFormControl.setValue(total);
          }
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription != null) {
      this.valueChangeSubscription.unsubscribe();
    }
  }
}
