import { SaveService } from './../../../../services/shared/save.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { PolicyService } from './../../../../policy.service';
import { FormGroup } from '@angular/forms';
import { LiabilityItem } from './../../../../../../shared/models/liability.model';
import { PolicyDataService } from './../../../../services/shared/policy-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PolicyState } from '@shared/models/state.model';
import LiabilityTab from '@shared/models/liability.model';
import { CoverageInfo } from '@shared/models/coverage.model';
import { CoverageTableComponent } from '@modules/policy/shared/components/coverage-table/coverage-table.component';
import { BehaviorSubject, of } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-liability',
  templateUrl: './liability.component.html',
  styleUrls: ['./liability.component.scss']
})
export class LiabilityComponent implements OnInit {
  primaryDisplayedColumns = ['name', 'limit', 'deductible', 'basisOfSettlement'];
  additionalDisplayedColumns = ['value', 'name', 'limit', 'deductible'];
  tab: string;
  policyID: string;
  nodeID: number;
  tabState: LiabilityTab;
  subtabList: LiabilityItem[];
  data: CoverageInfo;
  hasLiabilities: boolean = true;
  subtab: string;
  form: FormGroup;
  policyReadOnly: BehaviorSubject<boolean>;
  readonly routeMapping = {
    cpl: '/policy/:policyId/farm/liability/cpl',
    frh: '/policy/:policyId/farm/liability/frh'
  };
  @ViewChild('tableComponent', { static: false }) tableComponent: CoverageTableComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private PolicyDataService: PolicyDataService,
    private PolicyService: PolicyService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService
  ) { }

  saveBeforeDeactivate() {
    this.syncStates();
    if (!this.form || (this.form && !(this.form.dirty || this.form.touched))) {
      return of(null).toPromise();
    }
    const cascadingIds: object = {
      nodeState: [Number(this.nodeID), this.form.valid],
      tabState: [this.tabState.id, this.form.parent ? this.form.parent.valid : false]
    };
    const cascadingStates = this.FormSaveHelper.buildStatePayload(cascadingIds);
    this.SaveService.updateStates(this.policyID, cascadingStates).subscribe();
    if (this.tableComponent) {
      return this.tableComponent.saveBeforeDeactivate().then(() => {
        this.showSaveSnackbar();
      });
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.tableComponent.showSaveSnackbar();
    this.tableComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.tab = 'liability';
    this.policyReadOnly = this.PolicyDataService.policyReadOnly
    this.policyID = this.PolicyDataService.getPolicyID();
    this.fetchLatestStates();
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(states => {
      this.PolicyDataService.updateStates(states);
      this.hasLiabilities = !(states.liability.liabilities.length < 1);
      this.loadTabs(states);
    });
  }

  initForm(form: FormGroup) {
    this.form = form;
  }

  loadTabs(states: PolicyState) {
    this.tabState = states[this.tab];
    this._modifyRoutes(this.tabState.liabilities);
    this.navigateToAvailableSubtab(true);
    this.route.params.subscribe(params => {
      this.subtab = params.subtab;
      this.nodeID = this.navigateToAvailableSubtab();
    });
  }

  _modifyRoutes(subtabs) {
    this.subtabList = subtabs.map(subtab => {
      const fullRoute = this.routeMapping[subtab.$key].replace(':policyId', this.policyID.toString());
      return {
        ...subtab,
        fullRoute,
      }
    });
  }

  navigateToAvailableSubtab(navigate?: boolean): number {
    if (this.subtabList.length < 1) {
      return;
    }
    const config = this.subtabList[0];
    if (navigate && config.fullRoute && !this.route.snapshot.params.subtab) {
      this.router.navigateByUrl(config.fullRoute);
    }
    if (!navigate && this.subtab) {
      return this.subtabList.find(item => item.$key == this.subtab).coverages.id;
    }
  }

  fetchLatestStates() {
    this.PolicyDataService.stateSubject.pipe(skip(1)).subscribe(states => {
      this.tabState = states[this.tab];
      this._modifyRoutes(this.tabState.liabilities);
    });
  }

  isFormValid(formId: number): boolean {
    const form = this.PolicyService.getForm(formId);
    if (form) {
      return form.valid;
    }
    return (this.tabState && this.tabState.liabilities.length < 1);
  }

  syncStates() {
    for (const subtab of this.tabState.liabilities) {
      if (this.form && this.form.valid != subtab.state) {
        this.form.markAsDirty();
      }
    }
  }
}
