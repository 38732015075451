import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss']
})
export class DisclaimerDialogComponent implements OnInit {
  private dialogContent: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.dialogContent = data;
  }

  ngOnInit() {}
}
