import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-form-tab',
  template: `
  <div *ngFor="let field of formJson">
    <lib-form-field [field]="field" [abstractControl]="form.get(field.name)" [applyDefault]="applyDefault"></lib-form-field>
  </div>
  `,
  styles: []
})
export class FormTabComponent implements OnInit {

  @Input() formJson: any
  @Input() form: FormGroup;
  @Input() applyDefault: boolean = false;

  constructor() { }

  ngOnInit() {
    // TODO: fix this, temp solution to an error suggesting formJson is an empty object
    if (!Array.isArray(this.formJson)) this.formJson = [];
  }

  updateVisibility(field) {
    this.updateformJSON(field, this.formJson)
  }

  // TODO:should we have dependency in fields with the same parent or not ? 
  updateformJSON(field, form): any {
    form.forEach(el => {
      if (el.name != field) {
        if (el.type == "object") {
          this.updateformJSON(field, el.parameters)
        }
      } else {
        //update visibility for the field
        el.visible = true;
        return el
      }
    })
  }
}
