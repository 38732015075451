import { FormControl, FormGroup } from '@angular/forms';
import { FilterService } from './../../services/filter.service';
import { Component, Input, OnInit } from '@angular/core';
import { MetadataService } from '@core/services/metadata.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dashboard-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  filters: Array<any>;
  filterForm: FormGroup = new FormGroup({});
  filterEventsSub: Subscription;
  @Input() filtersApplied: any;
  @Input() initialized: boolean;

  constructor(
    private FilterService: FilterService,
    private metaDataService: MetadataService
  ) { }

  ngOnInit() {
    this.filterEventsSub = this.FilterService.events.subscribe(({ filter, clear, quickFilter }) => {
      if (filter || clear) this.resetFilters(false, clear || quickFilter, filter);
    })
  }

  ngOnChanges({ initialized }): void {
    if (initialized && initialized.currentValue) this.initFilters()
  }

  ngAfterViewInit() { }

  initFilters() {
    if (!this.filters) {
      const filtersConfig = this.metaDataService.getWorkbenchMetaData().filters;
      this.filters = this.buildFilters(filtersConfig, this.filtersApplied);
    }
  }

  buildFilters(filtersConfig: Array<any>, filtersApplied = {}) {
    return filtersConfig.map(field => {
      const control = new FormControl();
      if (field.name in filtersApplied) {
        control.setValue(filtersApplied[field.name]);
      }
      this.filterForm.addControl(field.name, control);

      return {
        ...field,
        abstractControl: control
      };
    })
  }

  getFilters() {
    this.FilterService.newEvent({ filter: this.filterForm.getRawValue() });
  }

  resetFilters(sendEvent = false, fullReset = false, newFilters?: object) {
    this.filterForm.reset(newFilters);
    if (sendEvent) this.FilterService.newEvent({ clear: true });
  }

  toggleFilters() {
    this.FilterService.toggle();
  }

  ngOnDestroy(): void {
    if (this.filterEventsSub)
      this.filterEventsSub.unsubscribe()
  }
}
