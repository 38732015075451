import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.PRODUCTION) {
  enableProdMode();
}

document.getElementById('app-icon').setAttribute('href', `./assets/img/${environment.TENANT}/favicon.ico`);
document.getElementById('google-api-integration').setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_API_KEY}&libraries=places`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
