import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as images from '../../../assets/img/images.json';
import * as customText from '../../../assets/text/custom-text.json';

interface LocaleSubset {
  [s: string]: {
    [s: string]: string
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomizationManager {
  public source: { [s: string]: string | LocaleSubset };

  constructor() {
    this.source = {
      ...images.default['default'],
      ...customText.default['default'],
      ...images.default[environment.TENANT],
      ...customText.default[environment.TENANT]
    }
  }

  public get(asset: string): string {
    return String(this.source[asset]);
  }

  public getSubset(asset: string, parent: 'error'): { [s: string]: string } {
    return this.source[parent][asset];
  }

  public interpolateText(data: { [s: string]: any }, text: string) {
    while (text.indexOf('{{') != -1 && data != null) {
      const replaceStart = text.indexOf('{{');
      const replaceEnd = text.indexOf('}}', replaceStart) + 2;
      const replaceKey = text.substring(replaceStart + 2, replaceEnd - 2);

      let insert = data[replaceKey] ?? ''
      text = text.substring(0, replaceStart) + insert + text.substring(replaceEnd);
    }

    return text;
  }
}
