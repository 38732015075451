import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-error-forbidden',
  templateUrl: './error-forbidden.component.html',
  styleUrls: ['./error-forbidden.component.scss']
})
export class ErrorForbiddenComponent {
  public homepageRoute: string;

  constructor() {
    this.homepageRoute = environment.HOMEPAGE_ROUTE;
  }
}
