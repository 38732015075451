import { PolicyService } from './../../../policy.service';
import { FormService } from './../../../services/shared/form.service';
import { TreeService } from '@modules/policy/services/location/tree.service';
import { BehaviorSubject, of, combineLatest } from 'rxjs';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { MatSnackBar, MatTreeNestedDataSource } from '@angular/material';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import Coverage, { CoverageInfo } from '@shared/models/coverage.model';
import { FormArray, FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import LocationTabs from '@shared/models/location.model';
import { CanComponentDeactivate } from '@modules/policy/guards/save.guard';
import { first } from 'rxjs/operators';
import { FormSaveHelper } from '@modules/policy/services/shared/save';

@Component({
  selector: 'app-coverage-table',
  templateUrl: './coverage-table.component.html',
  styleUrls: ['./coverage-table.component.scss']
})

export class CoverageTableComponent implements CanComponentDeactivate {
  primaryDisplayedColumns = ['name', 'limit', 'deductible', 'basisOfSettlement'];
  additionalDisplayedColumns = ['value', 'name', 'limit', 'deductible'];
  data: Coverage;
  primaryRows: FormArray;
  additionalRows: FormArray;
  primaryForm: FormGroup;
  additionalForm: FormGroup;
  primaryCoverageDataSource: BehaviorSubject<AbstractControl[]>;
  additionalCoverageDataSource: BehaviorSubject<AbstractControl[]>;
  dataSource: MatTreeNestedDataSource<LocationTabs>;
  tables: any[];
  form: FormGroup;
  tabState;
  packageType: string;
  policyCode: string;
  formTypeCd: string;
  province: string;
  readOnlyPolicy: boolean;

  private fieldSubscriptions = [];

  @Input() subtab: string;
  @Input() tab: string;
  @Input() nodeID: number;
  @Input() policyID: number;
  @Input() isChild: boolean;
  @Input() additionalDependencies: { [s: string]: AbstractControl };

  @Output() newFormEvent = new EventEmitter<FormGroup>();

  readonly ADDITIONAL_COVERAGE_DEPENDENCIES = {
    "ScheduleItemDeductibleCd": {
      type: "deductible",
      dependentCoverageCodes: [
        "EBMB",
        "EP-COVY",
        "EP-FME",
        "FLHP",
        "FTL",
        "HPRO",
        "LUEX",
        "PDCC",
        "PROFLT_ATV",
        "PROFLT_FTC",
        "FPSOLAR",
        "FPDRONE"
      ]
    },
    "ScheduleItemValueAmt": {
      type: "limit",
      dependentCoverageCodes: [
        "EP-FME"
      ]
    }
  }

  constructor(
    private PolicyDataService: PolicyDataService,
    private SaveService: SaveService,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private TreeService: TreeService,
    private formService: FormService,
    private policyService: PolicyService,
    private FormSaveHelper: FormSaveHelper
  ) { }

  saveBeforeDeactivate(): Promise<any> {
    return (this.form && (this.form.dirty || this.form.touched)) ?
      this.save() : of(null).toPromise();
  }

  newForm(form: FormGroup) {
    this.newFormEvent.emit(form);
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nodeID && this.policyID && this.subtab && this.tab) {
      this.init();
    }
    if (changes.additionalDependencies != null) {
      this.createAdditionalDependencySubscriptions();
    }
  }

  ngOnDestroy() {
    this.destroySubscriptions();
  }

  destroySubscriptions() {
    while (this.fieldSubscriptions.length > 0) {
      this.fieldSubscriptions.shift().unsubscribe();
    }
  }

  init() {
    this.matTreeSubscription();
    this.initializeTableData();
    const stateObs = this.PolicyDataService.stateSubject;
    const dataObs = this.PolicyDataService.getData(this.policyID, this.nodeID);
    const columnObs = this.PolicyDataService.getCoverageMetaData(this.PolicyDataService.getPolicyID());
    const readOnlyObs = this.PolicyDataService.policyReadOnly;
    combineLatest([stateObs, dataObs, columnObs, readOnlyObs])
      .pipe(first())
      .subscribe(res => {
        const [states, data, columnMetaData, readOnly] = res;
        this.readOnlyPolicy = readOnly
        this.additionalDisplayedColumns = columnMetaData["additionalColumns"];
        this.primaryDisplayedColumns = columnMetaData["primaryColumns"]
        this.tabState = states[this.tab];
        this.PolicyDataService.updateUnderWritingRules(data["opinions"]);
        this.data = data["data"];
        this.province = data["data"].meta?.Product?.Province
        if (this.data["RiskPackageTypeCd"]) {
          this.packageType = this.data["RiskPackageTypeCd"];
          this.policyCode = this.data["RiskPackageCd"];
        } else if (this.data["LiabilityPackageTypeCd"]) {
          this.packageType = this.data["LiabilityPackageTypeCd"];
          this.policyCode = this.data["LiabilityPackageTypeCd"];
        } else if (this.data["ScheduleItemFormTypeCd"]) {
          this.formTypeCd = this.data["ScheduleItemFormTypeCd"]
        } else if (this.data["OutbuildingFormCd"]) {
          this.formTypeCd = this.data["OutbuildingFormCd"]
        } else {
          this.packageType = "";
          this.policyCode = "";
        }
        this.form = this.fb.group({
          'primary': this.primaryForm,
          'additional': this.additionalForm
        });
        this.data.PrimaryCoverages.forEach((coverage: CoverageInfo) => this.addRow(coverage, "PRIMARY", true));
        this.data.AdditionalCoverages.forEach((coverage: CoverageInfo) => this.addRow(coverage, "ADDITIONAL", true));
        this.updateView();
        this.form.markAsPristine();
        this.setForm(this.form, this.subtab, this.tab, this.nodeID);
        this.newForm(this.form);
        if (this.tab == "location") {
          this.handleValidations();
        }
      });
  }

  addRow(coverage: CoverageInfo, type: string, noUpdate?: boolean) {
    const row = this.fb.group(coverage);
    if (this.readOnlyPolicy) {
      row.disable()
    }
    if (row.get('__meta').value.Limit) {
      row.get('__meta').value.Limit.Visible = row.get("CoverageSelectedInd").value;
    }
    if (row.get('__meta').value.Deductible) {
      row.get('__meta').value.Deductible.Visible = row.get("CoverageSelectedInd").value;
    }
    this.createFieldSubscription(row);
    if (this.shouldDisable(row, "Deductible")) {
      row.get('CoverageDeductibleAmt').disable();
    }
    if (this.shouldDisable(row, "Limit")) {
      row.get("CoverageLimitAmt").disable();
    }
    if (row.get('CoverageLimitAmt').enabled) {
      this.onCheck(row, { checked: row.get('CoverageSelectedInd').value }, true);
    }
    if (row.get('CoverageSelectedInd').value) {
      this.handleSpecialDefaults(row, true);
    }
    if (type === "PRIMARY") {
      this.primaryRows.push(row);
    }
    if (type === "ADDITIONAL") {
      this.additionalRows.push(row);
    }
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.primaryCoverageDataSource.next(this.primaryRows.controls);
    this.additionalCoverageDataSource.next(this.additionalRows.controls);
  }

  onCheck(row: FormGroup, { checked }, initial?: boolean) {
    if (checked) {
      if (row.get('__meta').value.Limit != null) {
        row.get('__meta').value.Limit.Visible = true;
      }
      if (row.get('__meta').value.Deductible != null) {
        row.get('__meta').value.Deductible.Visible = true;
      }
      if (!initial) row.enable();
      this.handleSpecialDefaults(row, initial ? false : true);

      if (row.get('__meta').value.Limit) {
        row.get("CoverageLimitAmt").setValidators([Validators.required]);
        if (this.shouldDisable(row, "Limit")) {
          row.get('CoverageLimitAmt').disable();
        }
        if (!initial && row.get("CoverageLimitAmt").value === null) {
          row.get("CoverageLimitAmt").setValue(row.get('__meta').value.Limit.Default);
        }
        row.get("CoverageLimitAmt").updateValueAndValidity();
        row.get("CoverageLimitAmt").markAsTouched();
      }

      if (row.get('__meta').value.Deductible) {
        if (this.shouldDisable(row, "Deductible")) {
          row.get("CoverageDeductibleAmt").disable();
        }
        if (!initial && !row.get("CoverageDeductibleAmt").value) {
          row.get("CoverageDeductibleAmt").setValue(row.get('__meta').value.Deductible.Default);
        }
        row.get("CoverageDeductibleAmt").updateValueAndValidity();
        row.get("CoverageDeductibleAmt").markAsTouched();
      }
      return;

    } else {
      if (row.get('__meta').value.Limit != null) {
        row.get('__meta').value.Limit.Visible = false;
      }
      if (row.get('__meta').value.Deductible != null) {
        row.get('__meta').value.Deductible.Visible = false;
      }
    }
    row.setValidators(null);
    row.get('CoverageDeductibleAmt').reset();
    row.get('CoverageLimitAmt').reset();

    row.get("CoverageDeductibleAmt").disable();
    row.get("CoverageLimitAmt").disable();
    row.updateValueAndValidity();
  }

  /**
   * Gets the row of the given coverage.
   * @param covName the name of the coverage to look for.
   * @returns the row of the found coverage; "undefined" if none found.
   */
  getCoverage(covName: string) {
    for (let form in this.form.controls) {
      const coverage = this.form.get(form).get("Coverages")["controls"].find(coverage => coverage.get("CoverageNameCd").value == covName);
      if (coverage) return coverage;
    }
    return undefined;
  }

  /**
   * Gets the limit value of the given coverage.
   * @param covName the name of the coverage to look for.
   * @returns the limit value of the coverage; 0 if none found.
   */
  getLimit(covName: string): number {
    const coverage = this.getCoverage(covName);
    if (coverage != null && coverage.get("CoverageLimitAmt").value != null) {
      return parseInt(coverage.get("CoverageLimitAmt").value);
    } else {
      return 0;
    }
  }

  /**
   * Gets the limit value for one of an array of coverages that may exist on the page
   *  (Used for MFI where package type is not distinguished in data payload)
   * @param possibleFields An array of coverages that may be on the page
   * @returns the limit value of the coverage that exists; 0 if none found.
   */
  getLimitFromPossibleArray(possibleFields: string[]): { limitDefault: number, foundField: string } {
    let limitDefault = 0;
    let field;
    while (possibleFields.length > 0 && limitDefault == 0) {
      field = possibleFields.shift();
      limitDefault = this.getLimit(field);
    }
    return {
      limitDefault,
      foundField: field
    };
  }

  /**
   * Gets the deductible value of the given coverage.
   * @param covName the name of the coverage to look for.
   * @returns the deductible value of the coverage; 0 if none found.
   */
  getDeductible(covName: string): number {
    const coverage = this.getCoverage(covName);
    if (coverage != null && coverage.get("CoverageDeductibleAmt").value != null) {
      return parseInt(coverage.get("CoverageDeductibleAmt").value);
    } else {
      return 0;
    }
  }

  /**
   * Gets the deductible value for one of an array of coverages that may exist on the page
   * Searches coverages in the order provided, returns the first (if any) coverage with a deductible value
   * @param possibleFields An array of coverage names that may be on the page
   * @returns the deductible value of the coverage that exists; 0 if none found & the name of the coverage
   */
  getDeductibleFromPossibleArray(possibleFields: string[]): { deductible: number, foundField: string } {
    let deductible = 0;
    let field;

    while (possibleFields.length > 0 && deductible == 0) {
      field = possibleFields.shift();
      deductible = this.getDeductible(field);
    }

    return {
      deductible,
      foundField: field
    };
  }

  /**
   * Sets the limit of the given coverage to the given value. If value is outside of the min or max, sets the value
   * to the min or max allowed value instead.
   * @param overwrite whether to overwrite the value already in row.
   * @param row the row that the coverage belongs to.
   * @param value the value to set the limit to.
   * @param min if the coverage has a minimum allowed value, set it here. Leave as "undefined" otherwise.
   * @param max if the coverage has a maximum allowed value, set it here. Leave as "undefined" otherwise.
   */
  setLimitDefault(overwrite: boolean, row: FormGroup, value: number, min: number = 0, max: number = value) {
    if (row == undefined) return;
    const output = Math.round(Math.max(Math.min(max, value), min));
    if (row.get("CoverageLimitAmt").value != output && row.get("CoverageSelectedInd").value &&
      (overwrite || row.get("CoverageLimitAmt").value == null)) row.get("CoverageLimitAmt").setValue(output);
  }

  /**
   * Sets the deductible of the given coverage to the given value. If value is outside of the min or max, sets the value
   * to the min or max allowed value instead.
   * @param row the row that the coverage belongs to.
   * @param value the value to set the deductible to.
   * @param min if the coverage has a minimum allowed value, set it here. Leave as "undefined" otherwise.
   * @param max if the coverage has a maximum allowed value, set it here. Leave as "undefined" otherwise.
   */
  setDeductibleDefault(row: FormGroup, value: number, min: number = 0, max: number = value) {
    if (row == undefined) return;
    const currentDeductible = row.get("CoverageDeductibleAmt").value
    const output = Math.round(Math.max(Math.min(max, value), min));
    if (currentDeductible == null && currentDeductible != output && row.get("CoverageSelectedInd").value) {
      row.get("CoverageDeductibleAmt").setValue(output);
    }
  }

  shouldDisable(row: FormGroup, type: string): boolean {
    return (row.get('__meta').value[type] && row.get('__meta').value[type].Editable === false);
  }

  save(): Promise<any> {
    if (this.form.disabled) return
    const savePayload = {
      Coverages: [
        ...this.primaryForm.getRawValue()["Coverages"],
        ...this.additionalForm.getRawValue()["Coverages"]
      ]
    }
    for (let coverage of savePayload.Coverages) {
      if (coverage.CoverageLimitAmt === '') {
        coverage.CoverageLimitAmt = null;
      }
      if (coverage.CoverageDeductibleAmt === '') {
        coverage.CoverageDeductibleAmt = null;
      }
    }
    const policySaveReq = this.SaveService.updatePolicy(this.policyID, savePayload, this.nodeID).toPromise();
    const nodeStatePayload: object = {
      nodeState: [parseInt(String(this.nodeID)), this.form.valid]
    };
    const statePayload = this.FormSaveHelper.buildStatePayload(nodeStatePayload);
    const stateSaveReq = this.SaveService.updateStates(this.policyID, statePayload).toPromise();
    return Promise.all([policySaveReq, stateSaveReq]).then((res) => {
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.showSaveSnackbar();
    });
  }

  showSaveSnackbar() {
    this.snackbar.open("Saved", null, {
      duration: 2000,
    });
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.PolicyDataService.updateStates(tree);
      this.tabState = tree[this.tab];
    })
  }

  matTreeSubscription() {
    this.dataSource = new MatTreeNestedDataSource<LocationTabs>();
    this.TreeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
  }

  initializeTableData() {
    this.data = {
      "PrimaryCoverages": [],
      "AdditionalCoverages": []
    };
    this.primaryRows = this.fb.array([]);
    this.additionalRows = this.fb.array([]);
    this.primaryForm = this.fb.group({ "Coverages": this.primaryRows });
    this.additionalForm = this.fb.group({ "Coverages": this.additionalRows })
    this.primaryCoverageDataSource = new BehaviorSubject<AbstractControl[]>([]);
    this.additionalCoverageDataSource = new BehaviorSubject<AbstractControl[]>([]);
    this.tables = [
      { type: "Primary", label: "Primary Coverages", data: this.primaryCoverageDataSource, form: this.primaryForm },
      { type: "Additional", label: "Additional Coverages", data: this.additionalCoverageDataSource, form: this.additionalForm }
    ];
  }

  onBlurNumber(control: AbstractControl) {
    if (control.value != "" && control.value != null) {
      control.setValue(Number(control.value));
    } else {
      control.setValue(null);
    }
  }

  setForm(form: FormGroup, subtab: string, tab: string, nodeID: number) {
    if (!this.isChild) {
      this.formService.setForm({
        form,
        orderedFormJson: [],
        formid: nodeID
      });
    }
  }

  // COVERAGE RISK DETAILS VALIDATIONS --- MOVE THIS

  handleValidations() {
    this.primaryForm = this.form.get('primary') as FormGroup;
    this.additionalForm = this.form.get('additional') as FormGroup;
    this.TreeService.appendFormToData(this.dataSource.data, this.nodeID, this.form);

    const formValuesPrimary: CoverageInfo[] = this.primaryForm.getRawValue()["Coverages"];
    const formValuesAdditional: CoverageInfo[] = this.additionalForm.getRawValue()["Coverages"];

    const EQD_Index = formValuesAdditional.findIndex(cov => cov.CoverageNameCd == "EQ-D");
    const EQB_Index = formValuesAdditional.findIndex(cov => cov.CoverageNameCd == "EQ-B");
    const EQC_Index = formValuesAdditional.findIndex(cov => cov.CoverageNameCd == "EQ-C");

    const COVD_Index = formValuesPrimary.findIndex(cov => cov.CoverageNameCd == "COVD");
    const COVB_Index = formValuesPrimary.findIndex(cov => cov.CoverageNameCd == "COVB");
    const COVC_Index = formValuesPrimary.findIndex(cov => cov.CoverageNameCd == "COVC");

    this.form.valueChanges.subscribe((res) => {
      const { primary, additional } = res;

      if (EQD_Index !== -1 && COVD_Index !== -1) {
        this.evaluateField(primary.Coverages[COVD_Index], additional.Coverages[EQD_Index], "COVD", "EQ-D");
      }

      if (EQB_Index !== -1 && COVB_Index !== -1) {
        this.evaluateField(primary.Coverages[COVB_Index], additional.Coverages[EQB_Index], "COVB", "EQ-B");
      }

      if (EQC_Index !== -1 && COVC_Index !== -1) {
        this.evaluateField(primary.Coverages[COVC_Index], additional.Coverages[EQC_Index], "COVC", "EQ-C");
      }

      this.handleMinMax();
    })
  }

  evaluateField(primaryCoverages, additionalCoverages, coverageTypeA, coverageTypeB) {
    const result = this.AcannotExceedB(additionalCoverages ? additionalCoverages["CoverageLimitAmt"] : null,
      primaryCoverages ? primaryCoverages["CoverageLimitAmt"] : null);
    const rowB = this.additionalRows.controls.find((fg: FormGroup) => fg.get('CoverageNameCd').value == coverageTypeB);
    const rowA = this.primaryRows.controls.find((fg: FormGroup) => fg.get('CoverageNameCd').value == coverageTypeA);
    const control = rowB.get('CoverageLimitAmt');
    this.setErrors(control, result, `Cannot exceed the ${rowA.get('CoverageNameNm').value} limit`);
  }

  setErrors(control: AbstractControl, result: boolean, message: string) {
    if (result === false) {
      control.setErrors({ message });
    } else {
      this.removeError(control, "message");
    }
  }

  AcannotExceedB(a, b) {
    if (a == null || b == null) return true;
    return a > b ? false : true;
  }

  // this function removes single error
  removeError(control: AbstractControl, error: string) {
    const err = control.errors;
    if (err) {
      delete err[error];
      if (!Object.keys(err).length) {
        control.setErrors(null);
      } else {
        control.setErrors(err);
      }
    }
  }

  /**
   * Sets the minimum and maximum allowed value for a coverage. Can be called in place of {@link setMin} and
   * {@link setMax} by simply setting one of the limits to "undefined".
   * @param covName the name of the coverage.
   * @param min the minimum allowed value; can be a number or "undefined" if none.
   * @param max the maximum allowed value; can be a number, or "undefined" if none.
   * @param field the field to set the min/max allowed value for; "CoverageLimitAmt" (default) or "CoverageDeductibleAmt."
   */
  setMinMax(covName: string, min: number | undefined, max: number | undefined, field: string = "CoverageLimitAmt") {
    const cov = this.getCoverage(covName);
    if (cov == undefined || cov.get(field).value == null) {
      return;
    }
    min = (min == undefined) ? undefined : Math.round(min);
    max = (max == undefined) ? undefined : Math.round(max);
    if (min != undefined && max == undefined) {
      this.setMin(covName, min, field);
    } else if (min == undefined && max != undefined) {
      this.setMax(covName, max, field);
    } else {
      this.setErrors(
        cov.get(field),
        cov.get("CoverageSelectedInd").value ? (
          cov.get(field).value >= min &&
          cov.get(field).value <= max
        ) : true,
        "Value must be between " + min.toLocaleString("en-US") + " and " + max.toLocaleString("en-US") + "."
      )
    }
  }

  /**
   * Sets the minimum allowed value for a coverage.
   * @param covName the name of the coverage.
   * @param min the minimum allowed value; can be a number.
   * @param field the field to set the min/max allowed value for; "CoverageLimitAmt" (default) or "CoverageDeductibleAmt."
   */
  setMin(covName: string, min: number, field: string | undefined = "CoverageLimitAmt") {
    const cov = this.getCoverage(covName);
    if (cov == undefined || cov.get(field).value == null) return;
    this.setErrors(
      cov.get(field),
      cov.get("CoverageSelectedInd").value ? (
        cov.get(field).value >= min
      ) : true,
      "Value cannot be less than " + min.toLocaleString("en-US") + "."
    )
  }

  /**
   * Sets the maximum allowed value for a coverage.
   * @param covName the name of the coverage.
   * @param min the maximum allowed value; can be a number.
   * @param field the field to set the min/max allowed value for; "CoverageLimitAmt" (default) or "CoverageDeductibleAmt."
   */
  setMax(covName: string, max: number, field: string | undefined = "CoverageLimitAmt") {
    const cov = this.getCoverage(covName);
    if (cov == undefined || cov.get(field).value == null) return;
    this.setErrors(
      cov.get(field),
      cov.get("CoverageSelectedInd").value ? (
        cov.get(field).value <= max
      ) : true,
      "Value cannot be greater than " + max.toLocaleString("en-US") + "."
    )
  }

  /**
   * Create appropriate subscriptions when additional dependencies are passed to the coverage table component
   */
  createAdditionalDependencySubscriptions(): void {
    this.destroySubscriptions();

    if (this.additionalDependencies != null) {
      for (const dependency in this.additionalDependencies) {
        const fieldSubscription = this.additionalDependencies[dependency].valueChanges.subscribe((value) => {
          const dependentCoverageObject = this.ADDITIONAL_COVERAGE_DEPENDENCIES[dependency];
          for (const dependentCoverageName of dependentCoverageObject.dependentCoverageCodes) {
            const dependentCoverage = this.getCoverage(dependentCoverageName);
            if (dependentCoverage != null) {
              if (dependentCoverageObject.type === 'deductible') {
                this.setDeductibleDefault(dependentCoverage, value);
              } else if (dependentCoverageObject.type === 'limit') {
                this.setLimitDefault(true, dependentCoverage, value);
              }
            }
          }
        });
        this.fieldSubscriptions.push(fieldSubscription);
      }
    }
  }

  /**
   * Creates a subscription to listen for changes to coverages.
   * @param row a row to check if a subscription should be created for it
   */
  createFieldSubscription(row: FormGroup) {
    const covName = row.get("CoverageNameCd").value;
    if (["HO", "SEAH"].includes(this.policyCode)) { // Homeowners
      switch (covName) {
        case "COVA":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
        // fall through
        case "COVB":
        case "COVC":
        case "COVD":
        case "COVE":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (["CO", "COR", "COS"].includes(this.policyCode)) { // Condominium
      switch (covName) {
        case "COVC":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
        // Fall through
        case "COVE":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "CPL") { // Comprehensive Personal Liability
      switch (covName) {
        case "COVE":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "CGL") { // Comprehensive Personal Liability
      switch (covName) {
        case "CGLABI":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "FEC") { // General Dwelling and Contents
      switch (covName) {
        case "BUILA":
        case "BUILF":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
        // Fall through
        case "PPAR":
        case "PPFEC":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "PINS") { // Property in Storage
      switch (covName) {
        case "PINSF":
        case "PINSA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "SEAR") { // Seasonal Residents and Contents
      switch (covName) {
        case "BUILF":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
        // fall through
        case "BUILA":
        case "PPFEC":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (["SETP", "TE"].includes(this.policyCode)) { // Tenants
      switch (covName) {
        case "COVC":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
        // Fall through
        case "COVE":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    } else if (this.policyCode == "PD") {
      switch (covName) {
        case "PD-COMP-COVA":
        case "PD-EP-COVA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
      }
    } else if (this.policyCode == "SNS") {
      switch (covName) {
        case "SNS-COMP-COVA":
        case "SNS-EP-COVA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
      }
    } else if (this.policyCode == "SD") {
      switch (covName) {
        case "SD-COMP-COVA":
        case "SD-BP-COVA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
      }
    } else if (this.policyCode == "MH") {
      switch (covName) {
        case "MH-BP-COVA":
        case "MH-COMP-COVA":
        case "MH-LP-COVA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
      }
    } else if (this.policyCode == "RD") {
      switch (covName) {
        case "RD-LP-COVA":
        case "RD-BP-COVA":
        case "RD-COMP-COVA":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
      }
    } else if (["FBCP", "FBEP", "FBBP", "FBLP"].includes(this.formTypeCd)) {
      switch (covName) {
        case "FBCP":
        case "FBBP":
        case "FBEP":
        case "FBLP":
          if (this.province != 'MB') {
            row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
            row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
            break;
          }
      }
    } else if (this.policyCode === "") {
      switch (covName) {
        case "EAO":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-A":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "EQ-C":
          row.get("CoverageLimitAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
        case "PD-COMP-COVA":
        case "TE-EP-COVC":
        case "TE-COMP-COVC":
        case "SNS-COMP-COVA":
        case "SNS-EP-COVA":
        case "SNS-LP-COVA":
        case "SD-LP-COVA":
        case "SD-EP-COVA":
        case "RD-EP-COVA":
        case "RD-COMP-COVA":
        case "MH-LP-COVA":
        case "MH-EP-COVA":
        case "MH-COMP-COVA":
          row.get("CoverageDeductibleAmt").valueChanges.subscribe((value) => this.handleLimitSubscriptionDefaults(row, covName));
          break;
      }
    }
  }

  /**
   * Created within the {@link createFieldSubscription} function. When the coverage given in the parameters 
   * has its limit changed by the user, it sets the limits of coverages dependent on it to their
   * new appropriate value.
   * @param row the row that the coverage belongs to
   * @param covName the coverage name
   */
  handleLimitSubscriptionDefaults(row: FormGroup, covName: string, overwrite: boolean = true) {
    const limit = this.getLimit(covName);
    const deduct = this.getDeductible(covName);
    if (this.policyCode == "HO") {
      switch (covName) {
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("BEDBRK"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ANLE"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("E33DYC"), limit, undefined, 2000000);
          this.setLimitDefault(overwrite, this.getCoverage("E40HBL"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("WALIA"), limit);
          break;
        case "COVA":
          this.setLimitDefault(overwrite, this.getCoverage("COVB"), limit * 0.15, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("COVC"), limit * 0.80, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 0.25, 0, 99999999);
          this.setDeductibleDefault(this.getCoverage("COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E9LSBU"), deduct);
        // fall through
        case "COVB":
        case "COVC":
        case "COVD":
          const COVA = this.getLimit("COVA");
          const COVB = this.getLimit("COVB");
          const COVC = this.getLimit("COVC");
          const COVD = this.getLimit("COVD");
          this.setLimitDefault(overwrite, this.getCoverage("E27CSL"), COVA + COVB + COVC + COVD);
          break;
      }
    } else if (this.policyCode == "CO") {
      switch (covName) {
        case "COVC":
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 2);
          this.setLimitDefault(overwrite, this.getCoverage("E12CCP"), limit * 5);
          this.setLimitDefault(overwrite, this.getCoverage("E13LA1"), limit * 5);
          this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit * 4);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
          this.setDeductibleDefault(this.getCoverage("E12CCP"), deduct);
          this.setDeductibleDefault(this.getCoverage("E13LA1"), deduct);
          this.setDeductibleDefault(this.getCoverage("E16IAB"), deduct);
          this.setDeductibleDefault(this.getCoverage("E43DAC"), deduct);
          this.setDeductibleDefault(this.getCoverage("E28SLE"), deduct);
          this.setDeductibleDefault(this.getCoverage("E39HBP"), deduct);
          this.setDeductibleDefault(this.getCoverage("E5RENT"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILBUSP"), deduct);
          break;
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("E14LAO"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("BEDBRK"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ANLE"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("E33DYC"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("E40HBL"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("RESEMP"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("WALIA"), limit);
          break;
      }
    } else if (["COR", "COS"].includes(this.policyCode)) {
      switch (covName) {
        case "COVC":
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 2);
          this.setLimitDefault(overwrite, this.getCoverage("E12CCP"), limit * 5);
          this.setLimitDefault(overwrite, this.getCoverage("E13LA1"), limit * 5);
          this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit * 4);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
          this.setDeductibleDefault(this.getCoverage("E12CCP"), deduct);
          this.setDeductibleDefault(this.getCoverage("E13LA1"), deduct);
          this.setDeductibleDefault(this.getCoverage("E16IAB"), deduct);
          this.setDeductibleDefault(this.getCoverage("E43DAC"), deduct);
          this.setDeductibleDefault(this.getCoverage("E5RENT"), deduct);
          break;
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("E14LAO"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit);
          break;
      }
    } else if (this.policyCode == "CPL") {
      switch (covName) {
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("ANLE"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("BEDBRK"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("E14LAO"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("E33DYC"), limit, undefined, 2000000);
          this.setLimitDefault(overwrite, this.getCoverage("E40HBL"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("INCBUS"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("WALIA"), limit)
          break;
      }
    } else if (this.policyCode == "CGL") {
      switch (covName) {
        case "CGLABI":
          this.setLimitDefault(overwrite, this.getCoverage("CGLBPI"), limit);
          break;
      }
    } else if (this.policyCode == "SEAH") {
      switch (covName) {
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("WALIA"), limit);
          break;
        case "COVA":
          this.setLimitDefault(overwrite, this.getCoverage("COVB"), limit * 0.10, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("COVC"), limit * 0.50, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 0.25, 0, 99999999);
          this.setDeductibleDefault(this.getCoverage("COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
        // fall through
        case "COVB":
        case "COVC":
        case "COVD":
          const COVA = this.getLimit("COVA");
          const COVB = this.getLimit("COVB");
          const COVC = this.getLimit("COVC");
          const COVD = this.getLimit("COVD");
          this.setLimitDefault(overwrite, this.getCoverage("E27CSL"), COVA + COVB + COVC + COVD, 0, 99999999);
          break;
      }
    } else if (this.policyCode == "FEC") {
      switch (covName) {
        case "BUILF":
          if (this.getLimit("BUILA") == 0) this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit, 0, 99999999);
          break;
        case "BUILA":
          this.setLimitDefault(overwrite, this.getCoverage("PPAR"), limit * 0.10, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit, 0, 99999999);
          break;
      }
      switch (covName) {
        case "PPAR":
        case "BUILA":
        case "PPFEC":
        case "BUILF":
          const fec_deduct = this.getDeductibleFromPossibleArray(['PPAR', 'BUILA', 'PPFEC', 'BUILF']);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), fec_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("E13LA1"), fec_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("E16IAB"), fec_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("E43DAC"), fec_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("E5RENT"), fec_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("RIDFEC"), fec_deduct.deductible);
          break;
      }
    } else if (this.policyCode == "PINS") {
      switch (covName) {
        case "PINSF":
        case "PINSA":
          const PINSF = this.getLimit("PINSF");
          const PINSA = this.getLimit("PINSA");
          this.setLimitDefault(overwrite, this.getCoverage("E18BRG"), PINSF + PINSA);

          const pins_deduct = this.getDeductibleFromPossibleArray(['PINSA', 'PINSF']);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), pins_deduct.deductible);
          this.setDeductibleDefault(this.getCoverage("E18BRG"), pins_deduct.deductible);
          break;
      }
    } else if (this.policyCode == "SEAR") {
      switch (covName) {
        case "BUILF":
          this.setDeductibleDefault(this.getCoverage("PPFEC"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
          this.setDeductibleDefault(this.getCoverage("E18BRG"), deduct);
          this.setDeductibleDefault(this.getCoverage("E5RENT"), deduct);
          this.setDeductibleDefault(this.getCoverage("E7VAND"), deduct);
          break;
        case "PPFEC":
          this.setLimitDefault(overwrite, this.getCoverage("E18BRG"), limit);
          break;
      }
    } else if (this.policyCode == "SETP") {
      switch (covName) {
        case "COVC":
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 0.25);
          this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit * 0.10, 0, 99999999);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
          this.setDeductibleDefault(this.getCoverage("E16IAB"), deduct);
          this.setDeductibleDefault(this.getCoverage("E28SLE"), deduct);
          break;
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          break;
      }
    } else if (this.policyCode == "TE") {
      switch (covName) {
        case "COVC":
          this.setLimitDefault(overwrite, this.getCoverage("COVD"), limit * 0.25);
          this.setLimitDefault(overwrite, this.getCoverage("E16IAB"), limit * 0.10, 0, 99999999);
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit);
          this.setDeductibleDefault(this.getCoverage("COVD"), deduct);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), deduct);
          this.setDeductibleDefault(this.getCoverage("E16IAB"), deduct);
          this.setDeductibleDefault(this.getCoverage("E28SLE"), deduct);
          this.setDeductibleDefault(this.getCoverage("E39HBP"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILAUTO"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILBOOK"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILBUSP"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILGRAV"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILMEQP"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILNURS"), deduct);
          this.setDeductibleDefault(this.getCoverage("ILSOFT"), deduct);
          this.setDeductibleDefault(this.getCoverage("STUDEX"), deduct);
          break;
        case "COVE":
          this.setLimitDefault(overwrite, this.getCoverage("ADDLRM"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADDRR"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI1"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ADNILI2"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("ANLE"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("BEDBRK"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("E33DYC"), limit, undefined, 2000000);
          this.setLimitDefault(overwrite, this.getCoverage("E40HBL"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("INCBUS"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("RESEMP"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("SPLIA"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("WALIA"), limit);
          this.setLimitDefault(overwrite, this.getCoverage("WBLIA"), limit);
          break;
      }
    } else if (this.policyCode == "PD") {
      switch (covName) {
        case "PD-COMP-COVA":
        case "PD-EP-COVA":
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVB`), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVC`), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVD`), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-A"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-C"), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVB`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVC`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVD`), deduct);
          break;
        case "EQ-A":
          this.setDeductibleDefault(this.getCoverage("EQ-A"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          break;
      }
    } else if (this.policyCode == "SNS") {
      switch (covName) {
        case "SNS-COMP-COVA":
        case "SNS-EP-COVA":
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVB`), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVC`), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVD`), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-A"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-C"), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVB`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVC`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVD`), deduct);
          break;
        case "EQ-A":
          this.setDeductibleDefault(this.getCoverage("EQ-A"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          break;
      }
    } else if (this.policyCode == "SD") {
      switch (covName) {
        case "SD-COMP-COVA":
        case "SD-BP-COVA":
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVB`), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVC`), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVD`), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-A"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-C"), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVB`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVC`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVD`), deduct);
          break;
        case "EQ-A":
          this.setDeductibleDefault(this.getCoverage("EQ-A"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          break;
      }
    } else if (this.policyCode == "MH") {
      switch (covName) {
        case "MH-BP-COVA":
        case "MH-COMP-COVA":
        case "MH-LP-COVA":
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVB`), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVC`), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVD`), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-A"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-C"), limit * 0.7)
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVB`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVC`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVD`), deduct);
          break;
        case "EQ-A":
          this.setDeductibleDefault(this.getCoverage("EQ-A"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-LC"), deduct);
          break;
      }
    } else if (this.policyCode == "RD") {
      switch (covName) {
        case "RD-LP-COVA":
        case "RD-BP-COVA":
        case "RD-COMP-COVA":
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVB`), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-COVC`), limit * 0.5)
          this.setLimitDefault(overwrite, this.getCoverage(`${this.packageType}-RI`), limit * 0.5)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-A"), limit)
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1)
          this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVB`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-COVC`), deduct);
          this.setDeductibleDefault(this.getCoverage(`${this.packageType}-RI`), deduct);
          break;
        case "EQ-A":
          this.setDeductibleDefault(this.getCoverage("EQ-A"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-LC"), deduct);
          break;
      }
    } else if (["FBCP", "FBEP", "FBBP", "FBLP"].includes(this.formTypeCd)) {
      switch (covName) {
        case "FBCP":
        case "FBBP":
        case "FBEP":
        case "FBLP":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, this.getCoverage("WATERPROL"), limit)
            this.setDeductibleDefault(this.getCoverage("WATERPROL"), deduct);
          }
      }
    } else if (this.policyCode === "") { // MFI coverages
      switch (covName) {
        case "EAO":
          this.setLimitDefault(overwrite, this.getCoverage("AEP"), (limit + 1000000));
          break;
        case "EQ-A":
          this.setLimitDefault(overwrite, this.getCoverage("EQ-B"), limit * 0.1);
          this.setLimitDefault(overwrite, this.getCoverage("EQ-C"), limit * 0.7);
          this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          this.setDeductibleDefault(this.getCoverage("EQ-LC"), deduct);
          break;
        case "EQ-C":
          if (this.getCoverage("EQ-A") == null) {
            this.setLimitDefault(overwrite, this.getCoverage("EQ-D"), limit * 0.2);
            this.setDeductibleDefault(this.getCoverage("EQ-D"), deduct);
          }
          break;
        case "PD-COMP-COVA":
          this.setDeductibleDefault(this.getCoverage("PD-COMP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("PD-COMP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("PD-COMP-COVD"), deduct);
          break;
        case "TE-EP-COVC":
          this.setDeductibleDefault(this.getCoverage("TE-EP-COVD"), deduct);
          break;
        case "TE-COMP-COVC":
          this.setDeductibleDefault(this.getCoverage("TE-COMP-COVD"), deduct);
          break;
        case "SNS-COMP-COVA":
          this.setDeductibleDefault(this.getCoverage("SNS-COMP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-COMP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-COMP-COVD"), deduct);
          break;
        case "SNS-EP-COVA":
          this.setDeductibleDefault(this.getCoverage("SNS-EP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-EP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-EP-COVD"), deduct);
          break;
        case "SNS-LP-COVA":
          this.setDeductibleDefault(this.getCoverage("SNS-LP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-LP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("SNS-LP-COVD"), deduct);
          break;
        case "SD-LP-COVA":
          this.setDeductibleDefault(this.getCoverage("SD-LP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("SD-LP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("SD-LP-COVD"), deduct);
          break;
        case "SD-EP-COVA":
          this.setDeductibleDefault(this.getCoverage("SD-EP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("SD-EP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("SD-EP-COVD"), deduct);
          break;
        case "RD-EP-COVA":
          this.setDeductibleDefault(this.getCoverage("RD-EP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("RD-EP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("RD-EP-COVD"), deduct);
          break;
        case "RD-COMP-COVA":
          this.setDeductibleDefault(this.getCoverage("RD-COMP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("RD-COMP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("RD-COMP-COVD"), deduct);
          break;
        case "MH-LP-COVA":
          this.setDeductibleDefault(this.getCoverage("MH-LP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-LP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-LP-COVD"), deduct);
          break;
        case "MH-EP-COVA":
          this.setDeductibleDefault(this.getCoverage("MH-EP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-EP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-EP-COVD"), deduct);
          break;
        case "MH-COMP-COVA":
          this.setDeductibleDefault(this.getCoverage("MH-COMP-COVB"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-COMP-COVC"), deduct);
          this.setDeductibleDefault(this.getCoverage("MH-COMP-COVD"), deduct);
          break;
      }
    }
  }

  /**
   * Sets default values for a row when it is enabled by the user.
   * i.e. if user clicks the checkbox, this function is called.
   * @param row the row that was enabled
   */
  handleSpecialDefaults(row: FormGroup, overwrite: boolean = true) {
    if (overwrite === false) {
      return;
    }
    const covName = row.get("CoverageNameCd").value;
    if (this.policyCode == "HO") {
      switch (covName) {
        case "E27CSL":
          const COVA = this.getLimit("COVA");
          const COVB = this.getLimit("COVB");
          const COVC = this.getLimit("COVC");
          const COVD = this.getLimit("COVD");
          this.setLimitDefault(overwrite, row, COVA + COVB + COVC + COVD);
          break;
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "ADDRR":
        case "ADDLRM":
        case "BEDBRK":
        case "ANLE":
        case "E40HBL":
        case "SPLIA":
        case "WALIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
        case "E9LSBU":
          this.setDeductibleDefault(row, this.getDeductible("COVA"));
          break;
        case "E33DYC":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"), undefined, 2000000);
          break;
      }
    } else if (this.policyCode == "CO") {
      switch (covName) {
        case "COVD":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 2);
          break;
        case "E12CCP":
        case "E13LA1":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 5);
          break;
        case "E16IAB":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 4);
          break;
        case "E14LAO":
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "ADDRR":
        case "ADDLRM":
        case "BEDBRK":
        case "ANLE":
        case "E33DYC":
        case "E40HBL":
        case "RESEMP":
        case "SPLIA":
        case "WALIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
      }
      switch (covName) {
        case "E10SWR":
        case "E12CCP":
        case "E13LA1":
        case "E16IAB":
        case "E43DAC":
        case "E28SLE":
        case "E39HBP":
        case "E5RENT":
        case "ILBUSP":
          this.setDeductibleDefault(row, this.getDeductible("COVC"));
          break;
      }
    } else if (["COR", "COS"].includes(this.policyCode)) {
      switch (covName) {
        case "COVD":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 2);
          break;
        case "E12CCP":
        case "E13LA1":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 5);
          break;
        case "E16IAB":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 4);
          break;
        case "E14LAO":
        case "ADDLRM":
        case "ADDRR":
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "SPLIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
      }
      switch (covName) {
        case "E10SWR":
        case "E12CCP":
        case "E13LA1":
        case "E16IAB":
        case "E43DAC":
        case "E5RENT":
          this.setDeductibleDefault(row, this.getDeductible("COVC"));
          break;
      }
    } else if (this.policyCode == "CPL") {
      switch (covName) {
        case "ADDLRM":
        case "ADDRR":
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "ANLE":
        case "BEDBRK":
        case "E14LAO":
        case "E40HBL":
        case "INCBUS":
        case "SPLIA":
        case "WALIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
        case "E33DYC":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"), undefined, 2000000);
          break;
      }
    } else if (this.policyCode == "CGL") {
      switch (covName) {
        case "CGLBPI":
          this.setLimitDefault(overwrite, row, this.getLimit("CGLABI"));
      }
    } else if (this.policyCode == "SEAH") {
      switch (covName) {
        case "COVB":
          this.setLimitDefault(overwrite, row, this.getLimit("COVA") * 0.10, 0, 99999999);
          break;
        case "COVC":
          this.setLimitDefault(overwrite, row, this.getLimit("COVA") * 0.50, 0, 99999999);
          break;
        case "COVD":
          this.setLimitDefault(overwrite, row, this.getLimit("COVA") * 0.25, 0, 99999999);
          break;
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "SPLIA":
        case "WALIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
        case "E27CSL":
          const COVA = this.getLimit("COVA");
          const COVB = this.getLimit("COVB");
          const COVC = this.getLimit("COVC");
          const COVD = this.getLimit("COVD");
          this.setLimitDefault(overwrite, row, COVA + COVB + COVC + COVD, 0, 99999999);
          break;
        case "E10SWR":
          this.setDeductibleDefault(row, this.getDeductible("COVA"));
          break;
      }
    } else if (this.policyCode == "FEC") {
      switch (covName) {
        case "E16IAB":
          if (this.getLimit("BUILA") == 0) this.setLimitDefault(overwrite, row, this.getLimit("BUILF"), 0, 99999999);
          else this.setLimitDefault(overwrite, row, this.getLimit("BUILA"), 0, 99999999);
          break;
        case "PPAR":
          this.setLimitDefault(overwrite, row, this.getLimit("BUILA") * 0.10, 0, 99999999);
          break;
        case "BUILA":
          // TODO: enable TVC?
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 200000, 99999999);
          break;
        case "BUILF":
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 0, 99999999);
          break;
      }
      switch (covName) {
        case "E10SWR":
        case "E13LA1":
        case "E16IAB":
        case "E43DAC":
        case "E5RENT":
        case "RIDFEC":
          const fec_deduct = this.getDeductibleFromPossibleArray(['PPAR', 'BUILA', 'PPFEC', 'BUILF']);
          this.setDeductibleDefault(row, fec_deduct.deductible);
          break;
      }
    } else if (this.policyCode == "PINS") {
      switch (covName) {
        case "E18BRG":
          this.setLimitDefault(overwrite, row, this.getLimit("PINSF") + this.getLimit("PINSA"));
        // fall through
        case "E10SWR":
          const pins_deduct = this.getDeductibleFromPossibleArray(['PINSA', 'PINSF']);
          this.setDeductibleDefault(this.getCoverage("E10SWR"), pins_deduct.deductible);
          break;
      }
    } else if (this.policyCode == "SEAR") {
      switch (covName) {
        case "E18BRG":
          this.setLimitDefault(overwrite, row, this.getLimit("PPFEC"));
        // Fall through
        case "PPFEC":
        case "E10SWR":
        case "E5RENT":
        case "E7VAND":
          this.setDeductibleDefault(row, this.getDeductible("BUILF"));
          break;
        case "BUILA":
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 25000, 99999999);
          break;
        case "BUILF":
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 0, 99999999);
          break;
      }
    } else if (this.policyCode == "GDC") {
      switch (covName) {
        case "BUILA":
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 200000, 99999999);
          break;
        case "BUILF":
          this.setLimitDefault(overwrite, row, this.data["RiskReplacementCostAmt"], 0, 99999999);
          break;
      }
    } else if (this.policyCode == "SETP") {
      switch (covName) {
        case "COVD":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 0.25);
          break;
        case "E16IAB":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 0.10, this.getLimit("COVC") * 0.10, 99999999);
          break;
        case "ADDLRM":
        case "ADDRR":
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
      }
      switch (covName) {
        case "E10SWR":
          this.setDeductibleDefault(row, this.getDeductible("COVC"));
          break;
        case "E16IAB":
        case "E28SLE":
          this.setDeductibleDefault(row, this.getDeductible("BUILF"));
          break;
      }
    } else if (this.policyCode == "TE") {
      switch (covName) {
        case "COVD":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 0.25);
          break;
        case "E16IAB":
          this.setLimitDefault(overwrite, row, this.getLimit("COVC") * 0.10, this.getLimit("COVC") * 0.10, 99999999);
          break;
        case "ADDLRM":
        case "ADDRR":
        case "ADNILI":
        case "ADNILI1":
        case "ADNILI2":
        case "ANLE":
        case "BEDBRK":
        case "E40HBL":
        case "INCBUS":
        case "RESEMP":
        case "SPLIA":
        case "WALIA":
        case "WBLIA":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"));
          break;
        case "E33DYC":
          this.setLimitDefault(overwrite, row, this.getLimit("COVE"), undefined, 2000000);
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVC`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVC`));
          }
          break;
      }
    } else if (this.policyCode == "PD") {
      switch (covName) {
        case "E10SWR":
        case "E16IAB":
        case "E28SLE":
        case "E39HBP":
        case "ILAUTO":
        case "ILBOOK":
        case "ILBUSP":
        case "ILGRAV":
        case "ILMEQP":
        case "ILNURS":
        case "ILSOFT":
        case "STUDEX":
          this.setDeductibleDefault(row, this.getDeductible("COVC"));
          break;
        case "EQ-A":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
          this.setDeductibleDefault(this.getCoverage("EQ-A"), this.getDeductible(covName));
          break;
        case "EQ-B":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.1);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), this.getDeductible("EQ-A"));
          break;
        case "EQ-C":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.7);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), this.getDeductible("EQ-A"));
          break;
        case "EQ-D":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.2);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), this.getDeductible("EQ-A"));
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVA`));
          }
          break;
      }
    } else if (this.policyCode == "SNS") {
      switch (covName) {
        case "EQ-A":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
          this.setDeductibleDefault(this.getCoverage("EQ-A"), this.getDeductible(covName));
          break;
        case "EQ-B":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.1);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), this.getDeductible("EQ-A"));
          break;
        case "EQ-C":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.7);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), this.getDeductible("EQ-A"));
          break;
        case "EQ-D":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.2);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), this.getDeductible("EQ-A"));
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVA`));
          }
          break;
      }
    } else if (this.policyCode == "SD") {
      switch (covName) {
        case "EQ-A":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
          this.setDeductibleDefault(this.getCoverage("EQ-A"), this.getDeductible(covName));
          break;
        case "EQ-B":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.1);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), this.getDeductible("EQ-A"));
          break;
        case "EQ-C":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.7);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), this.getDeductible("EQ-A"));
          break;
        case "EQ-D":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.2);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), this.getDeductible("EQ-A"));
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVA`));
          }
          break;
      }
    } else if (this.policyCode == "MH") {
      switch (covName) {
        case "EQ-A":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
          this.setDeductibleDefault(this.getCoverage("EQ-A"), this.getDeductible(covName));
          break;
        case "EQ-B":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.1);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), this.getDeductible("EQ-A"));
          break;
        case "EQ-C":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.7);
          this.setDeductibleDefault(this.getCoverage("EQ-C"), this.getDeductible("EQ-A"));
          break;
        case "EQ-D":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.2);
          this.setDeductibleDefault(this.getCoverage("EQ-D"), this.getDeductible("EQ-A"));
          break;
        case "EQ-LC":
          this.setDeductibleDefault(this.getCoverage("EQ-LC"), this.getDeductible("EQ-A"));
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVA`));
          }
          break;
      }
    } else if (this.policyCode == "RD") {
      switch (covName) {
        case "EQ-A":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
          this.setDeductibleDefault(this.getCoverage("EQ-A"), this.getDeductible(covName));
          break;
        case "EQ-B":
          this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`) * 0.1);
          this.setDeductibleDefault(this.getCoverage("EQ-B"), this.getDeductible("EQ-A"));
          break;
        case "EQ-LC":
          this.setDeductibleDefault(this.getCoverage("EQ-LC"), this.getDeductible("EQ-A"));
          break;
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(`${this.packageType}-COVA`));
            this.setDeductibleDefault(row, this.getDeductible(`${this.packageType}-COVA`));
          }
          break;
      }
    } else if (["FBCP", "FBEP", "FBBP", "FBLP"].includes(this.formTypeCd)) {
      switch (covName) {
        case "WATERPROL":
          if (this.province != 'MB') {
            this.setLimitDefault(overwrite, row, this.getLimit(this.formTypeCd));
            this.setDeductibleDefault(row, this.getDeductible(this.formTypeCd));
            break;
          }
      }
    } else if (this.policyCode == "") {
      switch (covName) {
        case "EQ-A":
          const possibleFields_eqa = [
            "PD-COMP-COVA",
            "TE-EP-COVC",
            "TE-COMP-COVC",
            "SNS-COMP-COVA",
            "SNS-EP-COVA",
            "SNS-LP-COVA",
            "SD-LP-COVA",
            "SD-EP-COVA",
            "RD-EP-COVA",
            "RD-COMP-COVA",
            "MH-LP-COVA",
            "MH-EP-COVA",
            "MH-COMP-COVA",
            "FBCP"
          ];
          const eqaLimitResult = this.getLimitFromPossibleArray(possibleFields_eqa);
          if (!row.get('CoverageLimitAmt'))
            this.setLimitDefault(overwrite, row, eqaLimitResult.limitDefault);

          let eqaDeductDefault: number;
          if (eqaLimitResult.foundField == "FBCP") {
            eqaDeductDefault = 5;
          } else {
            eqaDeductDefault = 10;
          }
          this.setDeductibleDefault(row, eqaDeductDefault);
          break;
        case "EQ-B":
          const possibleParentFields_eqb = [
            "EQ-A",
            "PD-COMP-COVA",
            "TE-EP-COVC",
            "TE-COMP-COVC",
            "SNS-COMP-COVA",
            "SNS-EP-COVA",
            "SNS-LP-COVA",
            "SD-LP-COVA",
            "SD-EP-COVA",
            "RD-EP-COVA",
            "RD-COMP-COVA",
            "MH-LP-COVA",
            "MH-EP-COVA",
            "MH-COMP-COVA",
            "FBCP"
          ];
          const eqbLimitResult = this.getLimitFromPossibleArray(possibleParentFields_eqb);
          if (!row.get('CoverageLimitAmt'))
            this.setLimitDefault(overwrite, row, eqbLimitResult.limitDefault * 0.1);

          let eqbDeductibleDefault = this.getDeductible("EQ-A");
          if (eqbDeductibleDefault == 0) {
            eqbDeductibleDefault = 10;
          }
          this.setDeductibleDefault(row, eqbDeductibleDefault);
          break;
        case "EQ-C":
          const possibleParentFields_eqc = [
            "FCCP",
            "EQ-A",
            "PD-COMP-COVA",
            "TE-EP-COVC",
            "TE-COMP-COVC",
            "SNS-COMP-COVA",
            "SNS-EP-COVA",
            "SNS-LP-COVA",
            "SD-LP-COVA",
            "SD-EP-COVA",
            "RD-EP-COVA",
            "RD-COMP-COVA",
            "MH-LP-COVA",
            "MH-EP-COVA",
            "MH-COMP-COVA"
          ];
          const eqcLimitResult = this.getLimitFromPossibleArray(possibleParentFields_eqc);
          if (!(eqcLimitResult.foundField == "FCCP" ||
            eqcLimitResult.foundField == "TE-EP-COVC" ||
            eqcLimitResult.foundField == "TE-COMP-COVC")) {
            eqcLimitResult.limitDefault = eqcLimitResult.limitDefault * 0.7;
          }
          if (!row.get('CoverageLimitAmt'))
            this.setLimitDefault(overwrite, row, eqcLimitResult.limitDefault);

          let eqcDeductibleDefault = this.getDeductible("EQ-A");
          if (eqcDeductibleDefault == 0) {
            if (eqcLimitResult.foundField == "FCCP") {
              eqcDeductibleDefault = 5;
            } else {
              eqcDeductibleDefault = 10;
            }
          }
          this.setDeductibleDefault(row, eqcDeductibleDefault);
          break;
        case "EQ-D":
          const possibleParentFields_eqd = [
            "EQ-A",
            "PD-COMP-COVA",
            "TE-EP-COVC",
            "TE-COMP-COVC",
            "SNS-COMP-COVA",
            "SNS-EP-COVA",
            "SNS-LP-COVA",
            "SD-LP-COVA",
            "SD-EP-COVA",
            "RD-EP-COVA",
            "RD-COMP-COVA",
            "MH-LP-COVA",
            "MH-EP-COVA",
            "MH-COMP-COVA",
            "FBCP"
          ];
          const eqdLimitResult = this.getLimitFromPossibleArray(possibleParentFields_eqd);
          eqdLimitResult.limitDefault = eqdLimitResult.limitDefault * 0.2;
          if (!row.get('CoverageLimitAmt'))
            this.setLimitDefault(overwrite, row, eqdLimitResult.limitDefault);

          let eqdDeductibleDefault = this.getDeductible("EQ-A");
          if (eqdDeductibleDefault == 0) {
            eqdDeductibleDefault = this.getDeductible("EQ-C");
            if (eqdDeductibleDefault == 0) {
              eqdDeductibleDefault = 10;
            }
          }
          this.setDeductibleDefault(row, eqdDeductibleDefault);
          break;
        case "EQ-LC":
          let eqlcDeductibleDefault = this.getDeductible("EQ-A");
          if (eqlcDeductibleDefault == 0) {
            eqlcDeductibleDefault = 10;
          }
          this.setDeductibleDefault(row, eqlcDeductibleDefault);
          break;
        case "EP-FME":
          if (!row.get('CoverageLimitAmt'))
            this.setLimitDefault(overwrite, row, this.additionalDependencies['ScheduleItemValueAmt'].value);
        // Fall through
        case "LUEX":
        case "EBMB":
        case "EP-COVY":
        case "FLHP":
        case "FTL":
        case "HPRO":
        case "PDCC":
        case "PROFLT_ATV":
        case "PROFLT_FTC":
        case "FPSOLAR":
        case "FPDRONE":
          this.setDeductibleDefault(row, this.additionalDependencies['ScheduleItemDeductibleCd'].value);
          break;
      }
    }
  }

  /**
   * Sets the minimum and maximum allowed limit values for coverages. If the value is outside the allowed limit,
   * an error message is shown to the user.
   */
  handleMinMax() {
    if (this.policyCode == "HO") {
      this.setMinMax("COVA", undefined, 99999999);
      this.setMinMax("COVB", this.getLimit("COVA") * 0.15, 99999999);
      this.setMinMax("COVC", this.getLimit("COVA") * 0.80, 99999999);
      this.setMinMax("COVD", this.getLimit("COVA") * 0.25, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E49ITE", 1, 1000000);
      this.setMinMax("E9LSBU", this.getDeductible("COVA"), undefined, "CoverageDeductibleAmt");
      this.setMinMax("E11CWC", this.getDeductible("COVA"), undefined, "CoverageDeductibleAmt");
      this.setMinMax("E33DYC", undefined, 2000000);
      this.setMinMax("E39HBE", 2500, 75000);
      this.setMinMax("E39HBP", 1, 75000);
      this.setMinMax("E5RENT", 1, 99999999);
    } else if (this.policyCode == "CO") {
      this.setMinMax("COVC", 1, 99999999);
      this.setMinMax("E14LAO", 0, undefined);
      this.setMinMax("E16IAB", this.getLimit("COVC") * 4, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E49ITE", 0, 999999999);
      this.setMinMax("E39HBE", 2500, 75000);
      this.setMinMax("E39HBP", 1, 75000);
      this.setMinMax("E5RENT", 1, 99999999);
      this.setMinMax("ILBUSP", 1, 99999999);
      this.setMinMax("E12CCP", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E13LA1", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E10SWR", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "COR") {
      this.setMinMax("COVC", 1, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E16IAB", this.getLimit("COVC") * 4, 99999999);
      this.setMinMax("E10FFF", 1, 999999.99);
      this.setMinMax("E5RENT", 1, 99999999);
      this.setMinMax("E12CCP", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E13LA1", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E10SWR", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "COS") {
      this.setMinMax("COVC", 1, 99999999);
      this.setMinMax("E16IAB", this.getLimit("COVC") * 4, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E5RENT", 1, 99999999);
      this.setMinMax("E12CCP", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E13LA1", this.getLimit("COVC") * 5, undefined);
      this.setMinMax("E10SWR", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "DWC") {
      this.setMinMax("RIDDCR", 1, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
    } else if (this.policyCode == "SEAH") {
      this.setMinMax("COVA", 75000, 99999999);
      this.setMinMax("COVB", 0, 99999999);
      this.setMinMax("COVC", this.getLimit("COVA") * 0.50, 99999999);
      this.setMinMax("COVD", this.getLimit("COVA") * 0.25, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E27CSL", 0, 99999999);
      this.setMinMax("E10SWR", this.getDeductible("COVA"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "FEC") {
      this.setMinMax("PPFEC", 0, 99999999);
      this.setMinMax("BUILA", 200000, 99999999);
      this.setMinMax("PPAR", 0, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E13LA1", 10000, 99999999);
      this.setMinMax("E16IAB", 0, 99999999);
      this.setMinMax("E5RENT", 1, 99999999);
      this.setMinMax("RIDFEC", 1, 99999999);
      const fec_deduct = this.getDeductibleFromPossibleArray(['PPAR', 'BUILA', 'PPFEC', 'BUILF']);
      if (fec_deduct.deductible != 0) {
        this.setMinMax("E10SWR", fec_deduct.deductible, undefined, "CoverageDeductibleAmt");
      }
    } else if (this.policyCode == "GDC") {
      this.setMinMax("BUILA", 200000, 99999999);
    } else if (this.policyCode == "PINS") {
      this.setMinMax("PINSF", 1, 99999999);
      this.setMinMax("PINSA", 1, 99999999);
      if (this.getDeductible("PINSA") == 0) {
        this.setMinMax("E10SWR", this.getDeductible("PINSF"), undefined, "CoverageDeductibleAmt");
      } else {
        this.setMinMax("E10SWR", this.getDeductible("PINSA"), undefined, "CoverageDeductibleAmt");
      }
    } else if (this.policyCode == "SEAR") {
      this.setMinMax("BUILF", 0, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("PPFEC", 1, 99999999);
      this.setMinMax("BUILA", 25000, 99999999);
      this.setMinMax("PPAR", 1, 99999999);
      this.setMinMax("E5RENT", 1, 99999999);
      this.setMinMax("E10SWR", this.getDeductible("BUILF"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "SETP") {
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E49ITE", 0, 999999999);
      this.setMinMax("E10FFF", 1, 999999.99);
      this.setMinMax("E16IAB", this.getLimit("COVC") * 0.10, 99999999);
      this.setMinMax("E10SWR", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
      this.setMinMax("E10FFF", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "TE") {
      this.setMinMax("COVC", 1, 99999999);
      this.setMinMax("E23FDC", 1, 99999999);
      this.setMinMax("E49ITE", 0, 999999999);
      this.setMinMax("E10FFF", 1, 999999.99);
      this.setMinMax("E16IAB", this.getLimit("COVC") * 0.10, 99999999);
      this.setMinMax("E39HBE", 2500, 75000);
      this.setMinMax("E39HBP", 1, 75000);
      this.setMinMax("ILAUTO", 1, 99999999);
      this.setMinMax("ILBOOK", 1, 99999999);
      this.setMinMax("ILBUSP", 1, 99999999);
      this.setMinMax("ILGRAV", 1, 99999999);
      this.setMinMax("ILMEQP", 1, 99999999);
      this.setMinMax("ILNURS", 1, 99999999);
      this.setMinMax("ILSOFT", 1, 99999999);
      this.setMinMax("E10SWR", this.getDeductible("COVC"), undefined, "CoverageDeductibleAmt");
    } else if (this.policyCode == "PD") {
      this.setMinMax(`${this.packageType}-COVA`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVB`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVC`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVD`, 1, 99999999);
      this.setMinMax("EQ-D", 1, 99999999);
      this.setMinMax("EQ-B", 1, 99999999);
      this.setMinMax("EQ-A", 1, 99999999);
      this.setMinMax("EQ-C", 1, 99999999);
    } else if (this.policyCode == "SD") {
      this.setMinMax(`${this.packageType}-COVA`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVB`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVC`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVD`, 1, 99999999);
      this.setMinMax("EQ-D", 1, 99999999);
      this.setMinMax("EQ-B", 1, 99999999);
      this.setMinMax("EQ-A", 1, 99999999);
      this.setMinMax("EQ-C", 1, 99999999);
    } else if (this.policyCode == "MH") {
      this.setMinMax(`${this.packageType}-COVA`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVB`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVC`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVD`, 1, 99999999);
      this.setMinMax("EQ-D", 1, 99999999);
      this.setMinMax("EQ-B", 1, 99999999);
      this.setMinMax("EQ-A", 1, 99999999);
      this.setMinMax("EQ-C", 1, 99999999);
      this.setMinMax("REBEE", 1, 200000000);
    } else if (this.policyCode == "RD") {
      this.setMinMax(`${this.packageType}-COVA`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVB`, 1, 99999999);
      this.setMinMax(`${this.packageType}-COVC`, 1, 99999999);
      this.setMinMax(`${this.packageType}-RI`, 1, 99999999);
      this.setMinMax("EQ-A", 1, 99999999);
      this.setMinMax("EQ-B", 1, 99999999);
      this.setMinMax("EQ-LC", 0, 99999999);
    } else if (this.formTypeCd == "EP") {
      this.setMinMax("PDCC", 1, 25000);
    } else if (this.tab == "liability") {
      switch (this.province) {
        case "MB":
        case "SK":
        case "AB":
          this.setMinMax("FEBILE", 10000, undefined);
          break;
        default:
          this.setMinMax("FEBILE", undefined, 99999999);

      }
    }
  }

  buildErrorMessage(field: AbstractControl) {
    this.handleMinMax();
    if (field.errors != null) {
      if (field.errors.message) {
        return field.errors.message;
      } else if (field.errors.required) {
        return 'Required';
      }
    }
    return null;
  }
}
