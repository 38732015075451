import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CustomizationManager } from '@core/services/customization-manager.service';
import { MessageService } from '@modules/policy/services/shared/message.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { UnderWritingRules } from '@shared/models/underwriting-rules.model';
import { BasicDialogComponent } from '../basic-dialog/basic-dialog.component';
import { DialogForm } from '../dialog-form/dialog-form.component';

@Component({
  selector: 'app-underwriter-review-dialog',
  templateUrl: './underwriter-review-dialog.component.html',
  styleUrls: ['./underwriter-review-dialog.component.scss']
})
export class UnderwriterReviewDialogComponent {
  message: string;
  postRulesMessage: string;
  title: string;
  dialogPrefix: string;
  underwritingRules: UnderWritingRules[];

  @ViewChild('messageTextArea', { static: false }) messageTextArea: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<DialogForm>,
    private dialog: MatDialog,
    private router: Router,
    private PolicyDataService: PolicyDataService,
    private MessageService: MessageService,
    private customizationManager: CustomizationManager
  ) {
    this.message = data.Message || 'The following rules require an underwriter to review and approve the policy:';
    this.postRulesMessage = data.postRulesMessage || 'Enter any additional information to submit below.';
    this.title = data.title || 'Request Underwriter Review';
    this.dialogPrefix = data.dialogPrefix || 'underwriter-review-dialog';
    this.underwritingRules = this.fetchUnderwritingRules(true);
  }

  fetchUnderwritingRules(returnOnlyReferRules: boolean): UnderWritingRules[] {
    const rules = this.PolicyDataService.underWritingRulesSubject.getValue();
    if (returnOnlyReferRules) {
      return rules.filter((rule) => {
        return rule.outcome === 'REFER';
      });
    }
    return rules;
  }

  sendRequest(): void {
    const message = this.buildMessage();
    this.dialogRef.close();
    
    this.MessageService.requestReview(
      this.PolicyDataService.getPolicyID(),
      { message }
    ).subscribe(
      (res) => {
        const confirmMessage = this.customizationManager.get('uw-review-confirm')
        this.router.navigate([`/dashboard`]);
        this.dialog.open(BasicDialogComponent, {
          data: {
            message: confirmMessage,
            onlyConfirm: true,
            title: 'Success',
            showDetails: false,
            dialogPrefix: this.dialogPrefix + '-success-modal'
          },
          width: '480px'
        });
      });
  }

  buildMessage(): string {
    let message = '';
    for (let rule of this.underwritingRules) {
      message += rule.message.replace(', refer to underwriter', '') + ' || ';
    }

    if (this.messageTextArea.nativeElement.value != '') {
      message += this.messageTextArea.nativeElement.value;
    } else {
      message = message.substring(0, message.length - 4);
    }

    return message;
  }

  buildElementId(buttonName: string): string {
    return (this.dialogPrefix != null ? this.dialogPrefix + '-' : '') + buttonName;
  }
}
