import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Input } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() placeholder;
  @Input() defaultDate;
  @Input() clear;
  @Input() name: string;
  @Output() widgetDateChange = new EventEmitter<any>();

  constructor() { }
  public selectedDate: FormControl;
  ngOnInit() {
    this.selectedDate = new FormControl()
  }

  ngOnChanges(changes) {
    if (changes.defaultDate && changes.defaultDate.currentValue) {
      this.selectedDate = new FormControl(changes.defaultDate.currentValue);
    }
  }
  onDateChange(dateRange) {
    this.widgetDateChange.emit(dateRange);
  }
  hasValue() {
    return this.selectedDate && this.selectedDate.value && this.selectedDate.value.begin && this.selectedDate.value.end ? true : false;
  }
  clearDate() {
    this.selectedDate.reset();
  }
}
