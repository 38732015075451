import { autofillAddress } from './../../util/google-places';
import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormTabComponent } from '../../form-tab.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { handleFormHiding } from '../../shared/hide-form.rules';
import { FieldLookupService } from '../../services/lookup.service';

// Display errors even if the field hasn't been touched
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.errors != null;
  }
}

@Component({
  selector: 'lib-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})
export class FormFieldComponent implements OnInit {

  @Input() abstractControl: AbstractControl;
  @Input() field: any;
  @Input() applyDefault: boolean = false;
  matcher = new MyErrorStateMatcher();
  filteredOptions: Observable<string[]>;
  resetFilter: boolean = false;
  hideField: boolean = false;

  constructor(
    public formtab: FormTabComponent,
    private lookupService: FieldLookupService,
  ) { }

  get selectValues() {
    return this.lookupService.selectValues[this.field.name]
  }

  ngOnInit() {
    if (this.applyDefault) {
      this.loadDefault()
    }
    this._initialValidation(this.field);
    switch (this.field.type) {
      case "object":
        // if (this.abstractControl.disabled) this.handleDisabledForm()
        break;
      case "string":
        this._initStringField(this.field);
        break;
      case "number":
        this._initNumberField(this.field);
        break;
      case "checkbox":
        this._initCheckboxField(this.field);
        break;
      case "select":
      case "search-select":
      case "yesno":
        this._initSelectField(this.field);
        this.filteredOptions = this.abstractControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        break;
      case "date":
        this._initDateField();
        break;
      default:
        break;
    }
  }

  private loadDefault() {
    if (this.field.type != "object" &&
      this.abstractControl != null &&
      this.abstractControl.value == null &&
      this.field.defaultValue != null) {
      this.abstractControl.patchValue(this.field.defaultValue)
    }
  }

  private _initialValidation(field) {
    if (this.formtab.form && this.formtab.formJson) {
      const formHiding = handleFormHiding(this.formtab.form, this.formtab.formJson, field);
      this.hideField = formHiding.init;
      formHiding.out.subscribe(value => {
        this.hideField = value;
      })
    }
    if (field["modal-only"]) {
      this.hideField = true;
    }
  }

  private _filter(value): string[] {
    if (this.resetFilter) {
      this.resetFilter = false;
      return this.selectValues;
    } else if (typeof value != "string") return;
    const filterValue = value.toLowerCase();
    return this.selectValues.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  handleDisabledForm() {
    if (this.field["conditionalRequirements"] != undefined) this.abstractControl.enable();
  }

  showSelectionPopup() {
    this.resetFilter = true;
    this.abstractControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  // checks that search-select input matches either the lookup label or value. if it matches the label,
  // it sets the field's value to the lookup's value instead
  handleSearchSelectInput() {
    if (this.abstractControl.value == "" || this.abstractControl.value == undefined || this.abstractControl.value == null) return;
    for (let value of this.selectValues) {
      if (value.value == this.abstractControl.value) return;
      else if (typeof this.abstractControl.value == "string" && value.label.toLowerCase() == this.abstractControl.value.toLowerCase()) {
        this.abstractControl.setValue(value.value);
        return;
      }
    }
    this.abstractControl.setErrors({ message: "Entered value is not a valid option. Please select an option from the selection menu." })
  }

  getOptionText(options, value) {
    for (let option of options) if (option.value == value) return option.label
    return '';
  }

  fullRow() {
    // if (!this.field.class) { return false; }
    // return this.field.class.includes('row');
    return true;
  }

  callFunc(name, context, fieldName, config, formValues) {
    if (!name) { return false }
    const args = Array.prototype.slice.call(arguments, 2);
    return context[name].apply(context, args);
  }

  // String field related inits
  _initStringField(field) {
  }

  // Number field related inits
  _initNumberField(field) { }

  _initSelectField(field) {
    if (field.options && field.options.length > 0) {
      this.lookupService.updateSelectValues(field.name, field.options);
      if (!(this.abstractControl.value == "" && typeof this.abstractControl.value == "string") && this.abstractControl.value != null &&
        this.selectValues[0].value != "CLEAR_VALUES") this.selectValues.unshift({ label: "-- Clear --", value: "CLEAR_VALUES" });
      if (this.selectValues.length >= 35 && this.field.type == "select") this.field.type = "search-select";
    }
  }

  checkSelectClear(value) {
    if (value == "CLEAR_VALUES") {
      this.abstractControl.reset();
      this.abstractControl.setValue(null);
      if (this.selectValues[0].value == "CLEAR_VALUES") this.selectValues.shift();
    }
    else if (this.selectValues[0].value != "CLEAR_VALUES" && !(this.abstractControl.value == "" && typeof this.abstractControl.value == "string") &&
      this.abstractControl.value != null) this.selectValues.unshift({ label: "-- Clear --", value: "CLEAR_VALUES" });
  }

  _initCheckboxField(field) {
    if (this.abstractControl.value !== true) {
      this.abstractControl.setValue(false);
    }
  }

  _initDateField() {
    if (typeof this.abstractControl.value == "string" && this.abstractControl.value.length > 0) {
      const date = this.abstractControl.value.split("-");
      if (date.length == 3) {
        this.abstractControl.setValue(date[0] + "-" + date[1].padStart(2, "0") + "-" + date[2].padStart(2, "0"))
      }
    }
  }

  getAddress(place) {
    place.address_components && autofillAddress(place, this.formtab.form.controls["RiskAddress"]);
  }

  onBlurNumber(control: AbstractControl) {
    const val = parseInt(control.value);
    if (isNaN(val)) {
      control.setValue(null);
    } else {
      control.setValue(val);
    }
  }

  toFormattedDate(iso: string) {
    const date = new Date(iso);
    const formattedDate = "" + date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" + date.getDate().toString().padStart(2, "0");
    this.abstractControl.setValue(formattedDate);
    this.abstractControl.markAsDirty();
  }

}
