import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, of } from 'rxjs';
import PolicyholderTabs from '@shared/models/policyholder.model';

@Injectable({
  providedIn: 'root'
})
export class PolicyholderService {
  public dataSourceSubject: BehaviorSubject<PolicyholderTabs[]>;
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) {
    this.dataSourceSubject = new BehaviorSubject([]);
  }

  updateDataSource(data: PolicyholderTabs[]) {
    this.dataSourceSubject.next(data);
  }

  add(quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/policyholder`, {}, { params: { quoteId, parentId } });
  }

  delete(quoteId, parentId, nodeId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/policyholder`, { params: { quoteId, parentId, nodeId } });
  }

  getClientsByZip(PolicyholderCompanyNameTxt: string, PolicyholderAddressPostalTxt: string, quoteId: any) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/contactsByNameZipCode/policyholder`, {
      params: {
        PolicyholderCompanyNameTxt,
        PolicyholderAddressPostalTxt,
        quoteId
      }
    });
  }

  getClientsByName(PolicyholderFirstNameTxt: string, PolicyholderLastNameTxt: string, PolicyholderAddressPostalTxt: string, quoteId: any) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/search/contactsByNameZipCode/policyholder`, {
      params: {
        PolicyholderFirstNameTxt,
        PolicyholderLastNameTxt,
        PolicyholderAddressPostalTxt,
        quoteId
      }
    });
  }
}
