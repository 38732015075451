import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  ratePolicy(quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/underwrite/quote`, { params: { quoteId } });
  }

  checkout(quoteId) {
    return this.http.post<any>(`${this.config.apiUrl}/workflow/lock`, {}, { params: { quoteId } });
  }

  bindPolicy(quoteId) {
    return this.http.post<any>(`${this.config.apiUrl}/workflow/bindbrokerbill`, {}, { params: { quoteId } });
  }
}
