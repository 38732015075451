import { TreeService } from '../../../../../services/location/tree.service';
import { PolicyDataService } from '../../../../../services/shared/policy-data.service';
import { SaveService } from '../../../../../services/shared/save.service';
import { ActivatedRoute } from '@angular/router';
import { PolicyService } from '@modules/policy/policy.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { MatTreeNestedDataSource } from '@angular/material';
import LocationTabs from '@shared/models/location.model';
import { PolicyState } from '@shared/models/state.model';
import { CoverageTableComponent } from '@modules/policy/shared/components/coverage-table/coverage-table.component';
import { of } from 'rxjs';

@Component({
  selector: 'app-building-coverages',
  templateUrl: './building-coverages.component.html',
  styleUrls: ['./building-coverages.component.scss']
})
export class BuildingCoveragesComponent implements OnInit {
  policyID: string;
  locationID: string;
  nodeID: string;
  dataSource: MatTreeNestedDataSource<LocationTabs>;
  subtab: string = "farmCoverageDetails";
  tab: string = "location";
  states: PolicyState;
  outbuildingStates;
  form: FormGroup;
  @ViewChild("tableComponent", { static: false }) tableComponent: CoverageTableComponent;

  constructor(
    private route: ActivatedRoute,
    private PolicyDataService: PolicyDataService,
    private TreeService: TreeService,
    private PolicyService: PolicyService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService
  ) { }

  saveBeforeDeactivate() {
    if (this.PolicyService.getForm(Number(this.nodeID)) != null) {
      const cascadingIds: object = {
        nodeState: [Number(this.nodeID), this.form.valid],
        riskState: [this.outbuildingStates["id"], this.TreeService.isRiskValid(this.outbuildingStates["id"], this.locationID)],
        locationState: [Number(this.locationID), this.TreeService.isLocationValid(this.locationID)],
        tabState: [this.states["location"]["id"], this.form.parent ? this.form.parent.valid : false]
      };
      const cascadingStates = this.FormSaveHelper.buildStatePayload(cascadingIds);
      this.SaveService.updateStates(this.policyID, cascadingStates).subscribe();
      return this.tableComponent.saveBeforeDeactivate();
    }
    return of(null).toPromise();
  }

  showSaveSnackbar() {
    this.tableComponent.showSaveSnackbar();
    this.tableComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.matTreeSubscription();
    this.policyID = this.PolicyDataService.getPolicyID();
    this.route.params.subscribe(params => {
      this.locationID = params["locationId"];
      this.nodeID = params["formId"];
      this.PolicyDataService.stateSubject.subscribe(states => {
        this.updateCurrentRiskStates(states);
        this.states = states;
      });
    });
  }

  initForm(form: FormGroup) {
    this.form = form;
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.updateCurrentRiskStates(tree);
      this.states = tree;
      this.PolicyDataService.updateStates(tree);
    });
  }

  matTreeSubscription() {
    this.dataSource = new MatTreeNestedDataSource<LocationTabs>();
    this.TreeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
  }

  updateCurrentRiskStates(states) {
    const tabState = states["location"];
    const currentLocation = tabState.locations.find(loc => loc.id == this.locationID);
    if (currentLocation != null && currentLocation.outbuildings.length > 0) {
      const currentOutbuilding = currentLocation.outbuildings.find(outbuilding => outbuilding.farmCoverageDetails.id == this.nodeID);
      this.outbuildingStates = currentOutbuilding;
    } else {
      this.outbuildingStates = null;
    }  
  }
}
