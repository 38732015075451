import { PolicyDataService } from './../../../../../services/shared/policy-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';

@Component({
  selector: 'app-policy-coverage',
  templateUrl: './policy-coverage.component.html',
  styleUrls: ['./policy-coverage.component.scss']
})
export class PolicyCoverageComponent implements OnInit {
  form: FormGroup
  tabState;
  @ViewChild("formComponent", { static: true }) formComponent: FormComponent;

  constructor(
    private PolicyDataService: PolicyDataService
  ) { }

  ngOnInit() {
    const stateSubject = this.PolicyDataService.stateSubject;
    stateSubject.pipe(first()).subscribe(states => this.tabState = states["policy"]);
  }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.form = form;
    handleValidations(this.formComponent.formjson, this.form);
    this.handlePolicyCoverageValidations()
  }

  handlePolicyCoverageValidations() {
  }
}

