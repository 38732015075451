import { TreeService } from '@modules/policy/services/location/tree.service';
import { MatDialog, MatSidenav } from '@angular/material';
import { PolicyDataService } from '../../../services/shared/policy-data.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PolicyService } from '@modules/policy/policy.service';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { AssetFetcherService } from '@modules/policy/services/shared/asset-fetcher.service';
import { BehaviorSubject } from 'rxjs';
import { RatingService } from '@modules/policy/services/rating/rating.service';

@Component({
  selector: 'app-policy-header',
  templateUrl: './policy-header.component.html',
  styleUrls: ['./policy-header.component.scss']
})
export class PolicyHeaderComponent implements OnInit {
  private policyId;
  private lob: string;
  data;
  policyDescriptionTxt;
  numLocations;
  headerConfig;
  numUnderwritingRules: number;
  policyReadOnly: BehaviorSubject<boolean>;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;

  @Input()
  set headerData(data) {
    if (data) {
      this.data = data;
      this.lob = data.PolicyLineOfBusinessCd;
      this.TreeService.updateNumLocations(data.PolicyNumOfLocationsQty);
    }
  }

  constructor(
    private PolicyDataService: PolicyDataService,
    private PolicyService: PolicyService,
    private router: Router,
    public dialog: MatDialog,
    private TreeService: TreeService,
    private assetFetcherService: AssetFetcherService,
    private ratingService: RatingService
  ) { }

  ngOnInit() {
    this.policyId = this.PolicyDataService.getPolicyID();
    this.policyReadOnly = this.PolicyDataService.policyReadOnly
    this.headerConfig = this.PolicyDataService.getHeaderConfig();
    this.numUnderwritingRules = 0;
    this.TreeService.numLocationsSubject.subscribe(numLoc => this.setNumLocations(numLoc));
    this.PolicyDataService.policyDescriptionSubject.subscribe(data => {
      this.policyDescriptionTxt = data;
    });
    this.PolicyDataService.numUnderWritingRules.subscribe(num => {
      this.numUnderwritingRules = num;
    });
  }

  setNumLocations(value) {
    this.numLocations = value;
  }

  showDisclaimer() {
    this.assetFetcherService.getAssetFile({
      name: 'disclaimer-content.html',
      quoteId: this.policyId
    }).subscribe(
      (res) => {
        this.dialog.open(BasicDialogComponent, {
          data: {
            title: 'Disclaimer',
            htmlContent: res.data,
            buttons: [
              {
                label: 'OK',
                action: true
              }
            ]
          }
        });
      });
  }

  close() {
    this.sidenav.close();
  }

  handlePolicyAction() {
    if (!this.checkFormValidityAll()) {
      return this.dialog.open(BasicDialogComponent, {
        data: {
          title: 'Warning',
          message: 'Please ensure all data is valid before proceeding.',
          onlyConfirm: true
        },
        width: '400px'
      });
    }

    const { workflow } = this.headerConfig.action;
    switch (workflow) {
      case 'post':
        this.ratingService.ratePolicy(this.policyId).toPromise().then(res => {
          if (res.Error) this.openErrorModal(res.Error)
          else this.openPostModal(res.QuoteKey);
        })
        break;
      default:
        this.router.navigate([`/policy/${this.policyId}/${this.lob}/rating`]);
    }
  }

  checkFormValidityAll(): boolean {
    const tabForms = this.PolicyService.getTabForms();
    for (let tab in tabForms) {
      if (tabForms[tab].status === 'INVALID') {
        return false;
      }
    }

    return true;
  }

  getActionLabel() {
    return this.headerConfig.action.label;
  }

  openPostModal(quoteKey: string) {
    this.dialog.open(BasicDialogComponent, {
      data: {
        title: 'Quote Posted',
        message: `Comparative Quote successfully pushed to enterprise. 
        QuoteKey: ${quoteKey}`,
        onlyConfirm: true
      },
      width: '400px'
    }).afterClosed().toPromise().then(res => {
      this.router.navigate(['/dashboard'])
    });
  }

  openErrorModal({ Message, Details }) {
    this.dialog.open(BasicDialogComponent, {
      data: {
        title: 'Error',
        message: Message,
        details: Details,
        onlyConfirm: true,
        showDetails: Boolean(Details)
      },
      width: '400px'
    })
  }

  handleRenderer(displayItem) {
    const attributes = { 
      label: displayItem.label,
      action: null
    }

    switch (displayItem.renderer) {
      case 'locations':
        attributes.label = `${displayItem.label}: ${this.numLocations}`
        break
      case 'disclaimer':
        attributes.action = this.showDisclaimer.bind(this)
    }
    return attributes
  }

  ngOnDestroy() {
    this.PolicyDataService.updatePolicyDescription(null);
  }
}
