/// <reference types="@types/googlemaps" />
import { Component, Input, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormTabService } from '../../form-tab.service';
import { FormTabComponent } from '../../form-tab.component';

@Component({
  selector: 'lib-autofill-address',
  templateUrl: './autofill-address.component.html',
  styleUrls: ['./autofill-address.component.css']
})

export class AutofillAddressComponent implements AfterViewInit {

  // Placeholder
  @Input() abstractControl: AbstractControl;
  @Input() field: any;

  @Input() addressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  // Reference to HTML input tag
  @ViewChild("addressText", { static: false }) addressText: any;

  constructor(public formTabService: FormTabService, public formtab: FormTabComponent) { }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: { country: "CA" },
        types: ['address']
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: object) {
    this.setAddress.emit(place);
  }

}
