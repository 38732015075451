class Queue {
  private items: any[];
  constructor() {
    this.items = [];
  }

  isEmpty() {
    return (this.items.length === 0);
  }

  enqueue(item: any) {
    this.items.unshift(item);
  }

  dequeue() {
    return this.items.pop();
  }

  size() {
    return this.items.length;
  }
}

export default Queue;