import { combineLatest } from 'rxjs';
import { JsonFormFetcherService } from './../../../../../services/shared/json-form-fetcher.service';
import { SaveService } from './../../../../../services/shared/save.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { CoverageTableComponent } from './../../../../../shared/components/coverage-table/coverage-table.component';
import { TreeService } from '../../../../../services/location/tree.service';
import { PolicyService } from '../../../../../policy.service';
import { PolicyDataService } from '../../../../../services/shared/policy-data.service';
import { MatTreeNestedDataSource } from '@angular/material';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import LocationTabs from '@shared/models/location.model';
import { PolicyState } from '@shared/models/state.model';

@Component({
  selector: 'app-liability-coverages',
  templateUrl: './liability-coverages.component.html',
  styleUrls: ['./liability-coverages.component.scss']
})
export class LiabilityCoveragesComponent {
  policyID: string;
  locationID: string;
  nodeID: string;
  dataSource: MatTreeNestedDataSource<LocationTabs>;
  subtab: string = "coverages";
  tab: string = "location";
  states: PolicyState;
  listName: string;
  riskStates;
  form: FormGroup;
  @ViewChild("tableComponent", { static: false }) tableComponent: CoverageTableComponent;

  constructor(
    private route: ActivatedRoute,
    private PolicyDataService: PolicyDataService,
    private TreeService: TreeService,
    private PolicyService: PolicyService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService,
    private JsonFormFetcherService: JsonFormFetcherService
  ) { }

  saveBeforeDeactivate() {
    const cascadingIds: object = {
      nodeState: [Number(this.nodeID), this.form.valid],
      riskState: [this.riskStates["id"], this.TreeService.isRiskValid(this.riskStates["id"], this.locationID)],
      locationState: [Number(this.locationID), this.TreeService.isLocationValid(this.locationID)],
      tabState: [this.states["location"]["id"], this.form.parent ? this.form.parent.valid : false]
    };
    this.TreeService.updateParentState(this.dataSource.data, this.riskStates["id"], this.TreeService.isRiskValid(this.riskStates["id"], this.locationID));
    this.TreeService.updateParentState(this.dataSource.data, Number(this.locationID), this.TreeService.isLocationValid(Number(this.locationID)));
    const cascadingStates = this.FormSaveHelper.buildStatePayload(cascadingIds);
    this.SaveService.updateStates(this.policyID, cascadingStates).subscribe();
    return this.tableComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.tableComponent.showSaveSnackbar();
    this.tableComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.matTreeSubscription();
    this.policyID = this.PolicyDataService.getPolicyID();
    const tabsObs = this.JsonFormFetcherService.getTabs(this.policyID);
    this.route.params.subscribe(params => {
      this.locationID = params["locationId"];
      this.nodeID = params["formId"];
      combineLatest([this.PolicyDataService.stateSubject, tabsObs]).subscribe(res => {
        const [states, tabs] = res;
        this.listName = this.PolicyDataService.getListName(this.tab, this.subtab, tabs.tabs);
        this.updateCurrentRiskStates(states, this.listName);
        this.states = states;
      });
    })
  }

  initForm(form: FormGroup) {
    this.form = form;
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.updateCurrentRiskStates(tree, this.listName);
      this.states = tree;
      this.PolicyDataService.updateStates(tree);
    })
  }

  matTreeSubscription() {
    this.dataSource = new MatTreeNestedDataSource<LocationTabs>();
    this.TreeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
  }

  updateCurrentRiskStates(states, listName) {
    const tabState = states["location"];
    const currentLocation = tabState.locations.find(loc => loc.id == this.locationID);
    const currentRisk = currentLocation[listName].find(risk => risk["coverages"].id == this.nodeID);
    this.riskStates = currentRisk;
  }

}
