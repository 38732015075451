import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  updatePolicy(quoteId, body, nodeId) {
    return this.http.put<any>(`${this.config.apiUrl}/quote-editor/data`, body, { params: { quoteId, nodeId } });
  }

  updateStates(quoteId, body) {
    return this.http.put<any>(`${this.config.apiUrl}/quote-editor/navigation`, body, { params: { quoteId } });
  }
}
