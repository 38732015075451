import { SpinnerService } from '../services/spinner.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { HubioLoginService } from '@hubio/hubio-login';
import { CustomizationManager } from '@core/services/customization-manager.service';

type ErrorMessages = { 
  'default': string;
  'timeout': string ;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  requestCount: number = 0;

  readonly C8_REQUEST_ID_HEADER_KEY = 'X-Collabor8-ReqID';

  constructor(
    private router: Router,
    private auth: HubioLoginService,
    private SpinnerService: SpinnerService,
    private dialog: MatDialog,
    private policyService: PolicyCreationService,
    private customizationManager: CustomizationManager
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showSpinner();
    request = request.clone({
      setHeaders: {
        [this.C8_REQUEST_ID_HEADER_KEY]: uuidv4()
      }
    });

    return next.handle(request).pipe(
      timeout(environment.HTTP_REQUEST_DURATION),
      catchError((err) => {
        const endpoint = this.getEndpoint(request.url);
        const messages = this.fetchErrorMessage(endpoint);
        if (err.name === 'TimeoutError') {
          this.openErrorDialog(messages.timeout, err);
          return throwError(err);
        } else if (err.status === 401 || err.status === 403) {
          this.policyService.clearBrokers();
          this.auth.logout();
        } else if (err.status === 400) {
          this.navigateToDashboard()
          //TODO: 400 responses to include error object w/ message
          this.openErrorDialog('Page not available', err)
        } else {
          this.openErrorDialog(messages.default, err);
          return throwError(err);
        }
      }),
      finalize(
        () => {
          this.stopSpinner();
        }
      )
    )
  }

  fetchErrorMessage(asset: string): ErrorMessages {
    let msg = this.customizationManager.getSubset(asset, 'error') as ErrorMessages;
    if (msg) {
      return msg;
    } else {
      return this.customizationManager.getSubset('default', 'error') as ErrorMessages;
    }
  }

  showSpinner(): void {
    this.requestCount++;
    this.SpinnerService.show();
  }

  stopSpinner(): void {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.SpinnerService.hide();
    }
  }

  openErrorDialog(message: string, error: any) {
    let details;
    if (error.error && error.error.Error) {
      details = `Request Id: ${error.error.req_id} (${error.error.Error.code})`
    }

    this.dialog.open(BasicDialogComponent, {
      data: {
        message,
        details,
        onlyConfirm: true,
        title: 'Error',
        showDetails: (details != null),
        dialogPrefix: 'http-error-modal'
      },
      width: '480px'
    }).afterClosed().subscribe((_) => {
      this.navigateToDashboard();
    });
  }

  private navigateToDashboard() {
    this.router.navigateByUrl('/dashboard', { state: { skipSave: true } });
  }

  private getEndpoint(url: string) {
    const baseUrl = `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
    return url.replace(baseUrl, '')
  }
}
