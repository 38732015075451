import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MetadataService } from '@core/services/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataGuard implements CanActivate {

  private isLoaded: boolean;

  constructor(
    private metadataService: MetadataService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    if (this.isLoaded) {
      return true;
    } else {
      return this.metadataService.updateMetadata().then(() => {
        this.isLoaded = true;
        return true;
      });
    }
  }
}
