import ClaimTabs from '@shared/models/claims.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  public dataSourceSubject: BehaviorSubject<ClaimTabs[]>;
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  constructor(private http: HttpClient) {
    this.dataSourceSubject = new BehaviorSubject([]);
  }

  updateDataSource(data: ClaimTabs[]) {
    this.dataSourceSubject.next(data);
  }

  add(quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/claim`, {}, { params: { quoteId, parentId } });
  }

  delete(quoteId, parentId, nodeId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/claim`, { params: { quoteId, parentId, nodeId } });
  }
}
