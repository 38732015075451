import { PolicyService } from '../../policy.service';
import { Injectable } from '@angular/core';
import LocationTabs from '@shared/models/location.model'

@Injectable({
  providedIn: 'root'
})
export class NestedMatTreeGenerator {

  readonly UNDELETABLE_NODE_TYPES = [
    'obf'
  ];

  generateLocations(config, data, policyID, lob) {
    const locations = [];
    data.locations.forEach((location) => {
      let locationStruct = this.appendLocation(location, config, policyID, lob);
      for (const list of config.lists) {
        const { listName } = list;
        this.appendToLocation(location, locationStruct, config, policyID, listName);
      }
      locations.push(locationStruct);
    })
    return locations;
  }

  appendLocation(location, config, policyID, lob): LocationTabs {
    const locationStruct: any = {
      children: [],
      state: { state: location.state },
      name: location.$name,
      type: 'locationParent',
      id: location.id
    }

    locationStruct.children.push(this._generateRiskAddressForm(location.id, policyID, lob, location.riskAddress.id));
    for (const list of config.lists) {
      const { button } = list;
      locationStruct.children.push(this._createButton(button, location.id));
    }
    return locationStruct;
  }

  appendToLocation(location, struct, config, policyID, listName) {
    if (!location[listName] || location[listName].length === 0) {
      return;
    }
    const listConfig = config.lists.find(list => list.listName == listName);
    let insertionIndex = this.getIndexByLabel(listConfig.button, struct) + 1;
    const risksConfig = config.lists.find(list => list.listName == listName).subTabs;
    const riskNodes = this.createLocationRisk(risksConfig, location, policyID, listName, location.id);
    riskNodes.forEach(risk => {
      if (this.checkIdExists(struct, risk.id)) {
        return;
      }
      struct.children.splice(insertionIndex, 0, risk);
      insertionIndex++;
    });
    if (listConfig.lists != null) {
      for (let listItem of location[listName]) {
        for (let subList of listConfig.lists) {
          this.appendToList(listItem, struct, subList, policyID, listConfig);
        }
      }
    }
    return struct;
  }

  appendToList(parentLocationItem, struct, listConfig, policyID, parentListConfig) {
    const listName = listConfig.listName;
    let insertionIndex = this.getIndexByLabel(parentListConfig.button, struct) + parentListConfig.subTabs.length + 1;
    const riskNodes = this.createLocationRisk(listConfig.subTabs, parentLocationItem, policyID, listName, struct.id);
    riskNodes.forEach(risk => {
      if (this.checkIdExists(struct, risk.id)) {
        return;
      }
      struct.children.splice(insertionIndex, 0, risk);
      insertionIndex++;
    });
    return struct;
  }

  createLocationRisk(subtabs, location, policyID, tabKey, locationId) {
    const riskNodes: LocationTabs[] = [];
    const items = location[tabKey];
    for (const item of items) {
      let children: LocationTabs[] = [];
      for (const subtab of subtabs) {
        children.push(this._createNode(item[subtab.formName], subtab, locationId, policyID));
      }

      let node: LocationTabs = {
        name: item.$name,
        id: item.id,
        children,
        type: tabKey,
        state: { state: item.state }
      }

      if (this.UNDELETABLE_NODE_TYPES.includes(node.type)) {
        node.undeletable = true;
      }

      if (children != null && children.length === 1) {
        node.fullRoute = children[0].fullRoute;
        if (node.name == null) {
          node.name = children[0].name;
        }
      }

      // TODO: How would you configure this? It's the parent node that wants to
      //   be marked as like "only show me as the child node here"
      if (node.type === 'obf') {
        node = {
          ...children[0]
        }
      }

      riskNodes.push(node);
    }
    return riskNodes;
  }

  _createButton(name: string, id: string) {
    return {
      type: 'button',
      name,
      id,
    }
  }

  _createNode(state, config, locationNavId, policyId): LocationTabs {
    const route = config.fullRoute
      .replace(/:policyid/ig, policyId)
      .replace(/:formid/ig, state.id)
      .replace(/:locationid/ig, locationNavId);
    const formName = `${config.formName}${state.id}`;

    return {
      name: config.label,
      fullRoute: `${route}`,
      formName,
      id: state.id,
      state
    }
  }

  _createSupplements(supplements, locationId, policyId) {
    const children = [];
    supplements.forEach(supplement => {
      children.push({
        name: supplement.label,
        fullRoute: supplement.fullRoute
          .replace(/locationId/ig, locationId)
          .replace(/:formid/ig, '1')
          .replace(/:policyId/ig, policyId),
        formName: `${supplement.formName}${locationId}-1`
      });
    });
    const ret = {
      name: 'Floaters',
      children,
      id: locationId
    }
    return ret;
  }

  _generateRiskAddressForm(locationId, policyId, lob, formId) {
    const route = `/policy/${policyId}/${lob}/location/${locationId}/risk-address/${formId}`
    return {
      name: 'Risk Address',
      fullRoute: route,
      formName: `riskAddress${formId}`,
      id: formId
    }
  }

  getIndexByLabel(label, struct: LocationTabs) {
    const children = struct.children;
    for (let index = 0; index < children.length; index++) {
      const child = children[index];
      if (child.name == label) return index;
    }
    return -1;
  }

  checkIdExists(struct: LocationTabs, childId: number) {
    for (let child of struct.children) {
      if (child.id == childId) {
        return true;
      }
    }
    return false;
  }

  rebuildTree(currentLocations, locationId: number, newLocationStates: any, policyId: number, lob: string, config: any) {
    const locationIndex = currentLocations.findIndex((location) => {
      return location.id === locationId;
    });

    currentLocations[locationIndex] = this.appendLocation(newLocationStates, config, policyId, lob);
    for (const list of config.lists) {
      const { listName } = list;
      this.appendToLocation(newLocationStates, currentLocations[locationIndex], config, policyId, listName);
    }

    return currentLocations;
  }
}
