import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-billing-header',
  templateUrl: './billing-header.component.html',
  styleUrls: ['./billing-header.component.scss']
})
export class BillingHeaderComponent implements OnInit {
  data;
  boundPolicyId: string;
  headerConfig;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const { config: { billingHeader }, headerData } = this.route.snapshot.data?.billing;
    this.headerConfig = billingHeader
    this.data = headerData
  }
}