import { NestedSubtabFormComponent } from './../../../../../shared/components/nested-subtab-form/nested-subtab-form.component';
import { PolicyDataService } from '../../../../../services/shared/policy-data.service';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-exposure-information',
  templateUrl: './exposure-information.component.html',
  styleUrls: ['./exposure-information.component.scss']
})
export class ExposureInformationComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor() { }

  canDeactivate() {
  }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  ngOnInit() {
  }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
  }

}
