import { ComponentCanDeactivateComponent } from '../../../../../shared/components/component-can-deactivate/component-can-deactivate.component';
import { FormGroup, NgForm } from "@angular/forms";

export abstract class FormCanDeactivate extends ComponentCanDeactivateComponent {

  abstract get form(): FormGroup;

  canDeactivate(): boolean {
    return !this.form.dirty
  }
}