import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatFormFieldModule, MatDatepickerModule, MatInputModule } from "@angular/material";


@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DatepickerComponent
  ]
})
export class SharedModule { }
