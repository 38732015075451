import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PolicyService } from '../policy.service';
import { PolicyDataService } from '../services/shared/policy-data.service';

export interface CanComponentDeactivate {
  saveBeforeDeactivate: () => Promise<any>
  showSaveSnackbar: () => void;
  updateSubtabName?: (data: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class SaveGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(
    private router: Router,
    private PolicyService: PolicyService,
    private PolicyDataService: PolicyDataService
  ) { }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const navigationAction = this.router.getCurrentNavigation();
    const readOnly = this.PolicyDataService.policyReadOnly.getValue()

    if ((navigationAction.extras && navigationAction.extras.state && navigationAction.extras.state.skipSave) ||
      // This second line catches the case where you're on a form but it's been deleted by another action
      (component.formComponent && component.formComponent.formId && this.PolicyService.getForm(Number(component.formComponent.formId)) == null)
      || (readOnly && component.form && (!component.form.dirty || !component.form.touched)) ) {
      return true;
    } else {
      return new Promise((resolve, reject) => {
        component.saveBeforeDeactivate().then(response => {
          if (response != null) {
            component.showSaveSnackbar();
            if (component.updateSubtabName && response.data) component.updateSubtabName(response.data.data);
          }
          resolve(true);
        }).catch(error => {
          console.log(error);
          resolve(true);
        })
      });
    }
  }
}
