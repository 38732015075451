import { PolicyService } from '@modules/policy/policy.service';
import { FormService } from '@modules/policy/services/shared/form.service';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { MatDialog } from '@angular/material';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { ActivatedRoute } from '@angular/router';
import InterestTabs from '@shared/models/interests.model';
import { InterestsService } from './../../../../services/interests/interests.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';
import { of } from 'rxjs';

@Component({
  selector: 'app-interests-form',
  templateUrl: './interests-form.component.html',
  styleUrls: ['./interests-form.component.scss']
})
export class InterestsFormComponent implements OnInit {
  interestForm: FormGroup;
  searchResult: FormGroup;
  allocationForm: FormGroup;
  allocationJson: object | undefined;
  nodeId: string;
  shouldHideClientField: boolean;
  tab: string;
  subtab: string;
  policyId: number;
  cachedValues;
  clientKey;
  options: ClientSearchOptions;
  @ViewChild("formComponent", { static: true }) formComponent: FormComponent;
  constructor(
    private InterestsService: InterestsService,
    private route: ActivatedRoute,
    private PolicyDataService: PolicyDataService,
    public dialog: MatDialog,
    private SaveService: SaveService,
    private FormService: FormService,
    private PolicyService: PolicyService
  ) { }

  ngOnInit() {
    this.tab = 'interest';
    this.subtab = 'interestInformation';
    this.policyId = Number(this.PolicyDataService.getPolicyID());
    this.options = {
      sectionTitle: 'Contact Search',
      objectName: 'contact',
      clientReturnKeys: {
        clientKey: 'InterestContactSearchCd',
        contactReturnKey: 'SearchContactSearchCd',
        addressKey: 'SearchContactAddressPostalTxt',
        nameKey: 'SearchContactNameTxt',
      },
      formFields: {
        formName: 'InterestContactInformation',
        firstNameField: 'InterestFirstNameTxt',
        lastNameField: 'InterestLastNameTxt',
        companyNameField: 'InterestCompanyNameTxt',
        postalCodeField: 'InterestAddressPostalTxt'
      },
      service: this.InterestsService
    }
  }

  saveBeforeDeactivate(): Promise<any> {
    const readOnly = this.PolicyDataService.policyReadOnly.getValue()
    if (!readOnly && this.FormService.doesFormExist(Number(this.nodeId))) {
      const allocationRawValues = this.allocationForm ? this.allocationForm.getRawValue() : {};
      return this.allocationForm && this.allocationForm.dirty ?
        Promise.all([
          this.SaveService.updatePolicy(this.policyId, allocationRawValues, this.nodeId).toPromise(),
          this.formComponent.saveBeforeDeactivate()
        ]) :
        this.formComponent.saveBeforeDeactivate();
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.nodeId = this.route.snapshot.params["formId"];
    this.interestForm = form;
    this.appendFormToDataSource(form);
    this.setCachedFormValues();
    handleValidations(this.formComponent.formjson, this.interestForm);
  }

  initTableForm(form: FormGroup) {
    this.allocationForm = form;
    this.allocationForm.markAsPristine();
  }

  initTableConfig(configJson) {
    this.allocationJson = configJson
  }

  appendFormToDataSource(form: FormGroup) {
    this.InterestsService.dataSourceSubject.pipe(
      filter(data => {
        const node: InterestTabs = data.find(e => e.id === Number(this.nodeId));
        return node && node.formGroup === undefined;
      })
    ).subscribe(data => {
      data.find(e => e.id === Number(this.nodeId))["formGroup"] = form;
      this.InterestsService.updateDataSource(data);
    });
  }

  setCachedFormValues() {
    this.cachedValues = this.PolicyDataService.getValueFromCache(this.nodeId);
    if (this.cachedValues && (this.cachedValues[this.options.clientReturnKeys.clientKey] ||
      this.cachedValues.InterestStatusCd === "C")) {
      this.clientKey = this.cachedValues[this.options.clientReturnKeys.clientKey];
    } else {
      this.clientKey = null;
    }
  }

  updateSubtabName(data) {
    const { InterestDescriptionTxt } = data;
    this.InterestsService.dataSourceSubject.pipe(
      take(1)
    ).subscribe(data => {
      const subtab = data.find(e => e.id === Number(this.nodeId));
      if (subtab) subtab.$name = InterestDescriptionTxt;
      this.InterestsService.updateDataSource(data);
    });
  }

  setForm(form: FormGroup, subtab: string, tab: string, nodeID: number) {
    this.FormService.setForm({
      form,
      orderedFormJson: [],
      formid: nodeID
    });
  }

  setClientKey(newClientKey: string) {
    this.clientKey = newClientKey;
  }

  save() {
    this.saveBeforeDeactivate().then(res => {
      if (res) this.showSaveSnackbar();
    })
  }
}
