import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { handleValidations } from "../../rules/handle-validations.rules";

@Component({
  selector: 'dialog-form',
  templateUrl: 'dialog-form.component.html',
  styleUrls: ['dialog-form.component.scss']
})
export class DialogForm {
  private form: FormGroup;
  private formJson: any;
  private statusChangeSubscription;
  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<DialogForm>, private changeDetector: ChangeDetectorRef) {
    this.form = data.form;
    this.formJson = data.formJson;
    handleValidations(this.formJson, this.form);
    this.handleValidations()
    this.statusChangeSubscription = this.form.statusChanges.subscribe(res => {
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }

  handleValidations() {
    for (let form of this.formJson) {
      for (let field of form.parameters) {
        if (field["modal-only"]) field["modal-only"] = false;
      }
    }
  }

}
