import { Component, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { BasicDialogComponent } from './../../../shared/components/basic-dialog/basic-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DialogSearchComponent } from './../../../shared/components/dialog-search/dialog-search.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { ClientService } from './../../../services/client/client.service';
import { Observable, of } from 'rxjs';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.scss']
})
export class ClientSearchComponent {
  @Output() clientKeySelection = new EventEmitter<string>();
  @Input() clientForm: FormGroup;
  @Input() tabType: FormGroup;
  @Input() subtabType: FormGroup;
  @Input() nodeId: FormGroup;
  @Input() options: ClientSearchOptions;
  @Input() formComponent: FormComponent;
  @Input() clientKey: string;
  @Input() policyId: number;

  private tab: string;
  private subtab: string;
  private searchResult: FormGroup;

  readonly BASE_OPTIONS: ClientSearchOptions = {
    sectionTitle: 'Policy Holder Search',
    objectName: 'client',
    clientReturnKeys: {
      clientKey: 'ClientPASClientId',
      contactReturnKey: 'ClientPASClientId',
      addressKey: 'ClientAddressPostalTxt',
      nameKey: 'ClientClientSearchCd',
    },
    formFields: {
      formName: 'ClientContactInformation',
      firstNameField: 'ClientFirstNameTxt',
      lastNameField: 'ClientLastNameTxt',
      companyNameField: 'ClientCompanyNameTxt',
      postalCodeField: 'ClientAddressPostalTxt'
    },
    service: this.ClientService
  }

  readonly TABS = {
    CLIENT: 'client',
    INTEREST: 'interest',
    POLICY: 'policy'
  }

  readonly EXCLUDED_CLIENT_SEARCH_FIELDS: { [s: string]: string[] } = {
    'client': ['searchResult'],
    'interest': ['searchResult'],
    'policy': ['searchResult']
  }

  constructor(
    public dialog: MatDialog,
    private ClientService: ClientService,
    private SaveService: SaveService,
    private snackbar: MatSnackBar,
    public policyDataService: PolicyDataService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabType && changes.subtabType) {
      this.initForm(changes.tabType.currentValue, changes.subtabType.currentValue);
    } else if (changes.clientForm) {
      this.initForm(this.tab, this.subtab);
    }
  }

  initForm(tab, subtab) {
    this.tab = tab;
    this.subtab = subtab;
    this.options = Object.assign({}, this.BASE_OPTIONS, this.options);
    const contactForm = this.getContactInfoForm();
    if (contactForm != null) {
      this.searchResult = contactForm.get('searchResult') as FormGroup;
      if (this.searchResult == null) {
        this.searchResult = new FormGroup({
          client: new FormControl('', [Validators.required])
        });
        contactForm.addControl('searchResult', this.searchResult);
      }
    }
    if (this.clientKey != null) {
      this.disableContactInfoFields();
      this.displayClientSearchResult();
    }
  }

  searchDisabled(): boolean {
    const contactInfoForm = this.getContactInfoForm();
    if (!this.clientForm || this.clientKey != null || !contactInfoForm) {
      return true;
    }

    const excludedClientInfoFields = this.EXCLUDED_CLIENT_SEARCH_FIELDS[this.tab] || [];

    for (let field in contactInfoForm.controls) {
      if (!excludedClientInfoFields.includes(field)) {
        const formField = contactInfoForm.get(field);
        if (formField.status !== 'VALID' && formField.status !== 'DISABLED') {
          return true;
        }
      }
    }
    return false;
  }

  onSearch(): void {
    if (this.searchDisabled()) return;
    this.fetchClients().subscribe(clients => {
      const { Error } = clients;
      if (Error) this.openErrorDialog(Error);
      else this.openSearchModal(clients);
    });
  }

  disableContactInfoFields(): void {
    const contactInfoForm = this.getContactInfoForm();
    const excludedClientInfoFields = this.EXCLUDED_CLIENT_SEARCH_FIELDS[this.tab];

    for (let formConfig of this.formComponent.formjson) {
      for (let fieldConfig of formConfig.parameters) {
        if (!excludedClientInfoFields.includes(fieldConfig.name)) {
          fieldConfig.disabling = null;
        }
      }
    }

    for (let field in contactInfoForm.controls) {
      if (!excludedClientInfoFields.includes(field)) {
        contactInfoForm.get(field).disable();
      }
    }
  }

  getContactInfoForm(): FormGroup {
    if (!this.clientForm) {
      return null;
    }
    return this.clientForm.controls[this.options.formFields.formName] as FormGroup;
  }

  getContactInfoFormRawValue(): { [s: string]: any } {
    const res = {};
    const clientFormRawValues = this.getContactInfoForm().getRawValue();
    const excludedClientSearchFields = this.EXCLUDED_CLIENT_SEARCH_FIELDS[this.tab] || [];

    for (let field in clientFormRawValues) {
      if (!excludedClientSearchFields.includes(field)) {
        res[field] = clientFormRawValues[field];
      }
    }
    return res;
  }

  displayClientSearchResult() {
    const clientField = this.searchResult.get('client');
    clientField.disable();
    let clientValue = '';

    const formRawValues = this.getContactInfoFormRawValue();
    const firstName: string = formRawValues[this.options.formFields.firstNameField];
    const lastName: string = formRawValues[this.options.formFields.lastNameField];
    const companyName: string = formRawValues[this.options.formFields.companyNameField];
    const postalTxt: string = formRawValues[this.options.formFields.postalCodeField];

    if (companyName) {
      clientValue += `${companyName} - ${postalTxt}`;
    } else {
      clientValue += `${firstName} ${lastName} - ${postalTxt}`;
    }

    clientField.setValue(clientValue);
  }

  fetchClients(): Observable<any> {
    const formRawValues = this.getContactInfoFormRawValue();
    const firstName: string = formRawValues[this.options.formFields.firstNameField];
    const lastName: string = formRawValues[this.options.formFields.lastNameField];
    const companyName: string = formRawValues[this.options.formFields.companyNameField];
    const postalTxt: string = formRawValues[this.options.formFields.postalCodeField];

    if (companyName && postalTxt) {
      return this.options.service.getClientsByZip(companyName, postalTxt, this.policyId);
    } else if (firstName && lastName && postalTxt) {
      return this.options.service.getClientsByName(firstName, lastName, postalTxt, this.policyId);
    } else {
      return of([]);
    }
  }

  openSearchModal(clients) {
    this.formComponent.saveBeforeDeactivate();
    const data = {
      clients,
      title: this.options.sectionTitle,
      contactKey: this.options.clientReturnKeys.contactReturnKey,
      addressKey: this.options.clientReturnKeys.addressKey,
      nameKey: this.options.clientReturnKeys.nameKey,
      objectName: this.options.objectName
    }
    this.dialog.open(DialogSearchComponent, {
      data,
      width: '60%',
      maxWidth: '600px',
      minWidth: '480px'
    }).afterClosed().subscribe(formValues => {
      if (formValues == null || Object.keys(formValues).length === 0) return;
      const { id } = formValues;
      let body: { [s: string]: any } = {};
      body[this.options.clientReturnKeys.clientKey] = id;
      if (id === '-1') {
        body = Object.assign(body, this.getContactInfoFormRawValue());
      } else {
        const formRawValues = this.getContactInfoFormRawValue();
        if (formRawValues.ClientConsentInd != null) {
          body.ClientConsentInd = formRawValues.ClientConsentInd;
        }
      }
      this.SaveService.updatePolicy(this.policyId, body, this.nodeId).subscribe(
        (result) => {
          const { Error } = result;
          if (Error) {
            this.openErrorDialog(Error);
          } else {
            this.snackbar.open('Success', null, {
              duration: 2000,
            });
            this.disableContactInfoFields();
            this.displayClientSearchResult();
            this.clientKeySelection.emit(result.data[this.options.clientReturnKeys.clientKey]);
          }
        });
    });
  }

  openErrorDialog(error) {
    this.dialog.open(BasicDialogComponent, {
      data: {
        message: error.Message,
        details: error.Details,
        onlyConfirm: true,
        title: 'Error',
        showDetails: true,
        dialogPrefix: 'client-search-error-modal'
      },
      width: '80%',
      maxWidth: '600px'
    });
  }
}
