import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MetadataService } from '@core/services/metadata.service';
import { SpinnerService } from '@core/services/spinner.service';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterContentChecked {
    tabs: any[];
    showSpinner: Observable<boolean> = of(true);

    constructor(
        private dashboardService: DashboardService, 
        private SpinnerService: SpinnerService,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        this.showSpinner = this.SpinnerService.visibility.asObservable();
    }

    ngAfterContentChecked(): void {
        if (!this.tabs) {
            this.tabs = this.dashboardService.handleTabs();
        }
        this.changeDetector.detectChanges();
    }
}
