import { PolicyService } from '@modules/policy/policy.service';
import { PolicyState } from '@shared/models/state.model';
import { JsonFormFetcherService } from './../../../services/shared/json-form-fetcher.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { zip, Subscription } from 'rxjs';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  form: FormGroup;
  policyId: string;
  tab: string;
  tabState: any;
  subTabList: any[];
  readOnlyPolicy: boolean;
  initSubscription: Subscription;

  constructor(
    private PolicyDataService: PolicyDataService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private policyService: PolicyService
  ) { }

  ngOnInit() {
    this.tab = "client";
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.configSubject;
    const policyReadOnlySubject = this.PolicyDataService.policyReadOnly
    this.policyId = this.PolicyDataService.getPolicyID();
    this.initSubscription = zip(stateSubject, configSubject, policyReadOnlySubject).subscribe(res => {
      const [states, config, readOnly] = res
      this.readOnlyPolicy = readOnly
      this.loadTabs(config["tabs"], states);
    })
  }

  loadTabs(tabs: any, states: PolicyState) {
    this.tabState = states.client;
    const tabConfig = tabs.find((tab: { tabKey: string; }) => tab.tabKey == this.tab);
    this.subTabList = tabConfig.subTabs;
    this.modifySubtabs(this.policyId);
  }

  modifySubtabs(policyId: string) {
    this.subTabList = this.subTabList.map(subtab => {
      return {
        ...subtab,
        formId: this.tabState[subtab.formName].id,
        fullRoute: subtab.fullRoute.replace(':policyId', policyId),
      }
    });
  }

  isFormValid(formId: number) {
    let result: boolean = false;
    if (this.policyService.getForm(formId)) {
      result = this.policyService.getForm(formId).status !== 'INVALID';
    }
    return result;
  }

  ngOnDestroy() {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
  }
}
