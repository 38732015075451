import { FormGroup } from '@angular/forms';
import { PolicyService } from './../../policy.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  private policyTabs = [];
  readOnlyPolicy: BehaviorSubject<boolean>;
  @Input() tabs;
  @Input() states;

  constructor(
    private route: ActivatedRoute,
    private PolicyService: PolicyService,
    private PolicyDataService: PolicyDataService
  ) { }

  ngOnInit() {
    this.policyTabs = this.tabs["tabs"]
    const policyID = this.PolicyDataService.getPolicyID();
    this._modifyPolicyTabRoutes(policyID);
    this.readOnlyPolicy = this.PolicyDataService.policyReadOnly
  }

  ngAfterViewInit() {
    const tabForms = this.PolicyService.getTabForms();
    this.policyTabs.forEach(tab => {
      if (this.states[tab.formName].state === false) {
        tabForms[tab.formName].setErrors({ invalid: true });
      }
    });
  }

  _modifyPolicyTabRoutes(id: string) {
    const tabForms = this.PolicyService.getTabForms();
    this.policyTabs = this.policyTabs.filter(tab => tab.tabKey != null).map(pol => {
      const currentForm: FormGroup = tabForms[pol.formName];
      const statusChanges = currentForm.statusChanges;
      return {
        ...pol,
        fullRoute: pol.fullRoute.replace(":policyId", id),
        isValid: statusChanges
      }
    });
  }
}
