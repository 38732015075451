import { ClientResults } from './../../../../../shared/models/client.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';

@Component({
  selector: 'app-dialog-search',
  templateUrl: './dialog-search.component.html'
})
export class DialogSearchComponent implements OnInit {
  form: FormGroup;
  searchResults: any;
  confirmMessage: string;
  confirmed: boolean;
  emittedClient: any;
  title: string;
  data;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.confirmed = false;
    this.emittedClient = {};
    this.confirmMessage = "Are you sure? Value cannot be changed once confirmed";
    if (!Array.isArray(data.clients)) data.clients = [];
    this.title = data.title;
    this.data = data;
    ADD_NEW_CLIENT[data.contactKey] = "-1";
    ADD_NEW_CLIENT[data.addressKey] = "";
    ADD_NEW_CLIENT[data.nameKey] = `- Create new ${data.objectName} with details in the contact information form`;
    this.searchResults = [...data.clients, ADD_NEW_CLIENT];
  }

  ngOnInit() {
    this.form = new FormGroup({
      clientResults: new FormControl('', [Validators.required]),
      confirmCheckbox: new FormControl('', [Validators.required])
    })
  }

  onCheck() {
    this.confirmed = !this.confirmed;
  }

  setClient(client: ClientResults) {
    this.emittedClient = {
      client: `${client[this.data.nameKey]} - ${client[this.data.addressKey]}`,
      id: this.form.get('clientResults').value
    };
  }

  getPlaceholderText(objectName: string): string {
    return objectName.charAt(0).toUpperCase() + objectName.slice(1);
  }
}

const ADD_NEW_CLIENT: ClientResults = {};
