import { AbstractControl } from "@angular/forms";

export const validateMainRevenueNoRevenue = (value: string, farmRevenue: AbstractControl, allowDisabling: boolean) => {
  const totalRevenueCtrl = farmRevenue.get('PolicyTotalRevenueAmt');
  if (allowDisabling) totalRevenueCtrl.enable();
  if (value == "NO") {
    farmRevenue.patchValue({
      PolicyTotalRevenueAmt: 0
    })
    if (allowDisabling) totalRevenueCtrl.disable();
  }
}

export const validateTotalRevenue = (value: any, farmRevenue: AbstractControl) => {
  const mainRevenue = farmRevenue.get('PolicyMainRevenueCd');
  const totalRevenueCtrl = farmRevenue.get('PolicyTotalRevenueAmt');
  if (mainRevenue.value == "HF") {
    if (value > 10000) {
      totalRevenueCtrl.setErrors( {message: "Total revenue cannot be more than $10,000 for a hobby farm."} )
    }
  }
  totalRevenueCtrl.markAsTouched();
}