import { Component, OnInit, ViewChild, EventEmitter, Input, Output } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { BillingService } from '../../services/billing.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class StepperComponent implements OnInit {
  billingLookups;
  header;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  constructor(
    private billingService: BillingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const { lookups, headerData } = this.route.snapshot.data?.billing;
    this.header = headerData;
    this.billingLookups = lookups;
  }

  ngAfterViewInit(): void {
    this.updateStep();
  }

  updateStep() {
    if (this.billingService.billingData.BillingStatusCd === 'CV') {
      this.stepper.next();
      this.stepper.next();
    } else if (this.isBillingComplete()) {
      this.stepper.next();
    }
  }

  isBillingComplete() {
    const { billingData, PAYMENT_TYPES } = this.billingService
    if (billingData == null) return false
    
    return Boolean(
      billingData.BillingPaymentTypeCd && billingData.BillingPaymentFrequencyCd && billingData.BillingDownpaymentAmt >= 0
      && (
        billingData.BillingWithdrawalDateDt 
        || (!billingData.BillingWithdrawalDateDt && billingData.BillingPaymentTypeCd == PAYMENT_TYPES.invoice)
      ) 
    )
  }

  isPaymentComplete() {
    if (this.billingService.billingData != null) {
      return this.billingService.billingData.BillingCreditCardAuthorizationInd == true &&
        this.billingService.billingData.BillingPaymentConfirmationInd == true;
    } else {
      return false;
    }
  }

  billingStep() {
    this.stepper.previous();
  }

  paymentStep(event) {
    this.billingService.saveBillingData(event, this.billingService.billingData.BillingPolicyID).subscribe(
      (_: any) => {
        this.billingService.calculateResourceUrls(true);
        this.stepper.next();
      });
  }

  confirmStep() {
    this.billingService.bindPolicy(this.billingService.billingData.BillingPolicyID).subscribe(
      (_: any) => {
        this.stepper.next();
      });
  }

  saveBillingData(event: { [s: string]: any }) {
    this.billingService.saveBillingData(event, this.billingService.billingData.BillingPolicyID).subscribe();
  }
}
