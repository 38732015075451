import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Error } from '@shared/models/error.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  errorPayload: Error;
  moreDetails: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) data: Error) {
    this.errorPayload = data;
  }

  ngOnInit() { }

  toggleDetails() {
    this.moreDetails = this.moreDetails ? false : true;
  }
}
