import { PolicyService } from '@modules/policy/policy.service';
import { Router } from '@angular/router';
import { InterestsService } from './../../../services/interests/interests.service';
import { ClaimsService } from './../../../services/claims/claims.service';
import { PolicyState } from './../../../../../shared/models/state.model';
import { JsonFormFetcherService } from './../../../services/shared/json-form-fetcher.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { first, skip } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { serviceMap } from './serviceConfig';
import { PolicyholderService } from '@modules/policy/services/policy/policyholder.service';

import InterestTabs from '@shared/models/interests.model';
import ClaimTabs from '@shared/models/claims.model';
import PolicyholderTabs from '@shared/models/policyholder.model';

@Component({
  selector: 'app-mat-tree',
  templateUrl: './mat-tree.component.html',
  styleUrls: ['./mat-tree.component.scss']
})
export class MatTreeComponent implements OnInit {
  @Input() type: string;
  @Input() listName: string;
  @Input() configList: Array<any>;
  @Input() treeWidth: string = '240px';

  treeControl = new NestedTreeControl<ClaimTabs | InterestTabs>(node => node.children);
  dataSource = new MatTreeNestedDataSource<ClaimTabs | InterestTabs | PolicyholderTabs>();
  @ViewChild('tree', { static: false }) tree;

  policyId: string;
  tabState: any;
  listConfig: any;
  addLabel: string;
  nodeId: number;
  service: ClaimsService | InterestsService | PolicyholderService;
  listSubTabFormName: string;
  treeHeight: string | null;
  policyReadOnly: BehaviorSubject<boolean>;

  constructor(
    private PolicyDataService: PolicyDataService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private injector: Injector,
    private router: Router,
    private PolicyService: PolicyService
  ) { }

  ngOnInit() {
    this.policyReadOnly = this.PolicyDataService.policyReadOnly
    this.treeHeight = this.getDynamicHeight()
    this.policyId = this.PolicyDataService.getPolicyID();
    if (serviceMap[this.type]) {
      this.service = this.injector.get<any>(serviceMap[this.type]);
    } else {
      throw new Error('Service not found');
    }
    this.service.dataSourceSubject.subscribe(data => {
      this.dataSource.data = data;
    });
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.configSubject;
    combineLatest([stateSubject, configSubject]).pipe(first()).subscribe(res => {
      const [states, config] = res;
      this.loadTabs(config.tabs, states);
    });
  }

  loadTabs(config, states: PolicyState) {
    this.tabState = states[this.type];
    const tabConfig = config.find(curTab => curTab.tabKey === this.type);
    this.listConfig = tabConfig.lists.find(x => x.listName === this.listName);
    this.addLabel = this.listConfig.button;
    this.nodeId = states[this.type].id;
    this.listSubTabFormName = this.listConfig.subTabs[0].formName;
    this.PolicyDataService.getData(this.policyId, this.nodeId).subscribe(res => {
      this.PolicyDataService.updateUnderWritingRules(res.opinions);
      const allListItems = [];
      res.navigation[this.listName].forEach(parentListItem => {
        const dataItem = this.transformItem(parentListItem);
        allListItems.push(dataItem);
      });
      this.service.updateDataSource(allListItems);
    });
  }

  addItem() {
    const data: (ClaimTabs | InterestTabs | PolicyholderTabs)[] = this.dataSource.data;
    this.service.add(this.policyId, this.nodeId).subscribe((res) => {
      const newItem = this.transformItem(res.navigation);
      this.PolicyService.addControlsFromStateTree(res.navigation, this.tabState.id);
      this.service.updateDataSource([...data, newItem]);
      this.router.navigateByUrl(newItem.fullRoute);
    });
  }

  deleteItem(node: any) {
    let { parentId } = node;
    this.service.delete(this.policyId, this.nodeId, parentId).subscribe(res => {
      const newDataSource = res.navigation[this.listName].map((item: ClaimTabs | InterestTabs | PolicyholderTabs) => {
        return this.transformItem(item);
      });
      this.service.updateDataSource(newDataSource);
      this.PolicyService.removeControlAndChildren(node.parentId, [node.id]);
      const lob = this.PolicyDataService.getLOB();
      this.router.navigateByUrl(`policy/${this.policyId}/${lob}/${this.type}`);
    });
  }

  transformItem(item: any) {
    let newItem = item[this.listSubTabFormName];
    newItem.parentId = item.id;
    newItem.$name = newItem.$name != null ? newItem.$name : item.$name;
    const route: string = this.listConfig.subTabs.find(subTab => subTab.formName === this.listSubTabFormName).fullRoute;
    newItem.fullRoute = route.replace(':policyId', this.policyId).replace(':formId', String(newItem.id));

    return newItem;
  }

  isFormValid(node) {
    const form = this.PolicyService.getForm(node.id);
    return (form != null) ? form.valid : node.state;
  }

  getDynamicHeight() {
    //dynamically sets the tree's height based on how many subtab items are passed from the parent component
    const remainingScreenHeight = 208.5;
    const subtabItemHeight = 45;
    return this.configList ? `calc(100vh - ${remainingScreenHeight + (subtabItemHeight * this.configList.length)}px)` : null;
  }

  ngOnDestroy() {
    this.service.updateDataSource([]);
  }
}
