import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { Observable, forkJoin } from "rxjs";
import { map } from 'rxjs/operators';
import { PolicyDataService } from '../services/shared/policy-data.service';
import { BillingService } from '@modules/billing/services/billing.service';

@Injectable({ providedIn: 'root' })
export class BillingResolver implements Resolve<any> {
  constructor(
    private PolicyService: PolicyCreationService,
    private policyDataService: PolicyDataService,
    private billingService: BillingService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const { id: policyId } = route.params;
    
    const headerData = this.policyDataService.getHeaderData(policyId)
    const billingData = this.billingService.getBillingData(policyId)
    const lookups = this.billingService.getBillingLookups(policyId)
    const editPolicy = this.PolicyService.editPolicy(policyId)

    return forkJoin({ headerData, billingData, lookups, editPolicy }).pipe(
      map((res) => ({
          ...res, config: res.editPolicy.config
        })
      )
    )
  }
}