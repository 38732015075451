import { AbstractControl } from "@angular/forms";
import { validatePostalCode, isValueWholeNumber, validateYearFormat, validateDateFormat, isDateInThePast, validatePhoneNumber, validateEmailAddress, validateRiskUnitsNumber } from "collabor8-funcs";

export const isValueWholeNumberWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return isValueWholeNumber(control.value);
}

export const validateYearFormatWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validateYearFormat(control.value);
}

export const validatePostalCodeWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validatePostalCode(control.value);
}

export const validateDateFormatWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validateDateFormat(control.value);
}

export const isDateInThePastWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return isDateInThePast(control.value);
}

export const validatePhoneNumberWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validatePhoneNumber(control.value);
}

export const validateEmailAddressWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validateEmailAddress(control.value);
}

export const validateRiskUnitsNumberWrapper = (control: AbstractControl): { [key: string]: any } | null => {
  return validateRiskUnitsNumber(control.value);
}