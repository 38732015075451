import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { LocationService } from '@modules/policy/services/location/location.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';

@Component({
  selector: 'app-building-details',
  templateUrl: './building-details.component.html',
  styleUrls: ['./building-details.component.scss']
})
export class BuildingDetailsComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor(
    private LocationService: LocationService
  ) { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
  }
  
}
