import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private _billingData: { [s: string]: any } = {};
  private _downpaymentsUrl: SafeResourceUrl;
  private _preauthUrl: SafeResourceUrl;
  private _stepperSate: Subject<StepperSelectionEvent> = new Subject();

  public readonly PAYMENT_TYPES = {
    invoice: 2,
    pre_auth: 3
  }
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`,
    testUrl: `${environment.PROTOCOL}://${environment.API_URL}:3088/api/v1/c8`
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private __sanitizer: DomSanitizer
  ) {
    this.billingData = this.route.snapshot.data?.billing;
  }

  get stepperState() {
    return this._stepperSate.asObservable()
  }

  get billingData(): any {
    return this._billingData;
  }

  set billingData(data: any) {
    this._billingData = data;
    this.calculateResourceUrls(false);
  }

  get resourceUrls(): { downpaymentsUrl: SafeResourceUrl, preauthUrl: SafeResourceUrl } {
    return {
      downpaymentsUrl: this._downpaymentsUrl,
      preauthUrl: this._preauthUrl
    }
  }

  getBillingData(quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/billing`, { params: { quoteId } }).pipe(
      map(fetchedBillingData => {
        this.billingData = fetchedBillingData;
        return fetchedBillingData;
      })
    );
  }

  saveBillingData(body, quoteId) {
    return this.http.put<any>(`${this.config.apiUrl}/billing`, body, { params: { quoteId } }).pipe(
      map(newBillingData => {
        this.billingData = newBillingData;
        return newBillingData;
      })
    );
  }

  updateDownpayment(body, quoteId) {
    return this.http.post<any>(`${this.config.testUrl}/downpayment-status`, body, { params: { quoteId } });
  }

  getDownpayment(quoteId) {
    return this.http.get<any>(`${this.config.testUrl}/downpayment-status`, { params: { quoteId } });
  }

  getBillingLookups(quoteId) {
    var params = ["PaymentFrequencyPreauth", "PaymentFrequencyInvoice", "PaymentType", "WithdrawalDate"];
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/lookup`, { params: { quoteId, lookupName: params } });
  }

  bindPolicy(quoteId) {
    return this.http.post<any>(`${this.config.apiUrl}/workflow/bind`, {}, { params: { quoteId } }).pipe(
      map(bindResult => {
        this.billingData = Object.assign(this.billingData, bindResult);
        return bindResult;
      }),
      tap(() => this.getBillingData(quoteId).subscribe())
    );
  }

  calculateResourceUrls(forceRefresh: boolean): void {
    if (forceRefresh || (this._downpaymentsUrl == null && this.billingData?.BillingDownpaymentAmt != null)) {
      this._downpaymentsUrl = this.__sanitizer.bypassSecurityTrustResourceUrl(
        `${environment.BAMBORA_BASE_URL}?policyId=${this.billingData?.BillingPolicyID}` +
        `&amount=${this.billingData?.BillingDownpaymentAmt}`
      );
    }

    if (forceRefresh || this._preauthUrl == null) {
      this._preauthUrl = this.__sanitizer.bypassSecurityTrustResourceUrl(
        environment.PREAUTH_FORM_URL
      );
    }
  }

  handleStepChange(event: StepperSelectionEvent) {
    this._stepperSate.next(event)
  }
}
