import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private drawer: MatDrawer;
  private filterEvents = new Subject<any>();
  private quickFilter = new BehaviorSubject<string | null>(null);
  private appliedFiltersState = new BehaviorSubject<object>({});

  constructor() { }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  newEvent(event) {
    this.filterEvents.next(event);
    if (event.quickFilter) {
      this.updateQuickFilter(event.quickFilter)
    } else if (event.clear || !event.searchText) {
      this.updateQuickFilter(null)
    }
  }

  updateQuickFilter(quickFilter: string | null) {
    this.quickFilter.next(quickFilter)
  }

  getQuickFilter() {
    return this.quickFilter.asObservable();
  }

  updateAppliedFiltersState(appliedFilters) {
    this.appliedFiltersState.next(appliedFilters);
  }

  get lastAppliedFilters() {
    return this.appliedFiltersState.getValue();
  }

  get currentQuickFilter() {
    return this.quickFilter.getValue();
  }

  get events() {
    return this.filterEvents.asObservable();
  }

  get matDrawer() {
    return this.drawer
  }
}