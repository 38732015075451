import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BillingService } from '@modules/billing/services/billing.service';
import { FormService } from '@modules/policy/services/shared/form.service';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { FieldLookupService } from 'projects/form-tab/src/lib/services/lookup.service';

@Component({
  selector: 'app-billing-step',
  templateUrl: './billing-step.component.html',
  styleUrls: ['./billing-step.component.scss']
})
export class BillingStepComponent {
  form: FormGroup;
  formJson: any = {};

  constructor(
    private FormHelper: FormService,
    private route: ActivatedRoute,
    private billingService: BillingService,
    private fieldLookupService: FieldLookupService
  ) { }

  get controls() { 
    return Object.values(this.form.controls)[0]; 
  }

  @Output() paymentStep = new EventEmitter<any>();
  @Output() confirmStep = new EventEmitter<string>();

  ngOnInit(): void {
    this.handleForms(this.billingService.billingData);
  }

  paymentsStep(): void {
    const [formVals] = Object.values(this.form.value)
    this.paymentStep.emit(formVals);
  }

  handleDependantFields() {
    const { lookups } = this.route.snapshot.data?.billing;
    const { PAYMENT_TYPES, billingData } = this.billingService
    const paymentTypeControl = this.controls.get('BillingPaymentTypeCd')
    const downpaymentControl = this.controls.get('BillingDownpaymentAmt')

    paymentTypeControl.valueChanges.subscribe(value => {
      const updateFieldName = 'BillingPaymentFrequencyCd'
      const lookupValues = value === PAYMENT_TYPES.pre_auth ? lookups['PaymentFrequencyPreauth'] : lookups['PaymentFrequencyInvoice']
      this.fieldLookupService.updateSelectValues(updateFieldName, lookupValues)
    })

    downpaymentControl.valueChanges.subscribe(value => {
      if (!value) return
      if (Number(value) > billingData.BillingPremiumAmt) {
        const premium = billingData.BillingPremiumAmt.toLocaleString()
        downpaymentControl.setErrors({ 
          message: `Downpayment cannot exceed policy premium of ${premium}` 
        }) 
      }
    })
  }

  handleForms(billingData) {
    const { id: policyID } = this.route.snapshot.params;
    this.FormHelper.initBillingForm({ 
      policyID,
      formName: 'payment-method', 
      formData: billingData
    }).subscribe((result: any) => {
      this.form = result.form;
      this.formJson = result.orderedFormJson;
      handleValidations(this.formJson, this.form);
      this.handleDependantFields();
    }, (err: any) => console.error(err));
  }
}