import { PolicyDataService } from './../../../../../services/shared/policy-data.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { PolicyService } from '@modules/policy/policy.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-farm-activities',
  templateUrl: './farm-activities.component.html',
  styleUrls: ['./farm-activities.component.scss']
})
export class FarmActivitiesComponent implements OnInit {
  form: FormGroup;
  policyID: string;
  nodeID: number;
  subtab: string = "farmActivitiesInformation";
  initSubscription: Subscription;
  @ViewChild("formComponent", { static: true }) formComponent: FormComponent;

  constructor(
    private PolicyService: PolicyService,
    private PolicyDataService: PolicyDataService
  ) { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.policyID = this.PolicyDataService.getPolicyID();
    this.initSubscription = this.PolicyDataService.stateSubject.subscribe(states => {
      this.nodeID = states.policy.farmActivitiesInformation.id;
    });
  }

  initForm(form: FormGroup) {
    this.form = form;
    handleValidations(this.formComponent.formjson, this.form);
  }

  ngOnDestroy() {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
  }
}
