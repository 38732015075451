import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { RouteGuard } from '@hubio/hubio-login';
import { PoliciesInForceComponent } from './pages/policies-in-force/policies-in-force.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'policies',
    pathMatch: 'full'
  },
  {
    path: 'policies',
    component: SearchResultsComponent
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
    canActivate: [RouteGuard],
    data: {
      allowedGroups: [
        'c8-role-admin'
      ]
    }
  },
  {
    path: 'policies-in-force',
    component: PoliciesInForceComponent,
    canActivate: [RouteGuard],
    data: {
      allowedGroups: [
        'c8-role-pi-user'
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
