
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { AnalyticsService } from '@modules/dashboard/services/analytics.service';
import { isObservable, Observable } from 'rxjs';
import { map, startWith, } from 'rxjs/operators';

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent implements OnInit {
    @Input() field: any;
    control: AbstractControl;
    options: Array<any> = [];
    filteredOptions: Observable<any>;

    @Output() change = new EventEmitter();

    constructor(public analyticsService: AnalyticsService) {
        this.displayFn = this.displayFn.bind(this);
    }

    ngOnInit() {
        this.control = this.field.abstractControl;
        this.setOptions();
        this.filteredOptions = this.control.valueChanges.pipe(
            startWith(''),
            map(value => {
                return typeof value === 'string' ? this.filterOptions(value) : this.options.slice()
            })
        );
    }

    setOptions() {
        if (this.field?.values && isObservable(this.field.values)) {
            this.field.values.subscribe(values => {
                this.options = values
                this.control.setValidators(this.validateInputString.bind(this))
            });
        } else {
            this.options = this.field.values;
        }
    }

    displayFn(valueSelected) {
        if (!valueSelected) return;

        if (valueSelected == this.analyticsService.SELECT_ALL) return valueSelected;

        return this.options.find(({ selected, key }) => selected || valueSelected == key)?.value;
    }

    filterOptions(value: string) {
        if (this.analyticsService.SELECT_ALL.includes(value)) return this.options;

        const filterValue = value.toLowerCase();
        return this.options.filter(option => {
            return option.value.toLowerCase().includes(filterValue)
        });
    }

    showSelectionPopup() {
        this.control.updateValueAndValidity();
    }

    clearSelection(event: Event) {
        event.stopPropagation();
        this.control.setValue(this.field.defaultValue ?? this.analyticsService.SELECT_ALL);
        this.emitChange();
    }
    
    emitChange() {
        this.change.emit();
    }

    private validateInputString(control: FormControl) {
        if (!control || !this.options) return null;

        const inputMatches = control.value == this.analyticsService.SELECT_ALL
            || this.options.some(({ key }) => key === control.value);

        if (control.value && !inputMatches) {
            return { message: 'Invalid' };
        }
        return null;
    }
}
