import { FilterService } from './../../services/filter.service';
import { Component, Input, OnInit } from '@angular/core';
import { NewPolicyModalComponent } from '../new-policy-modal/new-policy-modal.component'
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { MetadataService } from '@core/services/metadata.service';
import { JsonFormFetcherService } from '@modules/policy/services/shared/json-form-fetcher.service';
import { mergeMap } from 'rxjs/operators';
import { FormTabService } from 'form-tab';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { CustomActionService } from '@core/services/custom-action.service';
import { DynamicDropdownService } from '@modules/policy/services/shared/dynamic.dropdown.service';


@Component({
  selector: 'dashboard-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  private searchField: string = '';
  private filterEventsSub: Subscription
  @Input() brokerages;

  workbenchActionsConfig: any;

  constructor(
    private dialog: MatDialog,
    private FilterService: FilterService,
    private policyService: PolicyCreationService,
    private MetadataService: MetadataService,
    private jsonFormFetcherService: JsonFormFetcherService,
    private formTabService: FormTabService,
    private customActionService: CustomActionService,
    private dropdownService: DynamicDropdownService
  ) { }

  ngOnInit() {
    this.workbenchActionsConfig = this.MetadataService.getWorkbenchActions();
    this.searchField = this.policyService.searchValue
    this.filterEventsSub = this.FilterService.events.subscribe(({ clear }) => {
      if (clear) this.searchField = ""
    });
  }

  toggleFilters() {
    this.FilterService.toggle();
  }

  newPolicyModal(): void {
    this.dialog.open(NewPolicyModalComponent, {
      data: this.brokerages,
      panelClass: 'new-policy-modal-container',
      autoFocus: false
    });
  }

  handleWorkbenchAction(actionConfig) {
    switch (actionConfig.action) {
      case 'createPolicy':
        this.newPolicyModal();
        break;
      default:
        if (actionConfig.modal) {
          this.jsonFormFetcherService.getCommonConfig(actionConfig.modal.formName).pipe(
            mergeMap(async (result) => {
              const configWithDropdowns = await this.dropdownService.appendSelectValuesToConfig(result, null, null).toPromise()
              return this.formTabService.initForm(JSON.stringify(configWithDropdowns), JSON.stringify({})).toPromise();
            })
          ).toPromise().then(res => {
            // =======================================================
            //   Ugly AF. Please remove when CPROD-1214 is complete
            // =======================================================
            if (actionConfig.action === 'policyInquiry') {
              res.form.controls.PolicyInquiry.controls.asOf.value = new Date().toLocaleDateString('fr-CA');
            }
            // =======================================================
            this.dialog.open(DialogForm, {
              data: {
                form: res.form,
                formJson: res.orderedFormJson
              }
            }).afterClosed().subscribe(res => {
              if (!res) return
              const [payload] = Object.values(res.getRawValue());
              this.customActionService.executeWorkbenchAction(payload, actionConfig.endpoint).toPromise()
                .then(res => {
                  this.handleWorkbenchActionResponse(actionConfig, res)
                });
            })
          })
        }
    }
  }

  private handleWorkbenchActionResponse(actionConfig, response) {
    switch (actionConfig.action) {
      case 'policyInquiry':
        const newTab = window.open();
        newTab.document.write(response.data);
        newTab.document.close();
        break
      default:
    }
  }

  applyFilter() {
    if (!this.searchField) return;
    const searchText = this.searchField.trim().toLowerCase();
    this.policyService.searchValue = searchText;
    this.FilterService.newEvent({ searchText: true });
  }

  clearSearch() {
    this.searchField = '';
    this.policyService.searchValue = '';
    this.FilterService.newEvent({ searchText: true });
  }

  hasSearchValue() {
    return this.searchField && this.searchField != "";
  }

  ngOnDestroy() {
    if (this.filterEventsSub) this.filterEventsSub.unsubscribe();
  }
}
