import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { PolicyDocument } from '@shared/models/policy-document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`,
    testUrl: `${environment.PROTOCOL}://${environment.API_URL}:3088/api/v1/c8`
  }

  constructor(
    private http: HttpClient
  ) { }
  
  public getPolicyDocument(policyId: string, category: string) {
    return this.http.get<PolicyDocument>(`${this.config.apiUrl}/billing/document`, { params: { quoteId: policyId, category } });
  }

  public openDocument(doc: PolicyDocument) {
    const byteCharacters = atob(doc.documentAsBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileURL = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf;base64' }));
    window.open(fileURL, '_blank');
  }
}
