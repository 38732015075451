import { Component } from '@angular/core';
import { CustomizationManager } from '@core/services/customization-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private title: string;

  constructor(
    private CustomizationManager: CustomizationManager
  ) {
    this.title = this.CustomizationManager.get('tab-title');
  }
}
