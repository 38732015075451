import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home/home.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { BillingModule } from './modules/billing/billing.module';
import { PolicyModule } from './modules/policy/policy.module';
import { ErrorNotFoundComponent } from '@shared/components/error-pages/error-not-found/error-not-found.component';
import { ErrorForbiddenComponent } from '@shared/components/error-pages/error-forbidden/error-forbidden.component';
import { CoreModule } from '@core/core.module';
import { PolicyService } from '@modules/policy/policy.service';
import { BearerTokenInterceptor, HubioLoginModule, JwtInterceptor } from '@hubio/hubio-login';

import { environment } from '@environments/environment';
import { HubioCommonPagesModule } from '@hubio/hubio-common-components';

@NgModule({
  declarations: [
    AppComponent,
    ErrorNotFoundComponent,
    ErrorForbiddenComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HomeModule,
    DashboardModule,
    BillingModule,
    PolicyModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HubioLoginModule.forRoot(environment),
    HubioCommonPagesModule.forRoot(environment)
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    PolicyService
  ]
})
export class AppModule { }
