import { MatSnackBar } from '@angular/material';
import { SaveService } from './../../../../../services/shared/save.service';
import { DynamicDropdownService } from '../../../../../services/shared/dynamic.dropdown.service';
import { TreeService } from '../../../../../services/location/tree.service';
import LocationTabs from '@shared/models/location.model';
import { PolicyDataService } from '../../../../../services/shared/policy-data.service';
import { FormTabService } from '../../../../../../../../../projects/form-tab/src/lib/form-tab.service';
import { JsonFormFetcherService } from '../../../../../services/shared/json-form-fetcher.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PolicyService } from '@modules/policy/policy.service';
import { FormService } from '@modules/policy/services/shared/form.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  form: FormGroup;
  formJson: any;
  private policyID: string;
  SaveHelper: FormSaveHelper;
  formID: number;
  subtab: string = 'riskAddress';
  subtabs;
  treeData: LocationTabs[];
  tabState;

  constructor(
    private route: ActivatedRoute,
    private SaveService: SaveService,
    private FormHelper: FormService,
    private PolicyService: PolicyService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private FormTabService: FormTabService,
    private PolicyDataService: PolicyDataService,
    private treeService: TreeService,
    private DynamicDropdownService: DynamicDropdownService,
    private snackbar: MatSnackBar
  ) { }

  saveBeforeDeactivate() {
    return (this.form && (this.form.touched || this.form.dirty)) ?
      this.save() : of(null).toPromise();
  }

  ngOnInit() {
    this.matTreeDataListener();
    this.policyID = this.PolicyDataService.getPolicyID();
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.configSubject;
    this.route.params.subscribe(routeParams => {
      combineLatest([stateSubject, configSubject]).pipe(
        first(),
        map(res => {
          const [states, config] = res;
          return {
            states,
            config
          }
        })
      ).subscribe(data => {
        const { states, config } = data;
        const { formId } = routeParams;
        this.tabState = states.location
        this.formID = formId;
        this.subtabs = config.tabs.find(curTab => curTab.tabKey == 'location').lists[0].subTabs;
        this.handleForms(this.subtabs);
      })
    });
  }

  matTreeDataListener() {
    this.treeService.dataSourceSubject.subscribe(data => {
      this.treeData = data;
    });
  }

  handleForms(subtabs: []) {
    const result = this.FormHelper.initForm({
      subtab: this.subtab,
      formid: this.formID,
      subTabList: subtabs,
      policyID: this.policyID,
      shouldCache: true
    });
    if (typeof result === 'number') this.displayForm(result);
    if (isObservable<any>(result)) this.getForm(result);
  }

  getForm(obs: Observable<any>) {
    obs.subscribe(result => {
      this.form = result.form;
      this.formJson = result.orderedFormJson;
      this.FormHelper.setForm({
        formid: this.formID,
        form: this.form,
        orderedFormJson: this.formJson
      });
      this.treeService.appendFormToData(this.treeData, this.formID, this.form);
      this.disableAllFields();
    });
  }

  displayForm(formId: number) {
    this.form = this.PolicyService.getForm(formId);
    this.formJson = this.PolicyService.getFormJson(formId);
    this.treeService.appendFormToData(this.treeData, this.formID, this.form);
    this.disableAllFields();
  }

  disableAllFields() {
    const control = this.form.get('RiskAddress');
    control.setValidators(null);
    control.updateValueAndValidity();
    control.disable();
  }

  // Save tab level state
  save() {
    const tabForm: FormGroup = this.PolicyService.getTabForms().location;
    const state = {
      id: this.tabState.id,
      state: tabForm.status == 'DISABLED' ? true : tabForm.valid
    }
    return this.SaveService.updateStates(this.policyID, state).toPromise();
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.PolicyDataService.updateStates(tree);
    });
  }

  showSaveSnackbar() {
    this.snackbar.open('Saved', null, {
      duration: 2000,
    });
    this.updateNavigationTree();
  }
}
