import { Component, OnInit, ViewChild } from '@angular/core';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { FormGroup } from '@angular/forms';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';

@Component({
  selector: 'app-broker-report',
  templateUrl: './broker-report.component.html',
  styleUrls: ['./broker-report.component.scss']
})
export class BrokerReportComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: FormComponent;

  constructor() { }

  ngOnInit() { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formjson, this.form);
  }
}
