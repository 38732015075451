import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';

@Component({
  selector: 'app-heating-info',
  templateUrl: './heating-info.component.html',
  styleUrls: ['./heating-info.component.scss']
})
export class HeatingInfoComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor() { } 

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
  }
}
