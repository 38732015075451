import { Component, Input } from '@angular/core';
import { CoveredItem } from '@shared/models/rating.model';

@Component({
  selector: 'app-rating-coverage-table',
  templateUrl: './rating-coverage-table.component.html',
  styleUrls: ['./rating-coverage-table.component.scss']
})
export class RatingCoverageTableComponent {
  @Input() items: CoveredItem;
  @Input() coverageColumns: string[];
  @Input() discountsAndSurchargeColumns: string[];

  constructor() { }

  ngOnInit() { }
}
