import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssetFetcherService {
  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  getAssetFile(params: { name: string, quoteId?: string }) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/file`, { params });
  }

  getStylesheet(name: string) {
    return this.http.get<any>(`${this.config.apiUrl}/stylesheet`, { params: { name } })
  }
}
