import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';
import { of } from 'rxjs';
import { TreeService } from '@modules/policy/services/location/tree.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { SaveService } from '@modules/policy/services/shared/save.service';

@Component({
  selector: 'app-grain-elevator-details',
  templateUrl: './grain-elevator-details.component.html',
  styleUrls: ['./grain-elevator-details.component.scss']
})
export class GrainElevatorDetailsComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor(
    private TreeService: TreeService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService,
    private PolicyDataService: PolicyDataService
  ) { }

  saveBeforeDeactivate(): Promise<any> {
    if (this.formComponent != null && this.formComponent.coverageTableComponent != null) {
      if ((this.formComponent.form.dirty || this.formComponent.form.touched) ||
        (this.formComponent.coverageTableComponent.form.dirty || this.formComponent.coverageTableComponent.form.touched)) {
        this.formComponent.coverageTableComponent.save();
      }
    }
    // TODO: This solution works but is temporarily in place while we resolve the race
    // condition that happens in the regular this.formComponent.saveBeforeDeactivate workflow
    // where navigation and data are saved in parallel. 
    if (this.form && (this.form.dirty || this.form.touched)) {
      const policyId = this.PolicyDataService.getPolicyID();
      const cascadingIds = {
        nodeState: [Number(this.formComponent.formId), this.form.valid]
      };
      const { payload, navPoint } = this.FormSaveHelper.buildSavePayload(this.form, cascadingIds);
      if (!payload || !navPoint) {
        console.error("Something wrong with save payload");
        return of(null).toPromise();
      }
      return this.SaveService.updatePolicy(policyId, payload.data, navPoint).toPromise().then((dataRes) => {
        this.TreeService.setParentNodeName(dataRes)

        this.SaveService.updateStates(policyId, payload.navigation).toPromise().then((navRes) => {
          this.PolicyDataService.updateStates(navRes);
        });
      });
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) {
      handleValidations(this.formComponent.formJson, this.form);
    }
  }
}
