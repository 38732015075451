import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MetadataService } from '@core/services/metadata.service';
import { AnalyticsService } from '@modules/dashboard/services/analytics.service';
import { FilterService } from '@modules/dashboard/services/filter.service';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
    filters: Array<any>;
    filterForm: FormGroup = new FormGroup({});
    timeIntervalLabel: Observable<string>;

    constructor(
        private metaDataService: MetadataService,
        private analyticsService: AnalyticsService,
        private filterService: FilterService,
        private router: Router
    ) { }

    ngOnInit() {
        const filtersConfig = this.metaDataService.getAnalyticsMetaData().filters;
        this.filters = this.buildFilters(filtersConfig);
        this.setFilteredData();
        this.timeIntervalLabel = this.analyticsService.getTimeIntervalSubject()
    }

    buildFilters(filtersConfig: Array<any>) {
        return filtersConfig.map(field => {
            const control = new FormControl(field.defaultValue ?? this.analyticsService.SELECT_ALL);
            this.filterForm.addControl(field.name, control);
            return {
                ...field,
                values: field.values,
                abstractControl: control
            };
        })
    }

    resetFilters() {
        if (this.filterForm.dirty) {
            this.filterForm.reset();
            for (const [controlName, control] of Object.entries(this.filterForm.controls)) {
                const controlDefault = this.filters.find(({ name }) => name == controlName)?.defaultValue
                control.setValue(controlDefault ?? this.analyticsService.SELECT_ALL)
            }
        }
        this.setFilteredData();
    }

    setFilteredData() {
        const payload = {}
        Object.entries(this.filterForm.getRawValue()).forEach(([key, value]) => {
            payload[key] = value != this.analyticsService.SELECT_ALL ? value : null
        });
        this.analyticsService.filterAnalytics(payload);
    }

    handleGraphClick(event) {
        const formValues02 = this.filterForm.getRawValue();
        const filterConfigs = this.metaDataService.getWorkbenchMetaData().filters;
        const filter = { ...event };

        // Filters to be aligned - this mapping is wonky
        for (const filterConfig of filterConfigs) {
            if (formValues02[filterConfig.name] && formValues02[filterConfig.name] !== 'ALL') {
                if (filterConfig.type === 'multiSelection') {
                    filter[filterConfig.name] = [formValues02[filterConfig.name]];
                } else {
                    filter[filterConfig.name] = formValues02[filterConfig.name];
                }
            }
        }

        this.router.navigateByUrl('/dashboard').then(() => {
            this.filterService.newEvent({ filter });
        });
    }
}
