import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public buildingDescriptionSubject: BehaviorSubject<any>;

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) {
    this.buildingDescriptionSubject = new BehaviorSubject("");
  }

  updateBuildingDescription(value: any) {
    this.buildingDescriptionSubject.next(value);
  }

  addLocation(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/location`, body, { params: { quoteId, parentId } });
  }

  deleteLocation(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/location`, { params: { quoteId, nodeId, parentId } });
  }

  addRisk(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/risk`, body, { params: { quoteId, parentId } });
  }

  deleteRisk(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/risk`, { params: { quoteId, parentId, nodeId } });
  }

  addFarmBuilding(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/farmBuilding`, body, { params: { quoteId, parentId } });
  }

  deleteFarmBuilding(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/farmBuilding`, { params: { quoteId, nodeId, parentId } });
  }

  addBinsSilo(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/binsSilo`, body, { params: { quoteId, parentId } });
  }

  deleteBinsSilo(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/binsSilo`, { params: { quoteId, nodeId, parentId } });
  }

  addLiability(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/liability`, body, { params: { quoteId, parentId } });
  }

  deleteLiability(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/liability`, { params: { quoteId, parentId, nodeId } });
  }

  addScheduleItem(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/scheduleItem`, body, { params: { quoteId, parentId } });
  }

  deleteScheduleItem(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/scheduleItem`, { params: { quoteId, parentId, nodeId } });
  }

  addElevator(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/elevator`, body, { params: { quoteId, parentId } });
  }

  deleteElevator(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/elevator`, { params: { quoteId, nodeId, parentId } });
  }

  addWatercraft(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/watercraft`, body, { params: { quoteId, parentId } });
  }

  deleteWatercraft(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/watercraft`, { params: { quoteId, parentId, nodeId } });
  }

  addOutbuilding(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/outbuilding`, body, { params: { quoteId, parentId } });
  }

  deleteOutbuilding(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/outbuilding`, { params: { quoteId, parentId, nodeId } });
  }

  addGrains(body, quoteId, parentId) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/action/grain`, body, { params: { quoteId, parentId } });
  }

  deleteGrain(quoteId, nodeId, parentId) {
    return this.http.delete<any>(`${this.config.apiUrl}/quote-editor/action/grain`, { params: { quoteId, nodeId, parentId } });
  }
}
