import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { ClientSearchOptions } from '@shared/models/client-search-options.model';
import { SaveService } from '@modules/policy/services/shared/save.service';
import { ClientService } from '@modules/policy/services/client/client.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss']
})
export class ClientInformationComponent implements OnInit {
  clientForm: FormGroup;
  searchResult: FormGroup;
  policyId: number;
  parentNodeId: number;
  nodeId: number;
  tab: string;
  subtab: string;
  shouldHideClientField: boolean;
  cachedValues: any;
  clientKey: string;
  options: ClientSearchOptions;
  initSubscription: Subscription;

  @ViewChild('formComponent', { static: false }) formComponent: FormComponent;
  constructor(
    public dialog: MatDialog,
    private PolicyDataService: PolicyDataService,
    private ClientService: ClientService,
    private SaveService: SaveService
  ) { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.tab = 'client';
    this.subtab = 'clientInformation';
    this.options = {
      sectionTitle: 'Client Search',
      objectName: 'client',
      clientReturnKeys: {
        clientKey: 'ClientPASClientId',
        contactReturnKey: 'ClientPASClientId',
        addressKey: 'ClientAddressPostalTxt',
        nameKey: 'ClientClientSearchCd',
      },
      formFields: {
        formName: 'ClientContactInformation',
        firstNameField: 'ClientFirstNameTxt',
        lastNameField: 'ClientLastNameTxt',
        companyNameField: 'ClientCompanyNameTxt',
        postalCodeField: 'ClientAddressPostalTxt'
      },
      service: this.ClientService
    };
    this.shouldHideClientField = true;
    const statesSubject = this.PolicyDataService.stateSubject;
    this.initSubscription = statesSubject.subscribe(states => {
      this.policyId = Number(this.PolicyDataService.getPolicyID());
      this.parentNodeId = states[this.tab].id;
      this.nodeId = states[this.tab][this.subtab].id;
    });
    this.searchResult = new FormGroup({
      client: new FormControl({ value: '', disabled: true })
    });
  }

  initForm(form: FormGroup) {
    this.clientForm = form;
    this.options.formFields.formName = Object.keys(this.clientForm.controls)[0];
    if (this.formComponent) {
      handleValidations(this.formComponent.formjson, this.clientForm);
    }
    this.setCachedFormValues();
  }

  setCachedFormValues() {
    this.cachedValues = this.PolicyDataService.getValueFromCache(this.nodeId);
    if (this.cachedValues[this.options.clientReturnKeys.clientKey] ||
      this.cachedValues.ClientStatusCd === 'C') {
      this.clientKey = this.cachedValues[this.options.clientReturnKeys.clientKey];
    } else {
      this.clientKey = null;
    }
  }

  setClientKey(newClientKey: string) {
    this.clientKey = newClientKey;
    const payload = [
      { id: this.parentNodeId, state: true },
      { id: this.nodeId, state: true }
    ];
    this.SaveService.updateStates(this.policyId, payload).subscribe();
    this.PolicyDataService.getData(this.policyId, this.nodeId).subscribe();
  }

  ngOnDestroy() {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
  }
}
