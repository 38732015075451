import { forkJoin, of } from 'rxjs';
import { SaveService } from './save.service';
import { FormGroup } from '@angular/forms';
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { PolicyDataService } from './policy-data.service';

@Injectable({
  providedIn: 'root'
})
export class FormSaveHelper {

  constructor(private policyDataService: PolicyDataService) { }

  private getDirtyValues(form: FormGroup) {
    let dirtyValues = {};
    Object.keys(form.controls)
      .forEach(key => {
        let currentControl: FormGroup = form.controls[key] as FormGroup;
        if (currentControl.value !== undefined) {
          if (currentControl.controls)
            dirtyValues[key] = this.getDirtyValues(currentControl);
          else
            dirtyValues[key] = currentControl.value;
        }
      });
    return dirtyValues;
  }

  public buildSavePayload(form: FormGroup, cascadingIds: object) {
    const values = this.getDirtyValues(form);
    const navigation = this.buildStatePayload(cascadingIds);
    const data = {}
    Object.assign(data, ...function _flatten(o) {
      return [].concat(...Object.keys(o).map(k => {
        return typeof o[k] === 'object' && !(o[k] instanceof Date) && o[k] !== null ? _flatten(o[k]) : ({ [k]: o[k] });
      }))
    }(values));
    const result = {
      payload: {
        navigation,
        data,
      },
      navPoint: cascadingIds["nodeState"]
    }
    return result;
  }

  buildStatePayload(cascadingIds: { type?: any, val?: any[] }) {
    const navigation = [];
    for (const [key, value] of Object.entries(cascadingIds)) {
      const [id, state, name] = value;
      if (name) {
        navigation.push({
          id,
          state,
          name
        })
      } else {
        navigation.push({
          id,
          state,
        })
      }
    }
    return navigation;
  }

  public save(form: FormGroup, cascadingIds: any, subTabList: any, SaveService: SaveService, policyID) {
    const readOnly = this.policyDataService.policyReadOnly.getValue()
    if (readOnly) return of(null);
    const { payload, navPoint } = this.buildSavePayload(form, cascadingIds);
    if (!payload || !navPoint) {
      console.log("Something wrong with save payload");
      return;
    }
    const saveAPICall = SaveService.updatePolicy(policyID, payload.data, navPoint);
    const updateStateAPICall = SaveService.updateStates(policyID, payload.navigation);
    return forkJoin([saveAPICall, updateStateAPICall]).pipe(map(result => {
      return {
        data: result[0],
        states: result[1]
      }
    }));
  }
}
