import { Validators } from '@angular/forms';

export function defaultValidator(validations, type): any {
  let validators = []
  if (validations != null && type != "checkbox") {
    for (let i = 0; i < validations.default.length; i++) {
      switch (validations.default[i]) {
        case 'min': validators.push(Validators.min(validations.default[i+1]));
          break;
        case 'max': validators.push(Validators.max(validations.default[i+1]));
          break;
        case 'required': validators.push(Validators.required);
          break;
        case 'requiredTrue': validators.push(Validators.requiredTrue);
          break;
        case 'email': validators.push(Validators.email);
          break;
        case 'minLength': validators.push(Validators.minLength(validations.default[i+1]));
          break;
        case 'maxLength': validators.push(Validators.maxLength(validations.default[i+1]));
          break;
        case 'pattern': validators.push(Validators.pattern);
          break;
      }
    }
  }
  return validators
}