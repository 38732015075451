import { DynamicDropdownService } from './../../../services/shared/dynamic.dropdown.service';
import { PolicyDataService } from './../../../services/shared/policy-data.service';
import { FormTabService } from './../../../../../../../projects/form-tab/src/lib/form-tab.service';
import { JsonFormFetcherService } from './../../../services/shared/json-form-fetcher.service';
import { PolicyService } from './../../../policy.service';
import { SaveService } from './../../../services/shared/save.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '@modules/policy/services/shared/form.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { BehaviorSubject, isObservable, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { CanComponentDeactivate } from '@modules/policy/guards/save.guard';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, CanComponentDeactivate {
  @Input() subtab: string;
  @Input() tab: string;
  @Output() newFormEvent = new EventEmitter<FormGroup>();
  @Output() newTableEvent = new EventEmitter<object | undefined>();

  form: FormGroup;
  formjson: any;
  private policyID: string;
  formID: string;
  subtabList;
  tabState;
  tabConfig;
  readOnlyPolicy: boolean;

  constructor(
    private route: ActivatedRoute,
    private SaveService: SaveService,
    private SaveHelper: FormSaveHelper,
    private FormHelper: FormService,
    private PolicyService: PolicyService,
    private JsonFormFetcherService: JsonFormFetcherService,
    public FormTabService: FormTabService,
    private PolicyDataService: PolicyDataService,
    private DynamicDropdownService: DynamicDropdownService,
    private snackbar: MatSnackBar,
    private cd: ChangeDetectorRef
  ) { }

  saveBeforeDeactivate(): Promise<any> {
    if (this.form && this.FormHelper.doesFormExist(Number(this.formID)) && (this.form.dirty || this.form.touched)) {
      return this.save(true).toPromise().then((res) => {
        this.PolicyService.updateTouchedNodeStates(res);
      });
    } else {
      return of(null).toPromise();
    }
  }

  newForm(form: FormGroup) {
    this.newFormEvent.emit(form);
  }

  emitTable(tableConfig: any) {
    this.newTableEvent.emit(tableConfig)
  }

  ngOnInit() {
    this.policyID = this.PolicyDataService.getPolicyID();
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.configSubject;
    const $subjects = this.route.params;
    $subjects.pipe(
      withLatestFrom(stateSubject, configSubject)
    ).subscribe(routeData => {
      const [params, states, config] = routeData;
      const subtabConfig = config.tabs.find(curTab => curTab.tabKey == this.tab);
      this.subtabList = subtabConfig.subTabs;
      const { shouldCache } = subtabConfig;
      this.formID = states[this.tab][this.subtab] ? states[this.tab][this.subtab].id : params.formId;
      this.tabConfig = config.tabs.find(curTab => curTab.tabKey == this.tab);
      this.subtabList = this.tabConfig.subTabs || [];
      if (this.tabConfig.lists != null) {
        this.tabConfig.lists.forEach((listConfig) => {
          const listSubTabs = listConfig.subTabs.map((subtab) => {
            return {
              isList: true,
              ...subtab
            }
          });
          this.subtabList = this.subtabList.concat(listSubTabs);
        });
      }
      this.tabState = states[this.tab];
      this.handleForms(shouldCache);
    });
  }

  handleForms(shouldCache: boolean) {
    const result = this.FormHelper.initForm({
      subtab: this.subtab,
      formid: this.formID,
      subTabList: this.subtabList,
      policyID: this.policyID,
      shouldCache
    });
    if (typeof result === 'number') {
      this.displayForm(result);
    }
    if (isObservable<any>(result)) {
      this.getForm(result);
    }
  }

  getForm(obs: Observable<any>) {
    obs.subscribe(result => {
      this.form = result.form;
      this.formjson = result.orderedFormJson;
      this.FormHelper.setForm({
        formid: this.formID,
        form: this.form,
        orderedFormJson: this.formjson
      });
      this.afterFormInit();
    }, (err: any) => console.error(err)) ;
  }

  displayForm(formId: number) {
    this.form = this.PolicyService.getForm(formId);
    this.formjson = this.PolicyService.getFormJson(formId);
    this.afterFormInit();
  }

  afterFormInit() {
    this.handleReadOnlyForm()
    this.newForm(this.form);
    const includesTable: object | undefined = this.formjson.find(({ type }) => type === "table")
    this.emitTable(includesTable);
    this.form.markAsPristine();
  }

  save(routeChange?: boolean): Observable<any> {
    if (this.readOnlyPolicy || this.form.disabled) return
    const cascadingIds = {
      nodeState: [Number(this.formID), !this.form.invalid],
      tabState: [this.tabState.id, !this.PolicyService.getTabForms()[this.tab].invalid]
    };
    const saveObs: Observable<any> = this.SaveHelper.save(
      this.form,
      cascadingIds,
      this.subtab,
      this.SaveService,
      this.policyID
    );
    if (routeChange === true) {
      return saveObs;
    }
    saveObs.subscribe((res: any) => {
      if (!res) return
      this.PolicyService.updateTouchedNodeStates(res);
      this.showSaveSnackbar();
      this.form.markAsUntouched();
      this.form.markAsPristine();
    });
    this.updateNavigationTree();
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.PolicyDataService.updateStates(tree);
      this.tabState = tree[this.tab];
    });
  }

  showSaveSnackbar() {
    this.snackbar.open('Saved', null, {
      duration: 2000,
    });
  }

  handleReadOnlyForm() {
    this.PolicyDataService.policyReadOnly.subscribe(readOnly => {
      this.readOnlyPolicy = readOnly
      if (readOnly) this.form.disable()
    })
  }
}
