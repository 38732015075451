import { validatePostalCodeWrapper, isValueWholeNumberWrapper, validateYearFormatWrapper, validateDateFormatWrapper, isDateInThePastWrapper, validatePhoneNumberWrapper, validateEmailAddressWrapper, validateRiskUnitsNumberWrapper } from "./backend-validation.validator";

export const validatorFuncs = {
  "isValueWholeNumber": isValueWholeNumberWrapper,
  "validateYearFormat": validateYearFormatWrapper,
  "validatePostalCode": validatePostalCodeWrapper,
  "validateDateFormat": validateDateFormatWrapper,
  "isDateInThePast": isDateInThePastWrapper,
  "validatePhoneNumber": validatePhoneNumberWrapper,
  "validateEmailAddress": validateEmailAddressWrapper,
  "validateRiskUnitsNumber": validateRiskUnitsNumberWrapper
}