import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-component-can-deactivate',
  templateUrl: './component-can-deactivate.component.html',
  styleUrls: ['./component-can-deactivate.component.scss']
})
export abstract class ComponentCanDeactivateComponent {
  abstract canDeactivate(): boolean;
  constructor() { }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

}
