import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetadataService } from '@core/services/metadata.service';
import { environment } from '@environments/environment';
import { of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private apiUrl = `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`;
  private analyticsData = new Subject<any[]>();
  private analyticsDataTimeInterval = new Subject<string>();
  public readonly SELECT_ALL: string = 'ALL';

  constructor(private http: HttpClient, private metadataService: MetadataService) { }

  filterAnalytics(filters) {
    this.http.post<any>(`${this.apiUrl}/analytics/filter`, filters)
      .pipe(catchError((err) => of(err)))
      .subscribe(data => this.updateDataSubject(data, filters));
  }

  getDataSubject() {
    return this.analyticsData.asObservable();
  }

  getTimeIntervalSubject() {
    return this.analyticsDataTimeInterval.asObservable();
  }

  private updateDataSubject(analyticData: Array<any>, filters: any) {
    const timeKey = this._determineTimeKey()
    this.analyticsDataTimeInterval.next(filters[timeKey])
    this.analyticsData.next(analyticData);
  }

  private _determineTimeKey() {
    return this.metadataService.getAnalyticsMetaData().filters.find(({ label }) => {
      return label.toLowerCase().includes('time')
    })['name']
  }
}
