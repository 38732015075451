import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsService } from '@modules/dashboard/services/analytics.service';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {
    graph: Chart;
    dataSubscription: Subscription;
    readonly COLORS = {
        MAIN: {
            background: '#E88444',
            hover: '#CF6521'
        },
        SECONDARY: {
            background: '#E8B799',
            hover: '#D98857'
        },
    };

    @Output() graphClick: EventEmitter<any> = new EventEmitter();

    constructor(private analyticsService: AnalyticsService) { }

    ngOnInit() {
        this.dataSubscription = this.analyticsService.getDataSubject()
            .subscribe((graphData: any) => {
                if (graphData.error) {
                    return;
                }

                this.handleGraphData(graphData);
            });
    }

    handleGraphData(data: any[]) {
        const labels = data.map(dataPoint => dataPoint.label);
        const graphData = data.map((dataPoint) => {
            return {
                data: dataPoint.values,
                metadata: dataPoint.metadata
            }
        });

        if (!this.graph) {
            this.graph = this.initGraph(labels, graphData);
        } else {
            this.graph.data.labels = labels;
            this.graph.data.datasets = this.buildDatasets(graphData);
            this.graph.update();
        }
    }

    initGraph(labels: Array<string>, graphData: Array<any>) {
        return new Chart('graph', {
            type: 'bar',
            data: {
                labels,
                datasets: this.buildDatasets(graphData)
            },
            options: {
                animation: {
                    duration: 700
                },
                onHover: (event, elements) => {
                    if (elements && elements.length > 0) {
                        (event.target as any).style.cursor = 'pointer';
                    } else {
                        (event.target as any).style.cursor = null;
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    position: 'top',
                },
                responsive: true,
                aspectRatio: 1.4,
                scales: {
                    yAxes: [{
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            stepSize: 1
                        }
                    }],
                    xAxes: [{
                        stacked: true
                    }]
                },
                tooltips: {
                    displayColors: false,
                    yPadding: 14,
                    xPadding: 20,
                    caretSize: 10,
                    backgroundColor: 'white',
                    titleFontColor: this.COLORS.MAIN.background,
                    footerFontColor: 'black',
                    bodyFontColor: 'rgb(50, 50, 50)',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    borderWidth: 1,
                    cornerRadius: 5,
                    callbacks: { footer: this.getFooterLabel }
                }
            }
        });
    }

    buildDatasets(graphData: Array<any>) {
        const barColors = Object.values(this.COLORS);
        let datasets: Chart.ChartDataSets = {};

        graphData.forEach(values => {
            Object.entries(values.data).forEach(([TT, count]) => {
                if (!(TT in datasets)) {
                    const colors = barColors.shift();
                    datasets[TT] = {
                        data: [],
                        metadata: [],
                        backgroundColor: colors.background,
                        label: TT,
                        borderWidth: 1,
                        hoverBackgroundColor: colors.hover
                    }
                }
                datasets[TT].data.push(count);
                datasets[TT].metadata.push(values.metadata);
            });
        });

        return Object.values(datasets);
    }

    private getFooterLabel(item: Chart.ChartTooltipItem[], { datasets }: Chart.ChartData) {
        const [{ index }] = item;

        let total = 0;
        for (const { data } of datasets) {
            total += data[index] as number;
        }

        return `Total: ${total}`;
    }

    clickHandler(event) {
        const points = this.graph.getElementsAtEvent(event);

        if (points.length) {
            const range = this.graph.data.datasets[0]['metadata'][points[0]['_index']].createdDate;
            this.graphClick.emit({
                createdDate: {
                    begin: moment(range.begin).toDate(),
                    end: moment(range.end).toDate()
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }
}
