import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HubioLoginCallbackComponent, HubioLoginService } from '@hubio/hubio-login';
import { MatDialog } from '@angular/material';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class JwtSizeGuard implements CanDeactivate<HubioLoginCallbackComponent> {
  constructor(
    private auth: HubioLoginService,
    private dialog: MatDialog
  ) { }

  canDeactivate(
    component: HubioLoginCallbackComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const isOversized = this.isOversized(this.auth.getCurrentUserToken());
    if (isOversized) {
      this.dialog.open(BasicDialogComponent, {
        data: {
          message: 'Authorization Token Error',
          onlyConfirm: true,
          title: 'Error',
          showDetails: false,
          dialogPrefix: 'auth-token-error-modal'
        },
        width: '480px'
      }).afterClosed().subscribe(() => {
        this.auth.logout();
      });
      return false;
    } else {
      return true;
    }
  }

  private isOversized(jwt: string) {
    const EIGHT_KB = 8;
    const sizeInBytes = new Blob([jwt]).size;
    const jwtInKB = sizeInBytes / 1024;

    return jwtInKB > EIGHT_KB;
  }
}
