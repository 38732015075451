import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '@core/services/document.service';
import { BillingService } from '@modules/billing/services/billing.service';
import { PolicyDocument } from '@shared/models/policy-document.model';

@Component({
  selector: 'app-summary-widget',
  templateUrl: './summary-widget.component.html',
  styleUrls: ['./summary-widget.component.scss']
})
export class SummaryWidgetComponent implements OnInit {
  summaryData;
  billingLookups;
  paymentFrequency;
  paymentType;

  constructor(
    private DocumentService: DocumentService,
    private route: ActivatedRoute,
    public billingService: BillingService
  ) { }

  ngOnInit() {
    const { lookups } = this.route.snapshot.data?.billing;
    this.billingLookups = lookups;
    this.updateDisplay();
  }

  updateDisplay() {
    const paymentFrequencyFilter = this.billingLookups.PaymentFrequencyInvoice.filter(filter => {
      return filter.value == this.billingService.billingData.BillingPaymentFrequencyCd;
    });

    this.paymentFrequency = paymentFrequencyFilter.length ? paymentFrequencyFilter[0].label : "";

    const paymentTypeFilter = this.billingLookups.PaymentType.filter(filter => {
      return filter.value == this.billingService.billingData.BillingPaymentTypeCd
    });

    this.paymentType = paymentTypeFilter.length ? paymentTypeFilter[0].label : "";
  }

  getQuoteDoc() {
    this.route.params.subscribe(params => {
      if (params.id != null) {
        this.DocumentService.getPolicyDocument(params.id, 'quote').subscribe((res: PolicyDocument) => {
          this.DocumentService.openDocument(res);
        });
      }
    })
  }
}
