import { FormComponent } from './../../../../../shared/components/form/form.component';
import { PolicyDataService } from './../../../../../services/shared/policy-data.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CanComponentDeactivate } from '@modules/policy/guards/save.guard';
import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';

@Component({
  selector: 'app-insured-information',
  templateUrl: './insured-information.component.html',
  styleUrls: ['./insured-information.component.scss']
})
export class InsuredInformationComponent implements OnInit, CanComponentDeactivate {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: FormComponent;

  constructor(
    private PolicyDataService: PolicyDataService
  ) { }

  ngOnInit() { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate();
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formjson, this.form);
    this.handleInsuredInformationValidations();
  }

  handleInsuredInformationValidations() {
    for (let form in this.form.controls) {
      const field = this.form.get(form).get("PolicyDescriptionTxt");
      if (field) field.valueChanges.subscribe(value => {
        this.PolicyDataService.updatePolicyDescription(value);
      });
    }
  }

}
