import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }

  constructor(private http: HttpClient) { }

  requestReview(quoteId, body) {
    return this.http.post<any>(`${this.config.apiUrl}/workflow/createquotemessage`, body, { params: { quoteId } });
  }
}