import { combineLatest, of, Subscription } from 'rxjs';
import { JsonFormFetcherService } from './../../../../../services/shared/json-form-fetcher.service';
import { SaveService } from './../../../../../services/shared/save.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { CoverageTableComponent } from './../../../../../shared/components/coverage-table/coverage-table.component';
import { TreeService } from '../../../../../services/location/tree.service';
import { PolicyService } from '../../../../../policy.service';
import { PolicyDataService } from '../../../../../services/shared/policy-data.service';
import { MatTreeNestedDataSource } from '@angular/material';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import LocationTabs from '@shared/models/location.model';
import { PolicyState } from '@shared/models/state.model';

@Component({
  selector: 'app-coverage-details',
  templateUrl: './coverage-details.component.html',
  styleUrls: ['./coverage-details.component.scss']
})
export class CoverageDetailsComponent {
  policyID: string;
  locationID: string;
  nodeID: string;
  dataSource: MatTreeNestedDataSource<LocationTabs>;
  subtab: string = "coverageDetails";
  tab: string = "location";
  states: PolicyState;
  listName: string;
  riskStates;
  form: FormGroup;
  initSubscription: Subscription;
  @ViewChild("tableComponent", { static: false }) tableComponent: CoverageTableComponent;

  constructor(
    private route: ActivatedRoute,
    private PolicyDataService: PolicyDataService,
    private TreeService: TreeService,
    private PolicyService: PolicyService,
    private FormSaveHelper: FormSaveHelper,
    private SaveService: SaveService,
    private JsonFormFetcherService: JsonFormFetcherService
  ) { }

  saveBeforeDeactivate() {
    if (this.PolicyService.getForm(Number(this.nodeID)) != null) {
      const cascadingIds: object = {
        nodeState: [Number(this.nodeID), this.form.valid]
      };
      const cascadingStates = this.FormSaveHelper.buildStatePayload(cascadingIds);
      this.SaveService.updateStates(this.policyID, cascadingStates).subscribe();
      return this.tableComponent.saveBeforeDeactivate();
    }
    return of(null).toPromise();
  }

  showSaveSnackbar() {
    this.tableComponent.showSaveSnackbar();
    this.tableComponent.updateNavigationTree();
  }

  ngOnInit() {
    this.matTreeSubscription();
    this.policyID = this.PolicyDataService.getPolicyID();
    const tabsObs = this.JsonFormFetcherService.getTabs(this.policyID);
    this.route.params.subscribe(params => {
      this.locationID = params["locationId"];
      this.nodeID = params["formId"];
      this.initSubscription = combineLatest([this.PolicyDataService.stateSubject, tabsObs]).subscribe(res => {
        const [states, tabs] = res;
        this.listName = this.PolicyDataService.getListName(this.tab, this.subtab, tabs.tabs);
        this.states = states;
      });
    });
  }

  ngOnDestroy() {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
  }

  initForm(form: FormGroup) {
    this.form = form;
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyID).subscribe(tree => {
      this.states = tree;
      this.PolicyDataService.updateStates(tree);
    })
  }

  matTreeSubscription() {
    this.dataSource = new MatTreeNestedDataSource<LocationTabs>();
    this.TreeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
  }
}
