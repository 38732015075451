import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FieldLookupService {
  public selectValues: { [k: string]: any[] } = {}

  constructor() { }

  updateSelectValues(fieldName: string, newValues: any[]) {
    this.selectValues[fieldName] = newValues;
  }
}
