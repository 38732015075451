import { Component, Input, OnInit } from '@angular/core';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() class: string;
  @Input() label: string;
  @Input() iconClass: string;
  @Input() iconType: string;
  @Input() disabled: boolean;
  @Input() focus: boolean;
  @Input() color: string = 'primary';
  @Input() filterActive: string;
  policyReadOnly: BehaviorSubject<boolean>;
  
  constructor(private policyDataService: PolicyDataService) { }

  ngOnInit() {
    if (this.label == 'Save') {
      this.policyReadOnly = this.policyDataService.policyReadOnly
    }
  }

  ngOnChanges({ filterActive }): void {
    if (!filterActive) return;
    if (filterActive.currentValue === this.label) {
      this.class += ' filter-button-active'
    } else {
      this.class = this.class.replace(' filter-button-active', '')
    }
  }
}
