import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { forkJoin, Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { JsonFormFetcherService } from "../services/shared/json-form-fetcher.service";
import { PolicyDataService } from '../services/shared/policy-data.service';

@Injectable({ providedIn: 'root' })
export class PolicyEditorResolver implements Resolve<any> {
  constructor(
    private PolicyDataService: PolicyDataService,
    private jsonFormFetcherService: JsonFormFetcherService,
    private PolicyService: PolicyCreationService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> | Promise<any> | any {
    const { id } = route.params;
    const policyConfig = this.PolicyDataService.getPolicyConfig()

    const getTabs = this.jsonFormFetcherService.getTabs(id);
    if (policyConfig) {
      const getStates = this.PolicyDataService.getPolicyStates(id);
      return forkJoin([getStates, getTabs]).pipe(map(result => {
        return {
          states: result[0],
          config: result[1]
        }
      }))
    } else {
      const policyEditInit = this.PolicyService.editPolicy(id);
      return policyEditInit.pipe(
        concatMap(editRes => getTabs.pipe(
          map(config => {
            return {
              policyConfig: editRes.config,
              states: editRes.navigation,
              config
            }
          })
        ))
      )
    }
  }
}