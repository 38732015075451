import { UnderWritingRules } from './../../../../shared/models/underwriting-rules.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { PolicyState } from '@shared/models/state.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PolicyDataService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  private formValuesCache = {};
  public policyDescriptionSubject: BehaviorSubject<string> = new BehaviorSubject("");
  public stateSubject: BehaviorSubject<PolicyState> = new BehaviorSubject(null);
  public underWritingRulesSubject: BehaviorSubject<UnderWritingRules[] | undefined> = new BehaviorSubject([]);
  public numUnderWritingRules: BehaviorSubject<number> = new BehaviorSubject(0);
  public policyReadOnly: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public policyConfig: BehaviorSubject<any> = new BehaviorSubject(null);
  private policyID: string;
  private lob: string;
  private transactionType: string;

  constructor(private http: HttpClient) { }

  updatePolicyDescription(data: string) {
    this.policyDescriptionSubject.next(data);
  }

  updateStates(data: PolicyState) {
    this.stateSubject.next(data);
  }

  updateUnderWritingRules(data: UnderWritingRules[]) {
    this.underWritingRulesSubject.next(data);
    this.numUnderWritingRules.next(data ? data.length : 0);
  }

  updatePolicyConfig(config: any) {
    this.policyConfig.next(config);
  }

  getPolicyConfig() {
    return this.policyConfig.getValue()
  }

  getHeaderConfig() {
    return this.getPolicyConfig().policyHeader;
  }

  assignPolicyReadOnly(policy: any) {
    // const readOnly = policy.PolicyTypeCd == 'FQ'; //TODO: this is where we will dictate what is a readOnly policy
    this.policyReadOnly.next(false);
  }

  insertCache(key, value) {
    this.formValuesCache[key] = value;
  }

  getCache() {
    return this.formValuesCache;
  }

  getValueFromCache(key) {
    return this.formValuesCache[key];
  }

  updatePolicyID(policyID: string) {
    this.policyID = policyID;
  }

  getPolicyID() {
    return this.policyID;
  }

  updateLOB(lob: string) {
    this.lob = lob;
  }

  getLOB() {
    return this.lob;
  }

  updateTransactionType(transactionType: string) {
    this.transactionType = transactionType;
  }

  getTransactionType() {
    return this.transactionType;
  }

  fetchAndUpdatePolicyStates() {
    this.getPolicyStates(this.policyID).subscribe((states) => {
      this.updateStates(states);
    });
  }

  getListName(tab: string, subtab: string, config: any): string {
    const tabConfig = config.find(e => e.tabKey === tab);
    let result;
    for (const lists of tabConfig["lists"][0]["lists"]) {
      result = lists["listName"];
      for (const subtabs of lists["subTabs"]) {
        if (subtabs["formName"] === subtab) return result;
      }
    }
    return result;
  }

  getPolicyStates(quoteId) {
    return this.http.get<PolicyState>(`${this.config.apiUrl}/quote-editor/navigation`, { params: { quoteId } });
  }

  getData(quoteId, nodeId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/data`, { params: { quoteId, nodeId } })
      .pipe(tap(res => this.insertCache(nodeId, res.data)));
  }

  getHeaderData(quoteId) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/header`, { params: { quoteId } });
  }

  updateCoverageData(quoteId, nodeId, body) {
    return this.http.post<any>(`${this.config.apiUrl}/quote-editor/sync/coverages`, body, { params: { quoteId, nodeId } });
  }

  getCoverageMetaData(policyId: string) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/assets`, { params: { quoteId: policyId, name: "coverage" } });
  }
}
