// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  PRODUCTION: $ENV.PRODUCTION || false,
  API_URL: $ENV.API_URL ? $ENV.API_URL.replace("https://", "") : 'localhost',
  API_PORT: $ENV.API_PORT === undefined ? 3230 : $ENV.API_PORT,
  PROTOCOL: $ENV.PROTOCOL || "http",
  BAMBORA_BASE_URL: $ENV.BAMBORA_BASE_URL || "https://iframe.bambora.qa.insuranceconnect.io",
  PREAUTH_FORM_URL: $ENV.PREAUTH_FORM_URL,
  GOOGLE_API_KEY: $ENV.GOOGLE_API_KEY,
  HTTP_REQUEST_DURATION: $ENV.HTTP_REQUEST_DURATION || 60000,

  TENANT: $ENV.TENANT || "mfi",
  ENVIRONMENT: $ENV.NODE_ENV || "development",
  LOGIN_URL: $ENV.LOGIN_URL || "https://mfi-dev.auth.ca-central-1.amazoncognito.com/login?client_id=6kels8sqbddukf7blnfffunbpm&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback%2F",
  LOGOUT_URL: $ENV.LOGOUT_URL || "https://mfi-dev.auth.ca-central-1.amazoncognito.com/logout?client_id=6kels8sqbddukf7blnfffunbpm&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin%2Fcallback%2F",
  HOMEPAGE_ROUTE: $ENV.HOMEPAGE_ROUTE || "/dashboard/",
  REDIRECT_URL: $ENV.REDIRECT_URL,
  AUTH_URL: $ENV.AUTH_URL || "https://auth.uat.insuranceconnect.io:443/api/v1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
