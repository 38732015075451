import { FormGroup } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { startWith } from "rxjs/operators";

export const handleFormHiding = (forms: any, formJson: any, field: any) => {

    let output = new Subject<boolean>();
    let initial = false;

    for (let form of formJson) {
        if (form.hiding && form.name == field.name) {
            const dependency: AbstractControl = forms.get(form.hiding.form).get(form.hiding.field);
            const initialValue = (forms.get(form.hiding.form) as FormGroup).getRawValue();
            dependency.valueChanges.pipe(startWith(initialValue[form.hiding.field])).subscribe(value => {
                const { values } = form.hiding;
                const invertValues = (form.hiding.invertValues == undefined ? false : form.hiding.invertValues);
                if ((Array.isArray(values) && values.includes(value) != invertValues) ||
                    ((values != undefined && !Array.isArray(values)) && (values == value) != invertValues) ||
                    (values == undefined && value != invertValues)) {
                    output.next(true);
                    initial = true;
                } else {
                    output.next(false);
                    initial = false;
                }
            });
            return { out: output.asObservable(), init: initial };
        }
    }

    output.next(false);
    initial = false;
    return { out: output.asObservable(), init: initial };
}
