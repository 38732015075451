import { DebugService } from '@core/services/debug.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from '../tabs/notes/dialogs/add-note-dialog/add-note-dialog.component';

@Component({
  selector: 'app-debug-data',
  templateUrl: './debug-data.component.html',
  styleUrls: ['./debug-data.component.scss']
})
export class DebugDataComponent implements OnInit {
  private logs: any[];
  constructor(public dialog: MatDialog, private DebugService: DebugService) { }

  ngOnInit() {
    this.DebugService.logs.subscribe(logs => {
      this.logs = logs;
    })
  }

  openDialog() {
    this.dialog.open(DebugDialog, {
      panelClass: 'dialog-debug-modal'
    })
  }

}

@Component({
  selector: 'debug-dialog',
  templateUrl: 'debug-dialog.html',
})
export class DebugDialog {
  private logs: any[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private DebugService: DebugService
  ) {
    this.fetchLogs();
  }

  fetchLogs() {
    this.DebugService.logs.subscribe(logs => {
      this.logs = logs;
    })
  }
}
