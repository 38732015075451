import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '@modules/dashboard/pages/dashboard/dashboard.component';
import { HubioLoginComponent, HubioLoginCallbackComponent, RouteGuard } from '@hubio/hubio-login';
import { MetadataGuard } from '@core/guards/metadata.guard';
import { JwtSizeGuard } from '@core/guards/jwtSize.guard';
import { ErrorNotFoundComponent } from '@shared/components/error-pages/error-not-found/error-not-found.component';
import { ErrorForbiddenComponent } from '@shared/components/error-pages/error-forbidden/error-forbidden.component';
import { AppResolver } from '@core/resolvers/app.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [RouteGuard, MetadataGuard],
    resolve: { app: AppResolver },
    data: {
      allowedGroups: [
        'c8-role-underwriter',
        'c8-role-admin',
        'c8-role-broker'
      ]
    }
  },
  {
    path: 'policy',
    loadChildren: () => import('@modules/policy/policy.module').then(m => m.PolicyModule),
    canActivate: [RouteGuard, MetadataGuard],
    resolve: { app: AppResolver },
    data: {
      allowedGroups: [
        'c8-role-underwriter',
        'c8-role-admin',
        'c8-role-broker'
      ]
    }
  },
  {
    path: 'billing',
    loadChildren: () => import('@modules/billing/billing.module').then(m => m.BillingModule),
    canActivate: [RouteGuard, MetadataGuard],
    resolve: { app: AppResolver },
    data: {
      allowedGroups: [
        'c8-role-underwriter',
        'c8-role-admin',
        'c8-role-broker'
      ]
    }
  },
  {
    path: 'login',
    component: HubioLoginComponent
  },
  {
    path: 'login/callback',
    component: HubioLoginCallbackComponent,
    canDeactivate: [JwtSizeGuard]
  },
  {
    path: 'error',
    children: [
      {
        path: '403',
        component: ErrorForbiddenComponent
      }
    ]
  },
  {
    path: '**',
    component: ErrorNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
