import { AbstractControl } from '@angular/forms';

// Google Maps helper methods
export const autofillAddress = (place: object, addressForm: AbstractControl) => {
  // Fetch address components
  const address = place['formatted_address'];
  const city = getCity(place);
  const province = getProvince(place);
  const postalCode = getPostCode(place);
  const country = getCountry(place);
  const streetNumber = getStreetNumber(place);
  const route = getRoute(place);

  // Autofill forms
  addressForm.patchValue({
    LocationStreetAddressTxt: `${streetNumber} ${route}`,
    LocationAddressMunicipalityTxt: city,
    LocationAddressProvinceTxt: province,
    LocationAddressCountryTxt: country,
    LocationAddressPostalTxt: postalCode,
    LocationAddressTxt: `${streetNumber} ${route}`,
    LocationCityCd: city,
    LocationProvinceCd: province,
    LocationCountryCd: country,
    LocationPostalCodeTxt: postalCode
  });
}

const getAddrComponent = (place, componentTemplate) => {
  let result;

  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentTemplate[addressType]) {
      result = place.address_components[i][componentTemplate[addressType]];
      return result;
    }
  }

  return result;
}

const getCity = (place) => {
  const COMPONENT_TEMPLATE = { locality: 'long_name' },
    city = getAddrComponent(place, COMPONENT_TEMPLATE);
  return city;
}

const getPostCode = (place) => {
  const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
    postCode = getAddrComponent(place, COMPONENT_TEMPLATE);
  return postCode;
}

const getProvince = (place) => {
  const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
    province = getAddrComponent(place, COMPONENT_TEMPLATE);
  return province;
}

const getCountry = (place) => {
  const COMPONENT_TEMPLATE = { country: 'short_name' },
    country = getAddrComponent(place, COMPONENT_TEMPLATE);
  return country;
}

const getRoute = (place) => {
  const COMPONENT_TEMPLATE = { route: 'long_name' },
    route = getAddrComponent(place, COMPONENT_TEMPLATE);
  return route;
}

const getStreetNumber = (place) => {
  const COMPONENT_TEMPLATE = { street_number: 'long_name' },
    number = getAddrComponent(place, COMPONENT_TEMPLATE);
  return number;
}
