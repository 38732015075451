import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicDropdownService {

  private config = {
    apiUrl: `${environment.PROTOCOL}://${environment.API_URL}:${environment.API_PORT}/api/v1/c8`
  }
  constructor(private http: HttpClient) { }

  private getStaticSelectValues(quoteId, nodeId, lookupName) {
    return this.http.get<any>(`${this.config.apiUrl}/quote-editor/lookup`, { params: { quoteId, nodeId, lookupName } });
  }

  private getDynamicSelectValues(lookupNames) {
    return this.http.post<any>(`${this.config.apiUrl}/lookup/dynamic`, { lookupNames });
  }

  private fetchAllSelectValues(config, quoteId, nodeId) {
    const staticNames = [], dynamicNames = []
    if (!Array.isArray(config)) return;
    for (const form of config) {
      if (form.parameters == null) continue;
      for (const field of form["parameters"]) {
        if (field["type"] == "yesno") {
          const lookupName = "YesNo";
          staticNames.push(lookupName);
        } else if (field["type"] == "select" || field["type"] == "search-select") {
          const lookupName = field["lookup"];
          if (!lookupName) continue;
          if (lookupName.includes('async_')) {
            field["lookup"] = lookupName.replace('async_', '')
            dynamicNames.push(field["lookup"]);
          }
          else staticNames.push(lookupName);
        }
      }
    }

    return {
      ...(staticNames.length && {
        staticLookups: this.getStaticSelectValues(quoteId, nodeId, staticNames)
      }),
      ...(dynamicNames.length && {
        dynamicLookups: this.getDynamicSelectValues(dynamicNames)
      })
    }
  }

  public appendSelectValuesToConfig(config, quoteId, nodeId) {
    const lookupCalls = this.fetchAllSelectValues(config, quoteId, nodeId);

    if (lookupCalls.staticLookups == null && lookupCalls.dynamicLookups == null) {
      return of(null);
    }

    return forkJoin(lookupCalls).pipe(map(({ staticLookups, dynamicLookups }: any) => {
      return this.addLookupValuesToConfig(config, { ...staticLookups, ...dynamicLookups })
    }));
  }

  private addLookupValuesToConfig(config, lookups) {
    for (const form of config) {
      if (form.parameters != null) {
        for (const field of form["parameters"]) {
          if (field["type"] == "yesno") {
            if (!lookups["YesNo"]) continue;
            field["options"] = lookups["YesNo"];
          } else if (field["type"] == "select" || field["type"] == "search-select") {
            const lookupName = field["lookup"];
            if (!lookupName || !lookups[lookupName]) continue;
            field["options"] = lookups[lookupName];
          }
        }
      }
    }
    return config;
  }
}
