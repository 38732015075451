import { ClientInformationComponent } from './components/tabs/client/subtabs/client-information/client-information.component';
import { InterestsFormComponent } from './components/tabs/interests/interests-form/interests-form.component';
import { FarmActivitiesComponent } from './components/tabs/policy/subtabs/farm-activities/farm-activities.component';
import { PolicyCoverageComponent } from './components/tabs/policy/subtabs/policy-coverage/policy-coverage.component';
import { FarmRevenueComponent } from './components/tabs/policy/subtabs/farm-revenue/farm-revenue.component';
import { InsuredInformationComponent } from './components/tabs/policy/subtabs/insured-information/insured-information.component';
import { SaveGuard } from './guards/save.guard';
import { AddressComponent } from './components/tabs/location/subtab/address/address.component';
import { CoverageDetailsComponent } from './components/tabs/location/subtab/coverage-details/coverage-details.component';
import { HeatingInfoComponent } from './components/tabs/location/subtab/heating-info/heating-info.component';
import { ScheduleItemInformationComponent } from './components/tabs/location/subtab/schedule-item-information/schedule-item-information.component';
import { SupplementInformationComponent } from './components/tabs/location/subtab/supplement-information/supplement-information.component';
import { RiskDetailsComponent } from './components/tabs/location/subtab/risk-details/risk-details.component';
import { BuildingDetailsComponent } from './components/tabs/location/subtab/building-details/building-details.component';
import { InterestsComponent } from './components/tabs/interests/interests.component';
import { NotesComponent } from './components/tabs/notes/notes.component';
import { ClaimsContentComponent } from './components/tabs/claims/claims-content.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PolicyEditorComponent } from './core/policy-editor.component';
import { PolicyComponent } from './components/tabs/policy/policy.component';
import { LocationContentComponent } from './components/tabs/location/location-content.component';

import { RatingComponent } from './components/tabs/rating/rating.component';
import { PolicyEditorResolver } from './resolvers/policy-editor.resolver';
import { CoveragesSyncGuard } from './guards/sync.guard';
import { LiabilityComponent } from './components/tabs/liability/liability/liability.component';
import { BuildingCoveragesComponent } from './components/tabs/location/subtab/building-coverages/building-coverages.component';
import { ClientComponent } from './components/tabs/client/client.component';
import { ClaimsFormComponent } from './components/tabs/claims/claims-form/claims-form.component';
import { ExposureInformationComponent } from './components/tabs/location/subtab/exposure-information/exposure-information.component';
import { LiabilityCoveragesComponent } from './components/tabs/location/subtab/liability-coverages/liability-coverages.component';
import { PolicyholderComponent } from './components/tabs/policy/subtabs/policyholder/policyholder.component';
import { WatercraftInformationComponent } from './components/tabs/location/subtab/watercraft-information/watercraft-information.component';
import { OutbuildingDetailsComponent } from './components/tabs/location/subtab/outbuilding-details/outbuilding-details.component';
import { BrokerReportComponent } from './components/tabs/policy/subtabs/broker-report/broker-report.component';
import { BinsAndSilosDetailsComponent } from './components/tabs/location/subtab/bins-and-silos-details/bins-and-silos-details.component';
import { BinsAndSilosCoverageDetailsComponent } from './components/tabs/location/subtab/bins-and-silos-coverage-details/bins-and-silos-coverage-details.component';
import { GrainDetailsComponent } from './components/tabs/location/subtab/grain-details/grain-details.component';
import { GrainElevatorDetailsComponent } from './components/tabs/location/subtab/grain-elevator-details/grain-elevator-details.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/dashboard', pathMatch: 'full'
  },
  {
    path: ':id/:lob',
    component: PolicyEditorComponent,
    resolve: { PolicyEditorData: PolicyEditorResolver },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'policy',
        component: PolicyComponent,
        children: [
          {
            path: 'insured-information',
            component: InsuredInformationComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: 'broker-report',
            component: BrokerReportComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: 'policyholder-information/:formId',
            component: PolicyholderComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: 'farm-operations',
            component: FarmRevenueComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: 'policy-coverage',
            component: PolicyCoverageComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: 'farm-activities-information',
            component: FarmActivitiesComponent,
            canDeactivate: [SaveGuard]
          }
        ]
      },
      {
        path: 'client',
        component: ClientComponent,
        children: [
          {
            path: ':clientId/client-information',
            component: ClientInformationComponent,
            canDeactivate: [SaveGuard]
          }
        ]
      },
      {
        path: 'location',
        component: LocationContentComponent,
        children: [
          {
            path: ':locationId/risk-address/:formId',
            component: AddressComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/risk-rating-details/:formId',
            component: RiskDetailsComponent,
            canDeactivate: [CoveragesSyncGuard, SaveGuard]
          },
          {
            path: ':locationId/heating-information/:formId',
            component: HeatingInfoComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/coverage-details/:formId',
            component: CoverageDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/exposure-information/:formId',
            component: ExposureInformationComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/liability-coverages/:formId',
            component: LiabilityCoveragesComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/building-details/:formId',
            component: BuildingDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/farm-coverage-details/:formId',
            component: BuildingCoveragesComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/schedule-item-information/:formId',
            component: ScheduleItemInformationComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/watercraft-information/:formId',
            component: WatercraftInformationComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/outbuilding-coverages/:formId',
            component: CoverageDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/outbuilding-details/:formId',
            component: OutbuildingDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/supplement-details/:formId',
            component: SupplementInformationComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/bins-and-silos-details/:formId',
            component: BinsAndSilosDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/bins-and-silos-coverage-details/:formId',
            component: BinsAndSilosCoverageDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/grain-details/:formId',
            component: GrainDetailsComponent,
            canDeactivate: [SaveGuard]
          },
          {
            path: ':locationId/grain-elevator-details/:formId',
            component: GrainElevatorDetailsComponent,
            canDeactivate: [SaveGuard]
          }
        ]
      },
      {
        path: 'claim',
        component: ClaimsContentComponent,
        children: [
          {
            path: 'claim-information/:formId',
            component: ClaimsFormComponent,
            canDeactivate: [SaveGuard]
          },
        ]
      },
      {
        path: 'interest',
        component: InterestsComponent,
        children: [
          {
            path: 'interest-information/:formId',
            component: InterestsFormComponent,
            canDeactivate: [SaveGuard]
          },
        ]
      },
      {
        path: 'rating',
        component: RatingComponent
      },
      {
        path: 'liability/:subtab',
        component: LiabilityComponent,
        canDeactivate: [SaveGuard]
      },
      {
        path: 'liability',
        component: LiabilityComponent,
      },
      {
        path: 'notes/policy-notes',
        component: NotesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PolicyRoutingModule { }
