import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { setRequiredValidation, getFieldValidations } from './shared/validators/custom-validations.validator';

@Injectable({
  providedIn: 'root'
})
export class FormTabService {
  public formJson: any
  public formValuesJson: any

  constructor() { }

  initForm(formJsonConfig: any, formValues: any): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.formJson = JSON.parse(formJsonConfig);
      this.formValuesJson = JSON.parse(formValues);
      if (this.formJson === null) { return; }
      // If formValues is a JSON array, multiple forms must be initialized
      if (Array.isArray(this.formValuesJson) && this.formValuesJson.length > 0) {
        const len = this.formValuesJson.length;
        const formValues = this.formValuesJson;
        for (let i = 0; i < len; i++) {
          this.formValuesJson = formValues[i];
          // id - caimid, locid, etc
          observer.next({ id: this.formValuesJson.id, form: this.toFormGroup(this.formJson, this.formValuesJson), orderedFormJson: this.formJson })
        }
      } else {
        observer.next({ form: this.toFormGroup(this.formJson, this.formValuesJson), orderedFormJson: this.formJson })
      }
      observer.complete();
    })
  }

  toFormGroup(formModel, formValues): any {
    let formGroup = {};
    let formControls = [];

    formModel.forEach(field => {
      if (field.type == 'object') {
        if (field.parameters != null) {
          formGroup[field.name] = this.toFormGroupFromArr(field.parameters);
          if (field.conditionalRequirements != null) {
            const conditionalRequirementsArray = field.conditionalRequirements;
            const validators = [];
            for (const conditionalRequirement of conditionalRequirementsArray) {
              validators.push(setRequiredValidation(conditionalRequirement, formGroup, formModel))
            }
            formGroup[field.name].setValidators(Validators.compose(validators));
          }
        }
      } else {
        formControls.push({ name: field.name, control: this.appendFieldFormControlToObject(field)[field.name] })
      }
    });

    let form = new FormGroup(formGroup);
    formControls.forEach(f => {
      form.addControl(f.name, f.control)
    });
    return form;
  }

  toFormGroupFromArr(arr) {
    return new FormGroup(arr.reduce((acc, cur) => {
      return this.appendFieldFormControlToObject(cur, acc)
    }, {}))
  }

  appendFieldFormControlToObject(field, obj = {}) {
    if (field.type == 'object') {
      obj[field.name] = this.toFormGroupFromArr(field.paramters);
    }
    else {
      const controlConfig = {
        value: this.formValuesJson[field.name],
        disabled: field.disable
      }
      const validations = getFieldValidations(field)
      obj[field.name] = new FormControl(controlConfig, { validators: validations, updateOn: "change" });
    }
    return obj;
  }

}
