import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BillingResolver } from '@modules/policy/resolvers/billing.resolver';
import { RouteGuard } from '@hubio/hubio-login';
import { BillingComponent } from './pages/billing/billing.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/dashboard', pathMatch: 'full'
  },
  {
    path: ':id',
    canActivate: [RouteGuard],
    resolve: {
      billing: BillingResolver
    },
    component: BillingComponent
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
