import { ChangeDetectorRef, Component } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent {
  constructor(
    public SpinnerService: SpinnerService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
