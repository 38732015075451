import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class PoliciesInForcePaginator extends MatPaginatorIntl {

  constructor() {
    super()
  }

  getRangeLabel = (page: number) => {
    return `Page ${page + 1}`;
  };
}
