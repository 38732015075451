
import ClaimTabs from '@shared/models/claims.model';
import { ClaimsService } from './../../../../services/claims/claims.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from '@modules/policy/shared/components/form/form.component';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';

@Component({
  selector: 'app-claims-form',
  templateUrl: './claims-form.component.html',
  styleUrls: ['./claims-form.component.scss']
})
export class ClaimsFormComponent implements OnInit {
  form: FormGroup;
  nodeId: string;
  @ViewChild('formComponent', { static: false }) formComponent: FormComponent;

  constructor(
    private ClaimsService: ClaimsService,
    private PolicyDataService: PolicyDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() { }

  saveBeforeDeactivate() {
    return this.formComponent.saveBeforeDeactivate().then(() => {
      this.PolicyDataService.getPolicyStates(this.PolicyDataService.getPolicyID()).toPromise().then((res)=> {
        this.PolicyDataService.updateStates(res);
      });
    });
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  initForm(form: FormGroup) {
    this.nodeId = this.route.snapshot.params.formId;
    this.form = form;
    this.appendFormToDataSource(form);
  }

  appendFormToDataSource(form: FormGroup) {
    this.ClaimsService.dataSourceSubject.pipe(
      filter(data => {
        const node: ClaimTabs = data.find(e => e.id === Number(this.nodeId));
        return node && node.formGroup === undefined
      })
    ).subscribe(data => {
      data.find(e => e.id === Number(this.nodeId)).formGroup = form;
      this.ClaimsService.updateDataSource(data);
    });
  }

  updateSubtabName(data) {
    const { ClaimDescriptionTxt } = data;
    this.ClaimsService.dataSourceSubject.pipe(
      take(1)
    ).subscribe(data => {
      data.find(e => e.id === Number(this.nodeId)).$name = ClaimDescriptionTxt;
      this.ClaimsService.updateDataSource(data);
    });
  }
}
