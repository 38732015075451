import { handleValidations } from '@modules/policy/shared/rules/handle-validations.rules';
import { LocationService } from '@modules/policy/services/location/location.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NestedSubtabFormComponent } from '@modules/policy/shared/components/nested-subtab-form/nested-subtab-form.component';
import { of } from 'rxjs';
import { TreeService } from '@modules/policy/services/location/tree.service';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { SaveService } from '@modules/policy/services/shared/save.service';

@Component({
  selector: 'app-bins-and-silos-details',
  templateUrl: './bins-and-silos-details.component.html',
  styleUrls: ['./bins-and-silos-details.component.scss']
})
export class BinsAndSilosDetailsComponent implements OnInit {
  form: FormGroup;
  @ViewChild("formComponent", { static: false }) formComponent: NestedSubtabFormComponent;

  constructor(
    private LocationService: LocationService,
    private PolicyDataService: PolicyDataService,
    private SaveService: SaveService,
    private FormSaveHelper: FormSaveHelper,
    private TreeService: TreeService
  ) { }

  saveBeforeDeactivate() {
    if (this.form && (this.form.dirty || this.form.touched)) {
      const policyId = this.PolicyDataService.getPolicyID();
      const cascadingIds = {
        nodeState: [Number(this.formComponent.formId), this.form.valid]
      };
      const { payload, navPoint } = this.FormSaveHelper.buildSavePayload(this.form, cascadingIds);
      if (!payload || !navPoint) {
        console.error("Something wrong with save payload");
        return of(null).toPromise();
      }
      return this.SaveService.updatePolicy(policyId, payload.data, navPoint).toPromise().then((dataRes) => {
        this.TreeService.setParentNodeName(dataRes)
        this.SaveService.updateStates(policyId, payload.navigation).toPromise().then((navRes) => {
          this.PolicyDataService.updateStates(navRes);
        });
      });
    } else {
      return of(null).toPromise();
    }
  }

  showSaveSnackbar() {
    this.formComponent.showSaveSnackbar();
    this.formComponent.updateNavigationTree();
  }

  ngOnInit() { }

  initForm(form: FormGroup) {
    this.form = form;
    if (this.formComponent) handleValidations(this.formComponent.formJson, this.form);
  }
}
