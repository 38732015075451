import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  public logs: BehaviorSubject<any>;

  constructor() {
    this.logs = new BehaviorSubject([]);
  }

  addLog(logData: any, url: string) {
    if (Array.isArray(logData)) return;
    logData["timestamp"] = new Date().toLocaleTimeString();
    logData["url"] = url;
    this.logs.next([logData, ...this.logs.getValue()]);
  }

  clearLogs() {
    this.logs.next([]);
  }
}
