import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingComponent } from './pages/billing/billing.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule, MatCheckboxModule, MatSelectModule, MatProgressSpinnerModule, MatDialogModule, MatRadioModule, MatListModule, MatStepperModule, MatCardModule, MatIconModule, MatButtonModule, MatInputModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { StepperComponent } from './components/stepper/stepper.component';
import { BillingStepComponent } from './components/billing-step/billing-step.component';
import { ConfirmationStepComponent } from './components/confirmation-step/confirmation-step.component';
import { PaymentsStepComponent } from './components/payments-step/payments-step.component';
import { BillingHeaderComponent } from './components/billing-header/billing-header.component';
import { SummaryWidgetComponent } from './components/summary-widget/summary-widget.component';
import { FormTabModule } from 'projects/form-tab/src/public-api';


@NgModule({
  declarations: [BillingComponent, StepperComponent, BillingStepComponent, ConfirmationStepComponent, PaymentsStepComponent, BillingHeaderComponent, SummaryWidgetComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    BillingRoutingModule,
    FormsModule, ReactiveFormsModule,
    MatTableModule, MatCheckboxModule, MatSelectModule, MatProgressSpinnerModule, MatDialogModule, MatRadioModule, MatListModule, MatStepperModule, MatCardModule, MatIconModule, MatButtonModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, FormTabModule
  ]
})
export class BillingModule { }
