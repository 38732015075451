import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { UnderWritingRules } from './../../../../../shared/models/underwriting-rules.model';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-underwriting-rules',
  templateUrl: './underwriting-rules.component.html',
  styleUrls: ['./underwriting-rules.component.scss']
})
export class UnderwritingRulesComponent implements OnInit {
  @Output() toggle = new EventEmitter<boolean>();
  data: UnderWritingRules[];

  constructor(private PolicyDataService: PolicyDataService) { }

  toggleRules() {
    this.toggle.next(true);
  }

  ngOnInit() {
    const underWritingRulesSubject = this.PolicyDataService.underWritingRulesSubject;
    underWritingRulesSubject.subscribe(data => {
      this.data = data;
    })
  }

}
