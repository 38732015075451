import { NotesService } from '../../../services/notes/notes.service';
import { AddNoteDialogComponent } from './dialogs/add-note-dialog/add-note-dialog.component';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import Note from '@shared/models/note.model';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['date', 'amount', 'actions'];
  ELEMENT_DATA: Note[] = [];

  note: string;

  constructor(public dialog: MatDialog, private NotesService: NotesService) {
    this.NotesService.getNotes(50).subscribe(notes => {
      this._generateNotes(notes);
    })
  }

  // Get rid of the error
  loadTabs() { }

  _generateNotes(notes) {
    notes.forEach(note => {
      const currNote: Note = {
        date: note.date,
        text: note.text,
        actions: "",
      }
      this.ELEMENT_DATA.push(currNote);
    });
    this.dataSource.data = this.ELEMENT_DATA;
  }

  addNote() {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      data: {
        text: this.note,
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      const today = new Date();
      const currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      if (!res) {
        return;
      }
      const newNote: Note = {
        date: currentDate,
        text: res,
        actions: "",
      };
      this.ELEMENT_DATA.push(newNote);
      this.dataSource.data = this.ELEMENT_DATA;
    });
  }

  removeNote(note: Note) {
    const index = this.dataSource.data.indexOf(note);
    this.dataSource.data.splice(index, 1);
    this.dataSource._updateChangeSubscription();
  }

}

