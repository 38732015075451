import { Component, OnInit } from '@angular/core';
import { CustomizationManager } from '@core/services/customization-manager.service';
import { PolicyCreationService } from '@modules/dashboard/services/policy.service';
import { HubioLoginService } from '@hubio/hubio-login';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public username: string;

  constructor(
    private auth: HubioLoginService,
    private CustomizationManager: CustomizationManager,
    private policyService: PolicyCreationService
  ) { }

  ngOnInit() {
    this.username = this.auth.getCurrentUserTokenDecoded().email;
  }

  logout() {
    this.policyService.clearBrokers();
    this.auth.logout();
  }
}
