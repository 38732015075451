import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { RatingService } from '../../../services/rating/rating.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Rating, BindResult } from '@shared/models/rating.model';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { UnderwriterReviewDialogComponent } from '@modules/policy/shared/components/underwriter-review-dialog/underwriter-review-dialog.component';
import { Error } from '@shared/models/error.model';
import { AssetFetcherService } from '@modules/policy/services/shared/asset-fetcher.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  errorPayload: Error;
  moreDetails: boolean = false;
  needsUnderwriterReview: boolean = false;
  checkoutHidden: boolean;

  generalCoverageColumns: string[] = ['Name', 'Limit', 'Deductible', 'Premium'];
  locationCoverageColumns: string[] = ['Name', 'Limit', 'Deductible', 'BasisofSettlement', 'Premium'];
  discountsAndSurchargeColumns: string[] = ['Name', 'Amount', 'Percent'];

  data: Rating;
  policyId: string;

  constructor(
    private router: Router,
    private RatingService: RatingService,
    private dialog: MatDialog,
    private PolicyDataService: PolicyDataService,
    private assetFetcherService: AssetFetcherService
  ) { }

  ngOnInit() {
    this.policyId = this.PolicyDataService.getPolicyID();
    this.rate();
  }

  rate() {
    this.RatingService.ratePolicy(this.policyId).subscribe(
      (data) => {
        this.PolicyDataService.updateUnderWritingRules(data.opinions);
        if (data.Error) {
          this.errorPayload = data;
          if (data.UserAction != null && data.UserAction.modal === 'underwriter-review-dialog') {
            this.needsUnderwriterReview = true;
          }
          return;
        }
        this.checkoutHidden = this.PolicyDataService.getPolicyConfig()?.checkoutHidden
        this.data = data;
      });
  }

  openErrorDialog(error: Error) {
    this.dialog.open(BasicDialogComponent, {
      data: {
        message: error.Message,
        details: error.Details,
        onlyConfirm: true,
        title: 'Error',
        showDetails: (error.Details != null),
        dialogPrefix: 'rating-error-modal'
      },
      width: '480px'
    });
  }

  toggleDetails() {
    this.moreDetails = !this.moreDetails;
  }

  initiateBind() {
    this.assetFetcherService.getAssetFile({
      quoteId: this.PolicyDataService.getPolicyID(),
      name: 'bind-warning-message-B.html'
    }).subscribe(
      (res) => {
        this.openWarningDialog(
          res.data,
          this.bindPolicy.bind(this)
        );
      }
    );
  }

  initiateCheckout() {
    this.assetFetcherService.getAssetFile({
      quoteId: this.PolicyDataService.getPolicyID(),
      name: 'bind-warning-message-D.html'
    }).subscribe(
      (res) => {
        this.openWarningDialog(
          res.data,
          this.checkout.bind(this)
        );
      }
    );
  }

  openWarningDialog(htmlContent: string, proceedMethod: () => void) {
    this.dialog
      .open(BasicDialogComponent, {
        data: {
          title: 'Warning',
          htmlContent,
          dialogPrefix: 'checkout-warning-modal',
        }
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          proceedMethod();
        }
      });
  }

  openUnderwriterReviewDialog() {
    this.dialog.open(UnderwriterReviewDialogComponent, {
      data: {}
    });
  };

  bindPolicy() {
    this.RatingService.bindPolicy(this.policyId).subscribe(
      (bindResult: BindResult) => {
        this.handleBindResult(
          bindResult,
          () => {
            this.dialog
              .open(BasicDialogComponent, {
                data: {
                  title: 'Success',
                  message: `Policy ${bindResult.PolicyISIPolicyKeyID} was successfully bound.`,
                  dialogPrefix: 'checkout-success-modal',
                  onlyConfirm: true,
                },
                width: '480px'
              })
              .afterClosed()
              .subscribe((_: boolean) => {
                this.router.navigate([`/dashboard`]);
              });
          }
        );
      });
  }

  checkout() {
    this.RatingService.checkout(this.policyId).subscribe(
      (bindResult: BindResult) => {
        this.handleBindResult(
          bindResult,
          () => {
            this.router.navigate([`/billing/${this.policyId}`]);
          }
        )
      });
  }

  handleBindResult(bindResult: BindResult, proceedMethod: () => void) {
    const { Error, UserAction } = bindResult;

    if (Error != null) {
      this.openErrorDialog(Error);

    } else if (UserAction != null && UserAction.modal === 'underwriter-review-dialog') {
      this.openUnderwriterReviewDialog()
    } else {
      proceedMethod();
    }
  }

  getColumnsFromObj(entityObj) {
    return Object.keys(entityObj);
  }
}
