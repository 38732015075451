import { NgModule } from '@angular/core';
import { FormTabComponent } from './form-tab.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatInputModule, MatCardModule, MatExpansionModule, MatCheckboxModule } from '@angular/material';
import { FormTabService } from './form-tab.service';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { AutofillAddressComponent } from './components/autofill-address/autofill-address.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { FieldLookupService } from './services/lookup.service';

@NgModule({
  declarations: [FormTabComponent, FormFieldComponent, AutofillAddressComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot({
      showMaskTyped: true
    })
  ],
  exports: [FormTabComponent],
  providers: [FormTabService, FieldLookupService]
})
export class FormTabModule { }
