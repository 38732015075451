import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { PolicyCreationService } from '../services/policy.service';

@Injectable()
export class DashboardPaginator extends MatPaginatorIntl {

    constructor(private policyCreationService: PolicyCreationService) {
      super()
    }

  getRangeLabel = (page: number, _: number, length: number) => {
    const lastPage = this.policyCreationService.dashboardLastPage.getValue()
    const currentPage = this.policyCreationService.dashboardCurrentPage.getValue()
    if (length === 0 || lastPage === 0) return 'Page 0 of 0'
    return `Page ${page + 1 > lastPage ? currentPage : page + 1} of ${lastPage}`;
  };
}
