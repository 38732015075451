import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-nav-bar',
  templateUrl: './dashboard-nav-bar.component.html',
  styleUrls: ['./dashboard-nav-bar.component.scss']
})
export class DashboardNavBarComponent implements OnInit {
  @Input() tabs: Array<any>;

  constructor(public route: ActivatedRoute) { }

  ngOnInit() {
  }

}
