import { PolicyService } from '@modules/policy/policy.service';
import { JsonFormFetcherService } from './../../../services/shared/json-form-fetcher.service';
import { FormTabService } from './../../../../../../../projects/form-tab/src/lib/form-tab.service';
import { PolicyDataService } from './../../../services/shared/policy-data.service';
import { DynamicDropdownService } from './../../../services/shared/dynamic.dropdown.service';
import { TreeService } from './../../../services/location/tree.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatSnackBar, MatTreeNestedDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FormService } from '@modules/policy/services/shared/form.service';
import { FormSaveHelper } from '@modules/policy/services/shared/save';
import { SaveService } from '@modules/policy/services/shared/save.service';
import LocationTabs from '@shared/models/location.model';
import { PolicyState } from '@shared/models/state.model';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CoverageTableComponent } from '../coverage-table/coverage-table.component';

@Component({
  selector: 'app-nested-subtab-form',
  templateUrl: './nested-subtab-form.component.html',
  styleUrls: ['./nested-subtab-form.component.scss']
})
export class NestedSubtabFormComponent implements OnInit {
  @Input() subtab: string;
  @Input() tab: string;
  @Input() type: string;
  @Output() newFormEvent = new EventEmitter<FormGroup>();

  form: FormGroup;
  formJson: any;
  policyId: string;
  formId: number;
  locationId: string;
  subtabs: [];
  riskStates;
  dataSource = new MatTreeNestedDataSource<LocationTabs>();
  $matTreeSubscription;
  $subjects;
  states: PolicyState;
  listName: string;
  readOnlyPolicy: boolean;
  private coverageData: any;
  private additionalCoverageDependencies: { [s: string]: AbstractControl } = {};
  @ViewChild("coverageTableComponent", { static: false }) coverageTableComponent: CoverageTableComponent;

  constructor(
    private route: ActivatedRoute,
    private saveService: SaveService,
    private FormHelper: FormService,
    private PolicyService: PolicyService,
    private JsonFormFetcherService: JsonFormFetcherService,
    private FormTabService: FormTabService,
    private PolicyDataService: PolicyDataService,
    private SaveHelper: FormSaveHelper,
    private DynamicDropdownService: DynamicDropdownService,
    private snackbar: MatSnackBar,
    private TreeService: TreeService
  ) { }

  saveBeforeDeactivate() {
    if (this.form && (this.form.dirty || this.form.touched)) {
      return this.save(true).toPromise().then((res) => {
        this.PolicyService.updateTouchedNodeStates(res);
      });
    } else {
      return of(null).toPromise();
    }
  }

  newForm(form: FormGroup) {
    this.newFormEvent.emit(form);
  }

  ngOnInit() {
    this.matTreeSubscription();
    this.policyId = this.PolicyDataService.getPolicyID();
    const stateSubject = this.PolicyDataService.stateSubject;
    const configSubject = this.JsonFormFetcherService.configSubject;
    this.$subjects = combineLatest([stateSubject, configSubject, this.route.params]).pipe(
      map(res => {
        const [states, config, params] = res;
        return {
          params,
          states,
          config
        }
      })
    ).subscribe(data => {
      const { states, config, params } = data;
      const { formId, locationId } = params;
      this.states = states;
      this.formId = formId;
      this.locationId = locationId;
      const subtabConfig = config.tabs.find(curTab => curTab.tabKey === this.tab);
      const { shouldCache } = subtabConfig;
      this.listName = this.PolicyDataService.getListName(this.tab, this.subtab, config.tabs);
      if (this.tab === 'policy') {
        this.subtabs = subtabConfig.subTabs;
      }
      else if (this.tab === 'location') {
        for (let subtab of subtabConfig.lists[0].lists) {
          if (subtab.listName === this.type) {
            this.subtabs = subtab.subTabs;
            break;
          }

          if (subtab.lists != null) {
            for (let subtabList of subtab.lists) {
              if (subtabList.listName === this.type) {
                this.subtabs = subtabList.subTabs.map((listSubTab) => {
                  return {
                    isList: true,
                    ...listSubTab
                  }
                });
                break;
              }
            }
          }

          if (this.subtabs != null) {
            break;
          }
        }
      }
      
      if (this.PolicyService.getForm(this.formId)) {
        this.handleForms(shouldCache);
      }
    });
  }

  save(routeChange?: boolean) {
    if (this.form.disabled) return
    const cascadingIds = {
      nodeState: [Number(this.formId), this.form.valid]
    };

    const saveObs: Observable<any> = this.SaveHelper.save(
      this.form,
      cascadingIds,
      this.subtab,
      this.saveService,
      this.policyId
    );
    if (routeChange) {
      return saveObs;
    }
    if (this.coverageTableComponent != null) {
      this.coverageTableComponent.save();
    }
    saveObs.toPromise().then((res) => {
      this.showSaveSnackbar();
      if (res != null && res.data.parent != null) {
        this.TreeService.setParentNodeName(res.data)
        this.PolicyDataService.getPolicyStates(this.PolicyDataService.getPolicyID()).toPromise().then((res) => {
          this.PolicyDataService.updateStates(res);
        });
      }
    });
  }

  updateNavigationTree() {
    this.PolicyDataService.getPolicyStates(this.policyId).subscribe(tree => {
      this.PolicyDataService.updateStates(tree);
    })
  }

  showSaveSnackbar() {
    this.snackbar.open('Saved', null, {
      duration: 2000,
    });
    this.updateNavigationTree();
  }

  handleForms(shouldCache: boolean) {
    const result = this.FormHelper.initForm({
      subtab: this.subtab,
      formid: this.formId,
      subTabList: this.subtabs,
      policyID: this.policyId,
      shouldCache
    });
    if (typeof result === 'number') {
      this.displayForm(result);
    }
    if (isObservable<any>(result)) {
      this.getForm(result);
    }
  }

  getForm(obs: Observable<any>) {
    obs.subscribe((result) => {
      this.form = result.form;
      this.formJson = result.orderedFormJson;
      this.FormHelper.setForm({
        formid: this.formId,
        form: this.form,
        orderedFormJson: this.formJson
      });
      this.afterFormInit();
    }, (err: any) => console.error(err));
  }

  displayForm(formId: number) {
    this.form = this.PolicyService.getForm(formId);
    this.formJson = this.PolicyService.getFormJson(formId);
    this.afterFormInit();
  }

  afterFormInit() {
    this.handleReadOnlyForm()
    if (Array.isArray(this.formJson)) {
      this.formJson.forEach((formSection) => {
        if (formSection.customSection != null) {
          this.initCoverageTable();
          return;
        }
      });
    }
    this.newForm(this.form);
    this.form.markAsPristine();
    this.TreeService.appendFormToData(this.dataSource.data, this.formId, this.form);
  }

  initCoverageTable() {
    const values = this.PolicyDataService.getValueFromCache(Number(this.formId));
    if (values.PrimaryCoverages != null && values.PrimaryCoverages.length > 0) {
      this.coverageData = {
        ...values.PrimaryCoverages,
        ...this.coverageData
      }
    }
    if (values.AdditionalCoverages != null && values.AdditionalCoverages.length > 0) {
      this.coverageData = {
        ...values.AdditionalCoverages,
        ...this.coverageData
      }
      // TODO: We VERY MUCH need a way to genericize this - this is not a pattern that we've come across before and
      // won't work cleanly with anything else. People gotta know what they're asking for.
      // I also hate that I've got a reference to "ItemDetails" here. Blech.
      switch (this.subtab) {
        case 'grainDetails':
          break;
        default:
          const itemDetails = this.form.get('ItemDetails');
          this.additionalCoverageDependencies = {
            ScheduleItemDeductibleCd: itemDetails.get('ScheduleItemDeductibleCd'),
            ScheduleItemValueAmt: itemDetails.get('ScheduleItemValueAmt')
          }
      }
    }
  }

  initForm(form: FormGroup) { 
    this.form.setControl('CoverageTable', form)
  }

  matTreeSubscription() {
    this.$matTreeSubscription = this.TreeService.dataSourceSubject.subscribe(data => {
      this.dataSource.data = null;
      this.dataSource.data = data;
    });
  }

  handleReadOnlyForm() {
    this.PolicyDataService.policyReadOnly.subscribe(readOnly => {
      this.readOnlyPolicy = readOnly
      if (readOnly) this.form.disable()
    })
  }

  ngOnDestroy() {
    if (this.$subjects) {
      this.$subjects.unsubscribe();
    }
    if (this.$matTreeSubscription) {
      this.$matTreeSubscription.unsubscribe();
    }
  }
}
